import { Component, Input, OnDestroy, inject } from '@angular/core';
import { MissionSharedService } from '../../services/mission-shared.service';
import { UploadDatasetService } from '../../services/upload-dataset.service';
import { CONSTANTS_MISSION, DUPLICATE_UPLOAD_MODAL, NOTIFY_MSG, RESOURCE_TYPE } from '../../constants/create-mission.const';
import { EventTypes, NotificationService } from '../../services/notification.service';
import { DataResourceType, WorkflowDef, WorkflowStep } from '../../models/mission-detail.model';
export interface FileUploadData {
  file: FileList | null,
  fileType: string,
  parentFileType: string
}

@Component({
  selector: 'app-upload-dataset',
  templateUrl: './upload-dataset.component.html',
  styleUrl: './upload-dataset.component.scss'
})
export class UploadDatasetComponent implements OnDestroy {

  @Input() resourceTypeList: string[] = []
  @Input() selectedWorkflowDef: WorkflowDef;

  missionSharedService = inject(MissionSharedService)
  uploadDateServices = inject(UploadDatasetService)
  notificationService = inject(NotificationService)

  // const
  RESOURCE_TYPE = RESOURCE_TYPE
  MISSION_CONSTANTS = CONSTANTS_MISSION;

  missionData: any
  s3CredentialsInfo: any
  isShowToaster: boolean = false;
  isShowProgressModal: boolean = false;
  showDeleteModal: boolean = false;
  duplicateFileExist: boolean = false;
  toasterClass: string = '';
  toasterMsg: string = '';
  duplicateFileUploadModal = DUPLICATE_UPLOAD_MODAL;
  createMissionParams = this.missionSharedService.createMissionParams_signal
  requiredInputs: (DataResourceType | undefined)[]

  ngOnInit(): void {
    this.onScrollIntoView()
    this.requiredInputs = this.getRequiredInputs(this.selectedWorkflowDef.workflowSteps);
    console.log('Required Inputs: ', this.requiredInputs);
  }

  ngOnDestroy(): void {
    this.uploadDateServices.datasetHeadExpand.next('')
  }

  onScrollIntoView() {
    this.uploadDateServices.datasetHeadExpand.subscribe(() => {
      requestAnimationFrame(() => {
        const element = document.querySelector('.show-upload-body');
        element && element.scrollIntoView({ behavior: "smooth", block: "center" });
      });
    })
  }

  stayOnUploadComponent() {
    this.isShowProgressModal = false;
  }

  filesDropped({ file, fileType, parentFileType }: FileUploadData) {
    file && this.uploadFiles(file, fileType, parentFileType)
  }

  getS3Credentials() {
    this.uploadDateServices.getCredentials(this.missionData.missionId).subscribe((data) => {
      this.s3CredentialsInfo = data
    })
  }

  uploadFiles(files: FileList, fileType: string, parentFileType: string) {
    let isFileExisted: boolean = this.missionSharedService.isFileExisted(
      files,
      fileType,
      parentFileType
    );

    if (isFileExisted) {
      this.notificationService.showToast({ type: EventTypes.error, message: NOTIFY_MSG.UPLOAD_DUPLICATE });
    } else {
      const validFiles = this.missionSharedService.getValidFiles(files, parentFileType);

      if (validFiles.length !== 0) {
        const { missionId, missionProjectId, siteId } = this.createMissionParams()

        this.missionSharedService.updateUploadingFilesDataset(
          validFiles,
          fileType,
          parentFileType
        );
        this.missionSharedService.populateFilesProgressList(
          validFiles,
          fileType,
          parentFileType
        );
        this.missionSharedService.uploadFilesToS3(
          validFiles,
          fileType,
          parentFileType,
          missionId,
          missionProjectId,
          siteId
        );
      } else {
        this.notificationService.showToast({ type: EventTypes.error, message: NOTIFY_MSG.SUPPORTED_FILE_NOT_FOUND });
      }
    }
  }

  hierarchySort(currentNode: WorkflowStep, workflowSteps: WorkflowStep[]): any {
    const childStepNames = workflowSteps.filter((step) =>
      step.parentStepNames.includes(currentNode.stepName)
    );

    const childNodes = [];
    const allInputs = [];

    for (const childStep of childStepNames) {
      const { childNode, requiredInputNames } = this.hierarchySort(
        childStep,
        workflowSteps
      );
      childNodes.push(childNode);
      allInputs.push(...requiredInputNames);
    }

    const inputDataResourceTypeNames = currentNode.inputDataResourceTypes.map(
      (input) => input.name
    );
    const outputDataResourceTypeNames = currentNode.outputDataResourceTypes.map(
      (output) => output.name
    );

    let requiredInputFilter = [];

    const nodes: any = {
      name: currentNode.stepName,
      inputDataResourceTypes: inputDataResourceTypeNames,
      outputDataResourceTypes: outputDataResourceTypeNames,
      children: childNodes,
    };

    if (childNodes.length === 0) {
      requiredInputFilter = inputDataResourceTypeNames;
    } else {
      allInputs.push(...inputDataResourceTypeNames);
      requiredInputFilter = allInputs.filter(
        (input) => !outputDataResourceTypeNames.includes(input)
      );
      requiredInputFilter = [...new Set(requiredInputFilter)];
    }

    return { childNode: nodes, requiredInputNames: requiredInputFilter };
  }

  getRequiredInputs(workflowSteps: WorkflowStep[]) {
    const rootStepName = workflowSteps.find(
      (step) => step.parentStepNames.length === 0
    );
    const { requiredInputNames } = this.hierarchySort(
      rootStepName!,
      workflowSteps
    );

    const uniqueResourceTypes = this.getResourceTypes(workflowSteps);

    return uniqueResourceTypes.filter((type) =>
      requiredInputNames.includes(type!.name)
    );
  }

  getResourceTypes(workflowSteps: WorkflowStep[]) {
    const allResourceTypes = workflowSteps.flatMap((step) => [
      ...step.inputDataResourceTypes,
      ...step.outputDataResourceTypes,
    ]);

    return Array.from(new Set(allResourceTypes.map((obj) => obj.name))).map(
      (name) => allResourceTypes.find((obj) => obj.name === name)
    );
  }
}
