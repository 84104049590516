import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild, inject } from '@angular/core';
import { CONSTANTS_MISSION, DRONE_IMAGE_LABEL, THREE_D_DATA, THREE_D_DATA_SUB_HEADER, UPLOAD_DATASET_HEADER, NOTIFY_MSG } from '../../../constants/create-mission.const';
import { FileUploadData } from '../upload-dataset.component';
import { UploadDatasetService } from '../../../services/upload-dataset.service';
import { MissionSharedService } from '../../../services/mission-shared.service';
import { UtilityService } from '../../../services/utility.service';
import { EventTypes, NotificationService } from '../../../services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../../shared/components/delete-modal/delete-modal.component';

export interface FilesProgressList {
  id: string
  progress: number
  name: string
  signalId: string
  file: File
  startTime: number
  etag: string,
  dataResourceId: string
}
@Component({
  selector: 'app-three-d-data',
  templateUrl: './three-d-data.component.html',
  styleUrl: './three-d-data.component.scss'
})
export class ThreeDDataComponent implements OnInit {
  @Output() getUploadedFile = new EventEmitter<FileUploadData>()
  @ViewChild('inputTowerView') inputTowerView: ElementRef<HTMLInputElement>
  @ViewChild('inputLineOfSight') inputLineOfSight: ElementRef<HTMLInputElement>
  @ViewChild('droneImageContainer') droneImageContainer: ElementRef<HTMLInputElement>
  @ViewChild('inputTopToDown') inputTopToDown: ElementRef<HTMLInputElement>
  @ViewChild('inputNadir') inputNadir: ElementRef<HTMLInputElement>
  @ViewChild('towerViewCheckbox') towerViewCheckbox: ElementRef<HTMLInputElement>
  @ViewChild('lineOfSightCheckbox') lineOfSightCheckbox: ElementRef<HTMLInputElement>
  @ViewChild('topToDownCheckbox') topToDownCheckbox: ElementRef<HTMLInputElement>
  @ViewChild('nadirCheckbox') nadirCheckbox: ElementRef<HTMLInputElement>

  utilityService = inject(UtilityService)
  uploadDataServices = inject(UploadDatasetService)
  missionSharedService = inject(MissionSharedService)
  notificationService = inject(NotificationService)
  ngbModalRef = inject(NgbModal)
  changeDetectorRef = inject(ChangeDetectorRef)

  // const
  DRONE_IMAGE_LABEL = DRONE_IMAGE_LABEL
  THREE_D_DATA = THREE_D_DATA
  MISSION_CONSTANTS = CONSTANTS_MISSION;
  THREE_D_DATA_SUB_HEADER = THREE_D_DATA_SUB_HEADER
  UPLOAD_DATASET_HEADER = UPLOAD_DATASET_HEADER

  headExpand = ''
  subHeadExpand = ''
  isValidCameraParam = true;
  isValid3DModel = true;
  isValidTowerView = true;
  isValidLineOfSight = true
  isValidTopToDown = true
  isValidNadir = true
  cameraParamUploadedFiles: boolean;
  threeDModelUploadedFiles: boolean;
  towerViewUploadedFiles: FilesProgressList[] = [];
  lineOfSightUploadedFiles: FilesProgressList[] = [];
  topToDownUploadedFiles: FilesProgressList[] = [];
  nadirUploadedFiles: FilesProgressList[] = [];

  towerViewSelectedFiles: FilesProgressList[] = [];
  lineOfSightSelectedFiles: FilesProgressList[] = [];
  topToDownSelectedFiles: FilesProgressList[] = [];
  nadirSelectedFiles: FilesProgressList[] = [];

  classList = ['tower-view', 'line-of-sight', 'top-to-down', 'nadir']
  droneImagesFileType = {
    [this.MISSION_CONSTANTS.droneImgTv]: DRONE_IMAGE_LABEL.TOWER_VIEW_UPLOADED_FILE,
    [this.MISSION_CONSTANTS.droneImgLos]: DRONE_IMAGE_LABEL.LINE_OF_SIGHT_UPLOADED_FILE,
    [this.MISSION_CONSTANTS.droneImageSV]: DRONE_IMAGE_LABEL.TOP_TO_DOWN_UPLOADED_FILE,
    [this.MISSION_CONSTANTS.droneImgNadir]: DRONE_IMAGE_LABEL.NADIR_UPLOADED_FILE
  };
  droneImagesFolder = [
    { title: 'Tower View', folderType: 'tower-view', uploadedFiles: this.towerViewUploadedFiles },
    { title: 'Line of Sight', folderType: 'line-of-sight', uploadedFiles: this.lineOfSightUploadedFiles },
    { title: 'Top to Down', folderType: 'top-to-down', uploadedFiles: this.topToDownUploadedFiles },
    { title: 'NADIR', folderType: 'nadir', uploadedFiles: this.nadirUploadedFiles }
  ]
  createMissionParams = this.missionSharedService.createMissionParams_signal

  ngOnInit() {
    this.uploadDataServices.datasetHeadExpand.subscribe((data) => {
      this.headExpand = data
    })

    this.uploadDataServices.checkUploadedFileList.subscribe(() => {
      this.getDroneImageUploadingFiles()
    })

    this.uploadDataServices.checkFileIsAvailable.subscribe(() => {
      this.cameraParamUploadedFiles = this.utilityService.checkUploadFileIsPresent(this.MISSION_CONSTANTS.datasetCP)
      this.threeDModelUploadedFiles = this.utilityService.checkUploadFileIsPresent(this.MISSION_CONSTANTS.datasetDT)
    })
  }

  onSentUploadedFile(file: FileList | null, fileType: string, parentFileType: string) {
    const FileData = { file, fileType, parentFileType }
    this.getUploadedFile.emit(FileData)
  }

  onHeadExpand(data: string) {
    this.uploadDataServices.datasetHeadExpand.next(data)
    this.subHeadExpand = ''
  }

  isFileUploadedToChild() {
    return (this.cameraParamUploadedFiles || this.threeDModelUploadedFiles || this.towerViewUploadedFiles.length ||
      this.lineOfSightUploadedFiles.length || this.topToDownUploadedFiles.length || this.nadirUploadedFiles.length)
      && !this.headExpand
  }

  onSetSubHead(subHead: string) {
    if (this.subHeadExpand === subHead) {
      this.subHeadExpand = '';
      this.onScrollIntoView('.upload-container')

    } else {
      this.subHeadExpand = subHead;
      this.onScrollIntoView('.show-dagAndDrop')
    }
  }

  onScrollIntoView(ClassName: string) {
    requestAnimationFrame(() => {
      const element = document.querySelector(ClassName);
      element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  }

  filesDropped($event: FileUploadData) {
    this.getUploadedFile.emit($event);
  }

  checkFileValid(value: boolean, uploadType: string) {
    if (uploadType == THREE_D_DATA.INPUT_TOWER_VIEW) {
      this.isValidTowerView = value
      if (!this.isValidTowerView) {
        this.inputTowerView.nativeElement.value = ''
      }
    }
    if (uploadType == THREE_D_DATA.INPUT_LINE_OG_SIGHT) {
      this.isValidLineOfSight = value
      if (!this.isValidLineOfSight) {
        this.inputLineOfSight.nativeElement.value = ''
      }
    }
    if (uploadType == THREE_D_DATA.INPUT_TOP_TO_DOWN) {
      this.isValidTopToDown = value
      if (!this.isValidTopToDown) {
        this.inputTopToDown.nativeElement.value = ''
      }
    }
    if (uploadType == THREE_D_DATA.INPUT_NADIR) {
      this.isValidNadir = value
      if (!this.isValidNadir) {
        this.inputNadir.nativeElement.value = ''
      }
    }
  }

  onRemoveAllClass() {
    this.droneImageContainer.nativeElement.classList.remove(...this.classList)
  }

  onAddClass(className: string) {
    this.droneImageContainer.nativeElement.classList.remove(...this.classList)
    this.droneImageContainer.nativeElement.classList.add(className);
  }

  getDroneImageUploadingFiles() {
    const fileList = this.missionSharedService.uploadingFiles;
    const parentFileType = this.MISSION_CONSTANTS.datasetDI;

    for (const fileType in this.droneImagesFileType) {
      const filesProgressList = fileList[parentFileType]?.[fileType]?.filesProgressList as FilesProgressList[];
      const isAllFileUploaded = filesProgressList.some((file) => file.progress == -1);

      if (filesProgressList && filesProgressList.length && !isAllFileUploaded) {
        filesProgressList.forEach((data) => {
          if (data.progress != 100 || !data.hasOwnProperty('dataResourceId')) return;
          const isPresent = ((this as any)[this.droneImagesFileType[fileType]] as FilesProgressList[]).some((val) => val.signalId == data.signalId);
          !isPresent && (this as any)[this.droneImagesFileType[fileType]].push(data);
        })
      }

    }
  }

  getUploadFileList(childType: string) {
    const fileList = this.missionSharedService.uploadingFiles;
    const parentFileList = fileList?.[this.MISSION_CONSTANTS.datasetDI]
    if (parentFileList) {
      return parentFileList[childType]
    }
  }

  onDeleteModalOpen(childType: string, selectedType: string) {
    const modalRef = this.ngbModalRef.open(DeleteModalComponent, {
      centered: true,
      windowClass: 'declaration-modal',
      backdropClass: 'declaration-modal-static',
      backdrop: false,
      size: 'md',
    });
    const selectedFileList = (this as any)[`${selectedType}SelectedFiles`]
    const data = {
      isFolder: false,
      multiSelect: true,
      selectedFile: selectedFileList.length == 1 ? selectedFileList[0].name : selectedFileList.length
    }
    modalRef.componentInstance.fileDetails = data;
    modalRef.result.then((isConform) => {
      if (isConform) {
        this.onDeleteSelectedFile(selectedType, childType)
      }
    }, (reason) => {
    });
    this.changeDetectorRef.detectChanges();
  }

  onSelectDroneImagesFile(selectedFile: FilesProgressList, selectedType: string) {
    const selectedFileList = ((this as any)[`${selectedType}SelectedFiles`] as FilesProgressList[]);
    const UploadedFileList = ((this as any)[`${selectedType}UploadedFiles`] as FilesProgressList[]);
    const inputSelectAll = ((this as any)[`${selectedType}Checkbox`] as ElementRef<HTMLInputElement>)

    const index = selectedFileList.findIndex((data) => data.signalId == selectedFile.signalId)
    if (index != -1) {
      selectedFileList.splice(index, 1)
    } else {
      selectedFileList.push(selectedFile)
    }

    if (selectedFileList.length == UploadedFileList.length) {
      inputSelectAll && (inputSelectAll.nativeElement.checked = true)
    } else {
      inputSelectAll && (inputSelectAll.nativeElement.checked = false)
    }
  }

  onSelectAllDroneImagesFile(element: HTMLInputElement, selectedType: string) {
    if (element.checked) {
      (this as any)[`${selectedType}SelectedFiles`] = [...(this as any)[`${selectedType}UploadedFiles`]]
    } else if (!element.checked) {
      (this as any)[`${selectedType}SelectedFiles`] = []
    }
  }

  onDeleteSelectedFile(selectedType: string, childType: string) {
    const filesProgressList = this.getUploadFileList(childType)?.filesProgressList as FilesProgressList[]
    const filesList = this.getUploadFileList(childType)?.files as File[]
    const selectedFileList = ((this as any)[`${selectedType}SelectedFiles`] as FilesProgressList[]);
    const dataResourceIds = selectedFileList.map((data) => data.dataResourceId);

    this.uploadDataServices.deleteDataResourcesBulk(dataResourceIds).subscribe({
      next: () => {
        selectedFileList.forEach((files) => {
          const index = filesProgressList.findIndex((fileId) => fileId.signalId == files.signalId)
          if (index !== -1) {
            filesProgressList.splice(index, 1)
          }
          const fileListIndex = filesList.findIndex((fileId) => fileId.name == files.id)
          if (fileListIndex !== -1) {
            filesList.splice(fileListIndex, 1)
          }
          for (const fileType in this.droneImagesFileType) {
            const index = ((this as any)[this.droneImagesFileType[fileType]] as FilesProgressList[])?.findIndex((val)=>val.signalId == files.signalId)
            if (index !== -1) {
              (this as any)[this.droneImagesFileType[fileType]].splice(index, 1)
            }
          }
        });
        (this as any)[`${selectedType}SelectedFiles`] = []
        this.notificationService.showToast({ type: EventTypes.success, message: NOTIFY_MSG.FILE_DELETE_SUCCESS });
      },
      error: () => {
        this.notificationService.showToast({ type: EventTypes.error, message: NOTIFY_MSG.FILE_DELETE_FAILED });
      }
    })
  }
}
