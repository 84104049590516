
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Routes } from 'projects/data-upload/src/app/services/routes'
import { AssetResponse } from '../interfaces/asset.interface';

@Injectable({
  providedIn: 'root'
})
export class ExternalClientAssetService {
  private apiUrl = 'externalClientAssetId';

  constructor(private http: HttpClient) {}

  searchExternalClientAsset(query?: string): Observable<any> {
    let url = Routes.GET_ALL_ASSETS;
    if(query) url+=`?externalClientAssetId=${query}`;
    return this.http.get(url);
  }

  getAssetList(searchData = '', nextCursor?: string | null): Observable<AssetResponse> {
    let url = `${Routes.GET_ALL_ASSETS}?pageLimit=20&search=${searchData}`;
    if(nextCursor) url+=`&nextCursor=${nextCursor}`;
    return this.http.get<AssetResponse>(url);
  }
}
