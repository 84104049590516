<div class="upload-dagAndDrop" [style.display]="showDragDrop ? 'block' : 'none'">
  <div class="upload-body-dropdown">
    <div class="upload-dagAndDrop-body">
      <input #inputElement title=" " (click)="inputElement.value = ''" dropStyle [parentFileType]="fileType"
        (isValidFile)="checkFileValid($event)" (change)="onSentUploadedFile(
        inputElement.files,
        fileType,
        fileType
      )" id="inputElement" type="file" multiple webkitdirectory mozdirectory />
      <div class="drag-and-drop-text-container">
        <img [src]="'/images/cloud_upload.svg' | assetUrl" alt="cloud_upload"
          [ngClass]="!isValid?'not-valid-file':''">
        <p><span class="click-to-upload">Click to upload folder</span><span>&nbsp; or &nbsp;drag and drop</span></p>
        @if(!isValid){
        <p>Wrong file format. Please upload file that is</p>
        }
        <p>{{supportedFile}}</p>
      </div>
    </div>
    @if(uploadedFiles && isValid){
    <div class="upload-preview-container">
      <app-upload-folder-preview [disableFileSelect]="disableFileSelect"  [uploadCategory]="{parentType:fileType,childType:fileType}"></app-upload-folder-preview>
    </div>
    }
  </div>
</div>