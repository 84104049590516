<form [formGroup]="createMissionForm">
  <div class="mission-details-container">
    <div class="input-group-container">
      <label class="input-group-label" for="missionName">Mission Name<sup class="require-tag">&nbsp; &ast;</sup></label>
      <input class="input-tag" formControlName="missionName" type="text" id="missionName" name="missionName"
        placeholder="Mission Name" autocomplete="off" [ngClass]="{'disableField': missionDetails}">
    </div>
    <div class="input-group-container">
      <label class="input-group-label" for="missionObjective">Mission Objective<sup class="require-tag">&nbsp;
          &ast;</sup></label>
      <div #missionObjective class="custom-select-box mission-objective-select">
        <div class="head">
          <p (click)="missionObjective.classList.toggle('dropdown-show')">{{ selectedMissionObjective.name }}</p>
          <img [src]="'/images/chevron.svg' | assetUrl" alt="chevron" />
        </div>
        <div class="dropdown">
          @for (objective of missionObjectiveData; track objective) {
          <p (click)="missionObjective.classList.toggle('dropdown-show'); getSelectedValue(objective,MISSION_DETAIL_SELECT_LABEL.MISSION_OBJECTIVE)"
            [ngClass]="selectedMissionObjective.name == objective.name ? 'selected' : ''">
            {{ objective.name }}
          </p>
          }
        </div>
      </div>
    </div>
    <div class="input-group-container">
      <label class="input-group-label" for="workflow">Workflow<sup class="require-tag">&nbsp; &ast;</sup></label>
      <div #workflowDef class="custom-select-box workflow-select">
        <div class="head">
          <p (click)="workflowDef.classList.toggle('dropdown-show')">{{ selectedWorkflow.name }}</p>
          <img [src]="'/images/chevron.svg' | assetUrl" alt="chevron" />
        </div>
        <div class="dropdown">
          @for (workflow of WorkflowDefData; track workflow) {
          <p (click)="workflowDef.classList.toggle('dropdown-show'); getSelectedValue(workflow,MISSION_DETAIL_SELECT_LABEL.WORKFLOW_DEF)"
            [ngClass]="selectedWorkflow.name == workflow.name ? 'selected' : ''">
            {{ workflow.name }}
          </p>
          }
        </div>
      </div>
    </div>
  </div>
</form>
