import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-toaster',
  templateUrl: './custom-toaster.component.html',
  styleUrl: './custom-toaster.component.scss'
})
export class CustomToasterComponent {
  @Input() toasterClass!:string;
  @Input() toasterMsg!:string;
}
