<div class="upload-container" [ngClass]="headExpand == UPLOAD_DATASET_HEADER.VIDEO ? 'show-upload-body' : ''">
  <div class="upload-head" (click)="onHeadExpand(headExpand == UPLOAD_DATASET_HEADER.VIDEO?'':UPLOAD_DATASET_HEADER.VIDEO)">
    <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
    <p>{{UPLOAD_DATASET_HEADER.VIDEO}}</p>
    @if(videoUploadedFilesPreview){
      <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
    }
  </div>
  <div class="upload-body-container">
    <div class="upload-body-dropdown">
      <div class="upload-body">
        <input #inputVideo title=" " (click)="inputVideo.value = ''" dropStyle [parentFileType]="MISSION_CONSTANTS.VIDEO" (isValidFile)="checkFileValid($event)"
          (change)="
            onSentUploadedFile(
              inputVideo.files,
              MISSION_CONSTANTS.VIDEO,
              MISSION_CONSTANTS.VIDEO
            )" id="inputVideo" type="file" multiple webkitdirectory mozdirectory />
        <div class="drag-and-drop-text-container">
          <img [src]="'/images/cloud_upload.svg' | assetUrl" alt="cloud_upload" [ngClass]="!isValid?'not-valid-file':''">
          <p><span class="click-to-upload">Click to upload folder</span><span>&nbsp; or &nbsp;drag and drop</span></p>
          @if(!isValid){
          <p>Wrong file format. Please upload file that is</p>
          }
          <p>Supported formats: .mp4, .mkv, .mov .m4v</p>
        </div>
      </div>
      @if(videoUploadedFilesPreview && isValid){
      <div class="upload-preview-container">
        <app-upload-folder-preview [uploadCategory]="{parentType:MISSION_CONSTANTS.VIDEO,childType:MISSION_CONSTANTS.VIDEO}"></app-upload-folder-preview>
      </div>
      }
    </div>
  </div>
</div>
