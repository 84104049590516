import { Injectable, signal } from '@angular/core';
import {
  BehaviorSubject,
  from,
  lastValueFrom,
  map,
  mergeMap,
  Observable,
  Subject,
  takeUntil,
  toArray,
} from 'rxjs';
import { state } from '@app/utility';
import {
  CONSTANTS_MISSION,
  DRONE_IMG_TYPE,
  NOTIFY_MSG,
} from '../constants/create-mission.const';
import { Upload } from '@aws-sdk/lib-storage';
import { DeleteObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { XhrHttpHandler } from '@aws-sdk/xhr-http-handler';
import { UploadDatasetService } from './upload-dataset.service';
import { ICreateDataResource, IMissionDataParams } from '../models/create-mission-dataset.model';
import * as JSZip from 'jszip';
import {
  DUPLICATE_TXT,
  UPLOAD_EXTENSIONS,
  VALID_UPLOAD_EXTENSIONS,
} from '../constants/upload-dataset.const';
import { HttpErrorResponse } from '@angular/common/http';
import { EventTypes, NotificationService } from './notification.service';
@Injectable({
  providedIn: 'root',
})
export class MissionSharedService {
  private additionalAttribute = {
    progress: 0,
    timeRemaining: 0,
    isUploaded: false,
    files: [],
    filesProgressList: [],
  };
  private uploadingFilesInitialValues: any = {
    [CONSTANTS_MISSION.datasetCP]: {
      title: 'Camera Parameters',
      resourceType: 'cameraParams',
      id: CONSTANTS_MISSION.datasetCP,
      ...this.additionalAttribute,
    },
    [CONSTANTS_MISSION.datasetDT]: {
      title: 'Digital Twin',
      resourceType: 'meshObj',
      id: CONSTANTS_MISSION.datasetDT,
      ...this.additionalAttribute,
      dataResources: []
    },
    [CONSTANTS_MISSION.datasetF01]: {
      title: 'F01 File',
      resourceType: 'f01FormsZip',
      id: CONSTANTS_MISSION.datasetF01,
      ...this.additionalAttribute,
    },
    [CONSTANTS_MISSION.datasetMisc]: {
      title: 'Miscellaneous',
      resourceType: 'miscFile',
      id: CONSTANTS_MISSION.datasetMisc,
      ...this.additionalAttribute,
    },
    [CONSTANTS_MISSION.datasetDI]: {
      title: 'Drone Images',
      progress: 0,
      isUploaded: false,
      resourceType: 'imageRGB',
      id: CONSTANTS_MISSION.datasetDI,
      [CONSTANTS_MISSION.droneImgTv]: {
        title: 'Tower View',
        id: CONSTANTS_MISSION.droneImgTv,
        ...this.additionalAttribute,
      },
      [CONSTANTS_MISSION.droneImgLos]: {
        title: 'Line Of Sight',
        id: CONSTANTS_MISSION.droneImgLos,
        ...this.additionalAttribute,
      },
      [CONSTANTS_MISSION.droneImageSV]: {
        title: 'Side View',
        id: CONSTANTS_MISSION.droneImageSV,
        ...this.additionalAttribute,
      },
      [CONSTANTS_MISSION.droneImgNadir]: {
        title: 'From Above View',
        id: CONSTANTS_MISSION.droneImgNadir,
        ...this.additionalAttribute,
      },
    },

    [CONSTANTS_MISSION.ORTHOMOSAIC]: {
      title: 'Orthomosaic',
      resourceType: 'orthoRGB',
      id: CONSTANTS_MISSION.ORTHOMOSAIC,
      ...this.additionalAttribute,
    },
    [CONSTANTS_MISSION.ORTHO_CP]: {
      title: 'Camera Parameters',
      resourceType: 'cameraParams',
      id: CONSTANTS_MISSION.ORTHO_CP,
      ...this.additionalAttribute,
    },
    [CONSTANTS_MISSION.DRONE_IMAGE]: {
      title: 'Drone Image',
      resourceType: 'image',
      id: CONSTANTS_MISSION.DRONE_IMAGE,
      ...this.additionalAttribute,
    },
    [CONSTANTS_MISSION.VIDEO]: {
      title: 'Video',
      resourceType: 'videoRGB',
      id: CONSTANTS_MISSION.VIDEO,
      ...this.additionalAttribute,
    },
    [CONSTANTS_MISSION.PANORAMA]: {
      title: 'Panorama',
      resourceType: 'image',
      id: CONSTANTS_MISSION.PANORAMA,
      ...this.additionalAttribute,
    },
    [CONSTANTS_MISSION.VIRTUAL_TOUR]: {
      title: 'Virtual Tour',
      resourceType: 'panotour',
      id: CONSTANTS_MISSION.VIRTUAL_TOUR,
      ...this.additionalAttribute,
      dataResources: []
    },
    [CONSTANTS_MISSION.ADDITIONAL_IMAGES]: {
      title: 'Additional images',
      resourceType: 'image',
      id: CONSTANTS_MISSION.ADDITIONAL_IMAGES,
      ...this.additionalAttribute,
    },
  };
  public uploadingFiles: any = JSON.parse(
    JSON.stringify(this.uploadingFilesInitialValues)
  );

  requestAbortControllers = new Map();
  public unsubscribeUploadData: Subject<void> = new Subject<void>();
  sitePlaceHolderResTag!: string;
  showProgressBar: boolean = true;
  public _simulatedProgressFilesList = new BehaviorSubject<any>(null);
  activeCollapseTitle: string = CONSTANTS_MISSION.datasetCP;
  public downloadList: Subject<number>[] = [];
  public showDownload: Subject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private uploadDatasetService: UploadDatasetService,
    private notificationService: NotificationService
  ) {
    this.onUploadingFilesInitialization();
  }
  _selectedObjective = new BehaviorSubject<string>('');
  showError = signal({ show: false, message: '' });
  initialMissionParams: IMissionDataParams = {
    siteId: '',
    missionId: '',
    missionProjectId: '',
    missionAssetId: '',
    name: '',
    trackingId: '',
  };
  createMissionParams_signal = signal(this.initialMissionParams);

  onUploadingFilesInitialization() {
    this.uploadingFiles = JSON.parse(
      JSON.stringify(this.uploadingFilesInitialValues)
    );
    this._simulatedProgressFilesList.next(this.uploadingFiles);
  }

  toogleActivate(id: string) {
    this.activeCollapseTitle = this.activeCollapseTitle == id ? ' ' : id;
  }

  getUserInfo() {
    return state.getUserInfo();
  }

  hideProgressBar() {
    this.showProgressBar = false;
  }

  initShowProgressBar() {
    this.showProgressBar = true;
  }

  getFileName(filePath: string) {
    const path = filePath.split('?')[0].split('/').slice(-1)[0];
    return decodeURIComponent(path);
  }

  getValidFiles(fileList: File[] | FileList, categoryName: string) {
    if (categoryName == CONSTANTS_MISSION.VIRTUAL_TOUR)
      return Array.from(fileList);
    const exclusiveFileExtensions = VALID_UPLOAD_EXTENSIONS[categoryName]
      .exclusiveSet as string[];
    const inclusiveFileExtensions = VALID_UPLOAD_EXTENSIONS[categoryName]
      .inclusiveSet as string[];

    const requiredExtensions = exclusiveFileExtensions.concat(
      inclusiveFileExtensions
    );

    const validFiles = Array.from(fileList).filter((file) => {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
      return requiredExtensions.includes(fileExtension.toLowerCase());
    });

    return validFiles;
  }

  isValidFileTypes(fileList: File[] | FileList, categoryName: string): boolean {
    let filesExtensions = new Set<string>([]);

    for (const file of Array.from(fileList)) {
      const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
      filesExtensions.add(fileExtension.toLowerCase());
    }

    function alphabeticalOrder(arr: string[]) {
      return arr.sort((a, b) => a.localeCompare(b));
    }

    const uniqueFileExtensions = Array.from(filesExtensions);
    const exclusiveFileExtensions = alphabeticalOrder(
      VALID_UPLOAD_EXTENSIONS[categoryName].exclusiveSet
    );
    const inclusiveFileExtensions = alphabeticalOrder(
      VALID_UPLOAD_EXTENSIONS[categoryName].inclusiveSet
    );
    const isValidExtensions = Array.from(filesExtensions).some((extension) =>
      UPLOAD_EXTENSIONS.includes(extension.toLowerCase())
    );
    if (isValidExtensions) {
      let isRequiredExtensionsAvailable: boolean = true;
      if (VALID_UPLOAD_EXTENSIONS[categoryName].mode == 'nonstrict') {
        exclusiveFileExtensions.forEach((ext: string) => {
          if (!uniqueFileExtensions.includes(ext))
            isRequiredExtensionsAvailable = false;
        });
        if (isRequiredExtensionsAvailable) {
          isRequiredExtensionsAvailable = inclusiveFileExtensions.some(
            (ext: string) => uniqueFileExtensions.includes(ext)
          );
        }
      } else {
        isRequiredExtensionsAvailable =
          inclusiveFileExtensions.length === 0
            ? true
            : inclusiveFileExtensions.some((ext: string) =>
                uniqueFileExtensions.includes(ext)
              );
        if (isRequiredExtensionsAvailable) {
          const filteredFileExtensions = uniqueFileExtensions.filter(
            (ext: string) => {
              if (inclusiveFileExtensions.includes(ext)) return false;
              return true;
            }
          );
          const sortedUserFileExtensionsSet = alphabeticalOrder(
            filteredFileExtensions
          );
          isRequiredExtensionsAvailable =
            JSON.stringify(exclusiveFileExtensions) ==
            JSON.stringify(sortedUserFileExtensionsSet);
        }
      }
      return isRequiredExtensionsAvailable;
    }
    return false;
  }

  getObjFileCountInDT() {
    let objFileCounter = 0;
    for (
      let i = 0;
      i < this.uploadingFiles[CONSTANTS_MISSION.datasetDT].files.length;
      i++
    ) {
      const fileExtension = this.uploadingFiles[
        CONSTANTS_MISSION.datasetDT
      ].files[i].name.substr(
        this.uploadingFiles[CONSTANTS_MISSION.datasetDT].files[
          i
        ].name.lastIndexOf('.')
      );
      if (fileExtension == '.obj') {
        objFileCounter++;
      }
    }
    return objFileCounter;
  }

  updateUploadingFilesDataset(
    fileList: any,
    fileType: string,
    parentFileType: string
  ) {
    if (fileType != parentFileType) {
      this.uploadingFiles[parentFileType] = {
        ...this.uploadingFiles[parentFileType],
        [fileType]: {
          ...this.uploadingFiles[parentFileType][fileType],
          files: [...this.uploadingFiles[parentFileType][fileType].files, ...fileList],
        },
      };
    } else {
      this.uploadingFiles[fileType] = {
        ...this.uploadingFiles[fileType],
        files: [...this.uploadingFiles[fileType].files, ...fileList],
      };
    }
    this._simulatedProgressFilesList.next(this.uploadingFiles);
  }

  populateFilesProgressList(
    filesList: any[],
    fileType: string,
    parentFileType: string
  ) {
    filesList = filesList.sort((a, b) => a.size - b.size);

    for (const file of filesList) {
      const fileName = file.webkitRelativePath.length
        ? file.webkitRelativePath.split('/')[
            file.webkitRelativePath.split('/').length - 1
          ]
        : file.name;
      if (fileType != parentFileType) {
        this.uploadingFiles[parentFileType][fileType].filesProgressList.push({
          id: fileName,
          progress: 3,
          name: fileName,
          signalId: file.webkitRelativePath.length
            ? file.webkitRelativePath
            : file.name,
          file: file,
        });
      } else {
        this.uploadingFiles[fileType].filesProgressList.push({
          id: fileName,
          progress: 3,
          name: fileName,
          signalId: file.webkitRelativePath.length
            ? file.webkitRelativePath
            : file.name,
          file: file,
        });
      }
    }

    this._simulatedProgressFilesList.next(this.uploadingFiles);
  }

  updateFilesProgressList(
    filePath: string,
    fileType: string,
    parentFileType: string,
    progress: number = 0,
    timeRemaining: number = 0
  ) {
    const helper = (uploadingFiles: any) => {
      const existingFileIndex = uploadingFiles.filesProgressList.findIndex(
        (item: any) => item.signalId == filePath
      );
      if (
        existingFileIndex !== -1 &&
        (uploadingFiles.filesProgressList[existingFileIndex].progress !== 100 ||
          progress === -1)
      ) {
        const curProgress =
          uploadingFiles.filesProgressList[existingFileIndex].progress;

        const prevProgress = progress > curProgress ? progress : curProgress;
        const newProgress = progress == -1 ? progress : prevProgress;

        uploadingFiles.filesProgressList[existingFileIndex] = {
          ...uploadingFiles.filesProgressList[existingFileIndex],
          progress: newProgress,
          timeRemaining,
          startTime: Date.now(),
        };

        // To trigger pipe
        uploadingFiles.filesProgressList = [
          ...uploadingFiles.filesProgressList,
        ];
      }
    };

    if (fileType != parentFileType) {
      helper(this.uploadingFiles[parentFileType][fileType]);
    } else {
      helper(this.uploadingFiles[fileType]);
    }

    this._simulatedProgressFilesList.next(this.uploadingFiles);
  }

  trackFilesEtag(
    filePath: string,
    fileType: string,
    parentFileType: string,
    etag: string
  ) {
    if (fileType != parentFileType) {
      const existingFileIndex = this.uploadingFiles[parentFileType][
        fileType
      ].filesProgressList.findIndex((item: any) => item.signalId == filePath);
      if (existingFileIndex !== -1) {
        this.uploadingFiles[parentFileType][fileType].filesProgressList[
          existingFileIndex
        ] = {
          ...this.uploadingFiles[parentFileType][fileType].filesProgressList[
            existingFileIndex
          ],
          etag,
          startTime: Date.now(),
        };
      }
    } else {
      const existingFileIndex = this.uploadingFiles[
        fileType
      ].filesProgressList.findIndex((item: any) => item.signalId == filePath);
      if (existingFileIndex !== -1) {
        this.uploadingFiles[fileType].filesProgressList[existingFileIndex] = {
          ...this.uploadingFiles[fileType].filesProgressList[existingFileIndex],
          etag,
          startTime: Date.now(),
        };
      }
    }
    this._simulatedProgressFilesList.next(this.uploadingFiles);
  }

  updateControllerMap(
    signalId: string,
    requestAbortController: AbortController
  ) {
    this.requestAbortControllers.set(signalId, requestAbortController);
  }

  abortS3Upload(requestIdToAbort: string) {
    if (this.requestAbortControllers.has(requestIdToAbort)) {
      this.requestAbortControllers.get(requestIdToAbort).abort();
      this.requestAbortControllers.delete(requestIdToAbort);
    }
  }

  async getS3Credentials(
    fileType: string,
    parentFileType: string,
    missionId: string,
    projectId: string | null
  ) {
    let s3Data;
    try {
      if (this.uploadingFiles && this.uploadingFiles[parentFileType]) {
        const updateS3Data = async () => {
          s3Data = await lastValueFrom(
            this.uploadDatasetService.getCredentials(missionId, projectId)
          );
          s3Data.resourceType =
            this.uploadingFiles[parentFileType].resourceType;
        };
        if (fileType != parentFileType) {
          await updateS3Data();
        } else if (
          fileType == CONSTANTS_MISSION.datasetDT &&
          this.getObjFileCountInDT() > 1
        ) {
          s3Data = await lastValueFrom(
            this.uploadDatasetService.getCredentials(missionId, projectId)
          );
          s3Data.resourceType = 'meshObjTile';
        } else {
          await updateS3Data();
        }
      } else {
        console.error(
          `Invalid fileType or parentFileType. The uploadingFiles object may be undefined or missing the required properties.`
        );
      }
      return s3Data;
    } catch (error) {}
  }

  getAllFilesArray(allFiles: any, data: any, fileType: string) {
    const relativepath = `${data.credentials.s3Prefix}/${data.resourceType}`;
    const currentTime = String(Date.now());
    return allFiles.map((item: any) => {
      let fileRelativePath = item.webkitRelativePath.split('/');
      let folderName, s3KeyValue;
      let tagsList: any = [];

      const defaultImageTags = (folderName?: string) => {
        const imageGroup: string = folderName || fileRelativePath[0]
        
        return fileRelativePath.length > 2
        ? [
            DRONE_IMG_TYPE.ORIG_FULL_RES,
            'image_group:' + imageGroup,
            'image_group_hierarchy:' + 
              fileRelativePath.slice(1,-1).join('/'),
          ]
        : [
            DRONE_IMG_TYPE.ORIG_FULL_RES,
            'image_group:' + imageGroup,
          ];
      }

      const s3KeyValueFunc = (folderName: string) => {
        return fileRelativePath.length > 2
          ? relativepath + '/' + item.webkitRelativePath
          : relativepath + '/' + folderName + '/' + item.webkitRelativePath;
      };

      switch (fileType) {
        case CONSTANTS_MISSION.droneImgTv:
          folderName = DRONE_IMG_TYPE.TOWER_VIEW;
          s3KeyValue = s3KeyValueFunc(folderName);
          tagsList = defaultImageTags(folderName);
          break;
        case CONSTANTS_MISSION.droneImgLos:
          folderName = DRONE_IMG_TYPE.LINE_OF_SIGHT;
          s3KeyValue = s3KeyValueFunc(folderName);
          tagsList = defaultImageTags(folderName);
          break;
        case CONSTANTS_MISSION.droneImageSV:
          folderName = DRONE_IMG_TYPE.TOP_TO_DOWN;
          s3KeyValue = s3KeyValueFunc(folderName);
          tagsList = defaultImageTags(folderName);
          break;
        case CONSTANTS_MISSION.droneImgNadir:
          folderName = DRONE_IMG_TYPE.FROM_ABOVE_VIEW;
          s3KeyValue = s3KeyValueFunc(folderName);
          tagsList = [
            DRONE_IMG_TYPE.ORIG_FULL_RES,
            'image_group:' + folderName,
            'image_group_hierarchy:' + CONSTANTS_MISSION.droneImgNadir,
          ];
          break;
        case CONSTANTS_MISSION.datasetDT:
          folderName = DRONE_IMG_TYPE.DIGITAL_TWIN;
          s3KeyValue = s3KeyValueFunc(folderName);
          tagsList =
            fileRelativePath.length > 2
              ? [folderName, fileRelativePath[1]]
              : [folderName];
          break;
        case CONSTANTS_MISSION.datasetCP:
          s3KeyValue = relativepath + '/' + item.webkitRelativePath;
          break;
        case CONSTANTS_MISSION.datasetF01:
          s3KeyValue = relativepath + '/' + item.webkitRelativePath;
          tagsList = fileRelativePath.reverse();
          tagsList.push(item.name, currentTime, 'F01');
          break;
        case CONSTANTS_MISSION.datasetMisc:
          s3KeyValue = relativepath + '/' + item.webkitRelativePath;
          tagsList = fileRelativePath.reverse();
          tagsList.push(currentTime, 'MISC');
          break;
        case CONSTANTS_MISSION.DRONE_IMAGE:
          uploadDetail();
          tagsList = defaultImageTags();
          break;
        case CONSTANTS_MISSION.VIDEO:
          uploadDetail();
          break;
        case CONSTANTS_MISSION.ORTHOMOSAIC:
          uploadDetail();
          break;
        case CONSTANTS_MISSION.PANORAMA:
          uploadDetail();
          break;
        case CONSTANTS_MISSION.VIRTUAL_TOUR:
          uploadDetail();
          break;
        case CONSTANTS_MISSION.ADDITIONAL_IMAGES:
          uploadDetail();
          tagsList = defaultImageTags();
          break;
        default:
          s3KeyValue = relativepath + '/' + item.webkitRelativePath;
      }

      function uploadDetail() {
        s3KeyValue = relativepath + '/' + item.webkitRelativePath;
        tagsList.push(DRONE_IMG_TYPE.ORIG_FULL_RES);
      }

      const uploadFileparams = {
        signalId: item.webkitRelativePath.length
          ? item.webkitRelativePath
          : item.name,
        Body: item,
        Bucket: data.credentials.s3Bucket,
        Key: s3KeyValue,
        tags: tagsList,
      };

      return uploadFileparams;
    });
  }

  getFileEtag(fileType: string, parentFileType: string, path: string) {
    if (fileType != parentFileType) {
      const existingFileIndex = this.uploadingFiles[parentFileType][
        fileType
      ].filesProgressList.findIndex((item: any) => item.signalId == path);
      if (existingFileIndex !== -1) {
        return this.uploadingFiles[parentFileType][fileType].filesProgressList[
          existingFileIndex
        ].etag;
      }
    } else {
      const existingFileIndex = this.uploadingFiles[
        fileType
      ].filesProgressList.findIndex((item: any) => item.signalId == path);
      if (existingFileIndex !== -1) {
        return this.uploadingFiles[fileType].filesProgressList[
          existingFileIndex
        ].etag;
      }
    }
  }

  getFileTag(fileType: string, file: any) {
    if (
      fileType == CONSTANTS_MISSION.datasetCP ||
      fileType == CONSTANTS_MISSION.datasetDT
    ) {
      return null;
    } else if (fileType == CONSTANTS_MISSION.droneImgNadir) {
      return DRONE_IMG_TYPE.FROM_ABOVE_VIEW + '/' + file.name;
    } else {
      return file.webkitRelativePath;
    }
  }
  async uploadFilesToS3(
    filesArray: File[],
    fileType: any,
    parentFileType: any,
    missionId: string,
    projectId: string,
    siteId: string
  ) {
    try {
      this.activeCollapseTitle = parentFileType;
      let dataResourceCounter = 0;
      let multiDataResourceCallPayload: any = {};

      const s3CredentialsInfo = await this.getS3Credentials(
        fileType,
        parentFileType,
        missionId,
        projectId
      );

      const xhrHttpHandler = new XhrHttpHandler({});
      let startTime = Date.now();
      xhrHttpHandler.on(
        XhrHttpHandler.EVENTS.UPLOAD_PROGRESS,
        (progress, request) => {
          if (progress.lengthComputable) {
            const loaded = progress.loaded ?? 0;
            const total = progress.total ?? 0;
            const uploadedFilePercentage = (loaded / total) * 100;

            const elapsedTime = Date.now() - startTime;
            const uploadedBytes = progress.loaded;
            const totalBytes = progress.total;
            const bytesRemaining = totalBytes - uploadedBytes;
            const uploadSpeed = uploadedBytes / (elapsedTime / 1000);
            const remainingTime = bytesRemaining / uploadSpeed;

            this.updateFilesProgressList(
              request.path ?? '',
              fileType,
              parentFileType,
              uploadedFilePercentage,
              remainingTime
            );
          }
        }
      );
      const client = new S3Client({
        region: s3CredentialsInfo.credentials.region,
        credentials: {
          accessKeyId: s3CredentialsInfo.credentials.accessKeyId,
          secretAccessKey: s3CredentialsInfo.credentials.secretAccessKey,
          sessionToken: s3CredentialsInfo.credentials.sessionToken,
        },
        // requestHandler: myHttpHandler,
        // requestHandler: xhrHttpHandler,
        requestHandler: new XhrHttpHandler({}),
      });
      interface UploadData {
        Body: File;
        Bucket: string;
        Key: string;
        signalId: string;
        tags: string[];
      }
      const url$: Observable<UploadData> = from([
        ...this.getAllFilesArray(filesArray, s3CredentialsInfo, fileType),
      ]);

      url$
        .pipe(
          mergeMap(async (param: UploadData) => {
            const requestAbortController = new AbortController();
            this.updateControllerMap(param.signalId, requestAbortController);

            const parallelUploads3 = new Upload({
              client: client,
              params: param,
              partSize: CONSTANTS_MISSION.MULTIPART_FILE_SIZE, // part size 200MB
              queueSize: 2,
              abortController: requestAbortController,
            });

            parallelUploads3.on('httpUploadProgress', (progress) => {
              const loaded = progress.loaded ?? 0;
              const total = param.Body.size;
              const uploadedFilePercentage = Math.min(
                (loaded / total) * 100,
                100
              );

              this.updateFilesProgressList(
                param.signalId,
                fileType,
                parentFileType,
                uploadedFilePercentage
              );
            });

            return parallelUploads3
              .done()
              .then((response) => ({ response, param }));
          }, CONSTANTS_MISSION.PIPELINE_SIZE),
          takeUntil(this.unsubscribeUploadData)
        )
        .subscribe(async ({ response, param }) => {
          const etag = response.ETag ?? '';

          this.trackFilesEtag(
            param.signalId,
            fileType,
            parentFileType,
            etag.replaceAll('"', ' ').trim()
          );

          const input = {
            Body: param.Body,
            Bucket: s3CredentialsInfo.credentials.s3Bucket,
            ETag: this.getFileEtag(fileType, parentFileType, param.signalId),
            Key: param.Key,
            tag: this.getFileTag(fileType, param.Body),
            tagList: param.tags,
          };

          const createDataResource = async (input: any, folderName?: string) => {
            try {
              const resp = await this.createDataResource(
                s3CredentialsInfo,
                input,
                fileType,
                dataResourceCounter,
                missionId,
                projectId,
                siteId
              );

              this.trackFilesDataResourceId(
                param.signalId,
                fileType,
                parentFileType,
                resp._id,
                folderName
              );

              this.uploadDatasetService.checkUploadedFileList.next('');
              this.uploadDatasetService.checkFileIsAvailable.next('');
            } catch (error) {
              if (!(error instanceof HttpErrorResponse)) {
                return;
              }

              const errorMsg = error.error.message;
              let msg = errorMsg;
              if (
                error.status === 400 &&
                errorMsg.includes(DUPLICATE_TXT)
              ) {
                msg = NOTIFY_MSG.UPLOAD_DUPLICATE;
              }

              this.notificationService.showToast({
                type: EventTypes.error,
                message: msg,
              });

              this.updateFilesProgressList(
                param.signalId,
                fileType,
                parentFileType,
                -1
              );
            }
          };

          if (
            fileType != CONSTANTS_MISSION.datasetDT &&
            fileType != CONSTANTS_MISSION.VIRTUAL_TOUR
          ) {
            await createDataResource([input]);
          } else {
            const rootFolderName = input.Body.webkitRelativePath.split('/')[0];
            const uniquePath = `${fileType}/${rootFolderName}`

            if (!multiDataResourceCallPayload[uniquePath]) {
              multiDataResourceCallPayload[uniquePath] = [input];
            } else {
              multiDataResourceCallPayload[uniquePath].push(input);
            }

            if (
              this.isMultiFilesFinishUpload(
                fileType,
                parentFileType,
                multiDataResourceCallPayload[uniquePath],
                rootFolderName
              )
            ) {
              await createDataResource(
                multiDataResourceCallPayload[uniquePath],
                rootFolderName
              );
            }
          }
        });
    } catch (error: any) {}
  }

  async deleteFilesFromS3(
    filesArray: File[],
    fileType: any,
    parentFileType: any,
    missionId: string,
    projectId: string | null = null
  ) {
    this.activeCollapseTitle = parentFileType;
    const s3CredentialsInfo = await this.getS3Credentials(
      fileType,
      parentFileType,
      missionId,
      projectId
    );
    const client = new S3Client({
      region: s3CredentialsInfo.credentials.region,
      credentials: {
        accessKeyId: s3CredentialsInfo.credentials.accessKeyId,
        secretAccessKey: s3CredentialsInfo.credentials.secretAccessKey,
        sessionToken: s3CredentialsInfo.credentials.sessionToken,
      },
    });
    const url$ = from([
      ...this.getAllFilesArray(filesArray, s3CredentialsInfo, fileType),
    ]);
    const pipFunc = async (urls: any) => {
      await urls
        .pipe(
          mergeMap(async (param: any) => {
            await client
              .send(
                new DeleteObjectCommand({
                  Bucket: s3CredentialsInfo.credentials.s3Bucket,
                  Key: param.Key,
                })
              )
              .then((resp: any) => {
                return resp;
              })
              .catch((error) => {});
          }, 20),
          map((response) => {}),
          toArray()
        )
        .toPromise();
    };

    await pipFunc(url$);
  }
  async createDataResource(
    credInfo: any,
    file: any,
    fileType: string,
    dataResourceCounter: number,
    missionId: string,
    projectId: string,
    siteId: string
  ) {
    let respFiles: any = [];
    let counter = 0;
    const workspaceId = this.getUserInfo().activeWorkspaceId;

    for (const f of file) {
      respFiles.push({
        s3Key: f.Key,
        s3Bucket: f.Bucket,
        sizeBytes: f.Body.size,
        extension: f.Body.name.substr(f.Body.name.indexOf('.') + 1),
        s3Etag: f.ETag,
        origUploadRelativePath: f.tag,
      });
    }

    const payload: ICreateDataResource = {
      workspaceId: workspaceId,
      type: 'data_resource',
      projectId: projectId,
      missionId: missionId,
      siteId: siteId,
      resourceType: credInfo.resourceType,
      tags: file[counter].tagList || [],
      metadata: {},
      storage: {
        storageType: 's3',
        files: respFiles,
      },
    };
    !siteId && delete payload.siteId;

    let response: any = await lastValueFrom(
      this.uploadDatasetService.createDataResources(payload)
    );
    counter++;
    dataResourceCounter++;
    if (dataResourceCounter == 1 && fileType == CONSTANTS_MISSION.droneImgTv) {
      this.sitePlaceHolderResTag = response.id;
    }
    return response;
  }

  isAllFilesUploaded(fileType: string, parentFileType: string) {
    let allFilesUploaded = true;

    const helper = (uploadingFiles: any) => {
      if (!uploadingFiles.filesProgressList.length) allFilesUploaded = false;
      for (const fileProgress of uploadingFiles.filesProgressList) {
        if (fileProgress.progress < 100) allFilesUploaded = false;
      }
    };

    if (fileType != parentFileType) {
      helper(this.uploadingFiles[parentFileType][fileType]);
    } else {
      helper(this.uploadingFiles[fileType]);
    }

    return allFilesUploaded;
  }

  isMultiFilesFinishUpload(
    fileType: string,
    parentFileType: string,
    totalUploaded: [],
    rootFolderName: string
  ) {
    let allFilesUploaded = true;

    const helper = (uploadingFiles: any) => {
      if (!uploadingFiles.filesProgressList.length) allFilesUploaded = false;
      else {
        const totalFiles = uploadingFiles.filesProgressList.filter(
          (fileProgress: any) => {
            return (
              fileProgress.file.webkitRelativePath.split('/')[0] ===
              rootFolderName
            );
          }
        );

        allFilesUploaded = totalUploaded.length >= totalFiles.length;
      }
    };

    if (fileType != parentFileType) {
      helper(this.uploadingFiles[parentFileType][fileType]);
    } else {
      helper(this.uploadingFiles[fileType]);
    }

    return allFilesUploaded;
  }

  isAllSiteFilesUploaded() {
    let allFilesUploaded = true;
    const childKeys = Object.keys(this.uploadingFiles);
    for (const element of childKeys) {
      if (this.uploadingFiles?.filesProgressList) {
        allFilesUploaded = this.isAllFilesUploaded(element, element);
      } else {
        const nestedChildKeys = Object.keys(this.uploadingFiles[element]);
        for (const nestedElement of nestedChildKeys) {
          if (this.uploadingFiles[element][nestedElement]?.filesProgressList) {
            allFilesUploaded = this.isAllFilesUploaded(nestedElement, element);
          }
        }
      }
    }
    return allFilesUploaded;
  }

  async getProjectInfo(projectId: string | null) {
    if (projectId) {
      return {
        f01: lastValueFrom(
          this.uploadDatasetService.getDataResource({
            projectId: projectId,
            preSignedUrl: true,
            workspaceId: this.getUserInfo().activeWorkspaceId,
            type: 'f01',
          })
        ),
        misc: lastValueFrom(
          this.uploadDatasetService.getDataResource({
            projectId: projectId,
            preSignedUrl: true,
            workspaceId: this.getUserInfo().activeWorkspaceId,
            type: 'misc',
          })
        ),
      };
    }
    return null;
  }

  async downloadF01(filesArray: { preSignedUrl: string; tags: string[] }[]) {
    const downloadableLink = document.createElement('a');
    downloadableLink.href = filesArray[0].preSignedUrl;
    downloadableLink.download = 'F01File';
    downloadableLink.click();
  }

  async downloadMiscZip(
    filesArray: { preSignedUrl: string; tags: string[] }[]
  ) {
    const downloadableZip = new JSZip();
    this.downloadList = filesArray.map(() => new Subject<number>());
    this.showDownload.next(true);
    const fileNames: string[] = [];
    const filesListZip = filesArray.map(async ({ preSignedUrl, tags }, idx) => {
      const arrayBuffer =
        await this.uploadDatasetService.getArrayBufferFromPreSignedUrl(
          preSignedUrl,
          idx,
          this.downloadList
        );
      let folder = '';
      const filteredTags = tags.slice(0, -2).reverse();
      filteredTags.forEach((val, idx) => {
        if (idx === filteredTags.length - 1) {
          fileNames.push(val);
        } else {
          folder += `${val}/`;
        }
      });
      folder = folder.slice(0, -1);
      return {
        folder,
        arrayBuffer,
      };
    });
    const resultsFilesListZip = await Promise.all(filesListZip);
    resultsFilesListZip.forEach(({ folder, arrayBuffer }, idx) => {
      downloadableZip
        .folder(folder)
        ?.file(fileNames[idx], arrayBuffer, { compression: 'STORE' });
    });
    const zipContent = await downloadableZip.generateAsync({ type: 'blob' });
    const downloadableLink = document.createElement('a');
    const downloadableURL = URL.createObjectURL(zipContent);
    downloadableLink.download = resultsFilesListZip[0].folder.split('/')[0];
    downloadableLink.href = downloadableURL;
    downloadableLink.click();
    URL.revokeObjectURL(downloadableURL);
    this.showDownload.next(false);
  }

  trackFilesDataResourceId(
    filePath: string,
    fileType: string,
    parentFileType: string,
    dataResourceId: string,
    folderName?: string
  ) {
    //Tracking  Data Resource id In this Function
    if ([CONSTANTS_MISSION.datasetDT, CONSTANTS_MISSION.VIRTUAL_TOUR].includes(parentFileType)) {
      //if parent file type is digital Twin
      this.uploadingFiles[parentFileType] = {
        ...this.uploadingFiles[parentFileType],
        dataResources: [
          {
            dataResourceId,
            folderName,
          },
          ...this.uploadingFiles[parentFileType].dataResources
        ],
      };
    } else if (fileType != parentFileType) {
      //if parent file type is drone Image
      const existingFileIndex = this.uploadingFiles[parentFileType][
        fileType
      ].filesProgressList.findIndex((item: any) => item.signalId == filePath);
      if (existingFileIndex !== -1) {
        //if file not found according to above logic add dataResourceId to those file properties
        this.uploadingFiles[parentFileType][fileType].filesProgressList[
          existingFileIndex
        ] = {
          ...this.uploadingFiles[parentFileType][fileType].filesProgressList[
            existingFileIndex
          ],
          dataResourceId,
        };
      }
    } else {
      //if parent file type is camera parameter
      const existingFileIndex = this.uploadingFiles[
        fileType
      ].filesProgressList.findIndex((item: any) => item.signalId == filePath);
      if (existingFileIndex !== -1) {
        this.uploadingFiles[fileType].filesProgressList[existingFileIndex] = {
          ...this.uploadingFiles[fileType].filesProgressList[existingFileIndex],
          dataResourceId,
        };
      }
    }

    this._simulatedProgressFilesList.next(this.uploadingFiles);
  }

  resetFilesEmpty(fileType: string, parentFileType: string) {
    //resetting  all files empty and there progress list empty
    if (fileType !== parentFileType) {
      this.uploadingFiles = {
        ...this.uploadingFiles,
        [parentFileType]: {
          ...this.uploadingFiles[parentFileType],
          [fileType]: {
            ...this.uploadingFiles[parentFileType][fileType],
            files: [],
            filesProgressList: [],
          },
        },
      };
    } else {
      this.uploadingFiles = {
        ...this.uploadingFiles,
        [fileType]: {
          ...this.uploadingFiles[fileType],
          files: [],
          filesProgressList: [],
        },
      };
    }
    this._simulatedProgressFilesList.next(this.uploadingFiles);
  }

  checkFilesProgressList(fileType: string, parentFileType: string) {
    if (fileType != parentFileType) {
      return this.uploadingFiles[parentFileType][fileType].filesProgressList
        .length;
    }
    return this.uploadingFiles[fileType].filesProgressList.length;
  }

  resetFiles() {
    this.uploadingFiles = JSON.parse(
      JSON.stringify(this.uploadingFilesInitialValues)
    );
  }

  isFileExisted(
    fileList: File[] | FileList,
    fileType: string,
    parentFileType: string
  ) {
    const helper = (uploadingFiles: any) => {
      const categoryFilesList = uploadingFiles.filesProgressList;
      const existingFileNames = new Set(
        categoryFilesList?.map(
          (file: { file: File; name: string }) => file.file.webkitRelativePath
        )
      );
      for (const file of Array.from(fileList)) {
        if (existingFileNames.has(file.webkitRelativePath)) {
          return true;
        }
      }
      return false;
    };

    if (fileType != parentFileType) {
      return helper(this.uploadingFiles[parentFileType][fileType]);
    } else {
      return helper(this.uploadingFiles[parentFileType]);
    }
  }
  resetSimulate() {
    this._simulatedProgressFilesList.next([]);
  }
  resetCreateMissionParams() {
    this.createMissionParams_signal.set(this.initialMissionParams);
  }
}