<div class="upload-progress-container">
  <div *ngFor="let workflowProgress of workflowDetails" [ngClass]="workflowProgress.isWorkFlowStarted ? 'upload-progress-wrapper' : 'd-none'">
    <ng-template [ngIf]="workflowProgress.isWorkFlowStarted">
      <div class="upload-header">
        <h3 class="title">{{ workflowProgress?.siteName }}</h3>
        <button class="btn-close" (click)="onHideProgressBar(workflowProgress.runningWorkFlowId)">
          <img [src]="icon.CROSS_ICON | assetUrl" alt="close" />
        </button>
      </div>
      <div class="upload-heading">
        <h3 [ngClass]="!workflowProgress.isWorkFlowStarted ? 'title mb-0' : 'title'">{{ workflowProgress?.workflowStep?.length ?  workflowProgress.workflowStep : progress_waiting_msg }}</h3>
        <span [ngClass]="!workflowProgress.isWorkFlowStarted ? 'd-none' : 'count'">{{ workflowProgress.workflowProgress }}%</span>
      </div>
      <div [ngClass]="!workflowProgress.isWorkFlowStarted ? 'd-none' : 'progress progress-holder'">
        <div
          class="progress-bar show-progress"
          role="progressbar"
          [style.width.%]="workflowProgress.workflowProgress"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="progressPercentage"
          ></div>
      </div>
      <div class="icons-wrapper">
        <img [src]="icon.RESTART_ICON | assetUrl" (click)="onWorkflowRestart(workflowProgress.runningWorkFlowId)" class="edit-workflow-icon" />
        <img [src]="icon.FAIL_ICON | assetUrl" (click)="onWorkflowCancel(workflowProgress.runningWorkFlowId)" class="edit-workflow-icon" />
      </div>
    </ng-template>
  </div>   
</div> 
