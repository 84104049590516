import { Pipe, PipeTransform } from '@angular/core';
import { FilesProgressList } from '../../interfaces/file-upload-dataset.interfaces';

@Pipe({
  name: 'overallUploadTimeRemaining',
})
export class OverallUploadTimeRemainingPipe implements PipeTransform {

  transform(filesProgressList: FilesProgressList[] ): string | number {
    if (!filesProgressList) {
      return 0;
    }
    let totalTimeRemaining = 0
    filesProgressList.forEach((item) =>totalTimeRemaining += item.timeRemaining ? item.timeRemaining : 0);
    const convertedTime = Math.floor(totalTimeRemaining)
    const minutes = `${convertedTime} minutes left`
    const second = `${convertedTime} second left`
    const timeRemaining = convertedTime / 60 >= 1 ? minutes : second
    return timeRemaining
  }

}
