import { Component, OnDestroy, OnInit, Input, SimpleChange, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ICreateMissionGetStatus, ICreateMissionObjective, ICreateMissionProject, ICreateMissionSiteData, ICreateMissionStatusAndProject, MissionProject } from '../../models/create-mission-dataset.model';
import { Router } from '@angular/router';
import { Observable, Subscription, interval } from 'rxjs';
import { MissionSharedService } from 'projects/data-upload/src/app/services/mission-shared.service';
import { UploadDatasetService } from 'projects/data-upload/src/app/services/upload-dataset.service';
import { CreateMissionService } from 'projects/data-upload/src/app/services/create-mission.service';
import {
  CONSTANTS_MISSION,
  MISSION_DATA_TYPES,
  MISSION_STATUS,
  NOTIFY_MSG,
  WORKFLOW_STATUS,
} from 'projects/data-upload/src/app/constants/create-mission.const';
import { CreateMissionWorkflowService } from 'projects/data-upload/src/app/services/create-mission-workflow.service';
import { IMissionWorkflowDef, IWorkflowProgress } from 'projects/data-upload/src/app/interfaces/create-mission-workflow.interface';
import { WorkflowSharedService } from 'projects/data-upload/src/app/services/workflow-shared.service';
import { USER_PERMISSIONS } from 'projects/data-upload/src/app/user-permissions/user-permission.const';
import { SharedFunctionsService } from 'projects/data-upload/src/app/services/shared-functions.service';

@Component({
  selector: 'app-create-mission',
  templateUrl: './create-mission.component.html',
  styleUrls: ['./create-mission.component.scss']
})
export class CreateMissionComponent implements OnInit, OnDestroy {
  @Output() onClosePanel = new EventEmitter()
  UploadDatasetForm!: FormGroup;
  loadMissionDetail: boolean = false;
  loadUploadDataset: boolean = false;
  backToCreateMission: boolean = false;
  hideMission: boolean = false;
  isSubmitted = false;
  createMissionFormData: any;
  updateMissionFormData: any = [];
  getSiteID: any;
  missionProjects: ICreateMissionProject = {
    meta: {},
    records: []
  };
  missionSiteAsset: ICreateMissionSiteData[] = [];
  missionSiteInfo: any = [];
  siteId!: string;
  missionProjectEvent!: Subscription;
  missionSiteEvent!: Subscription;
  missionSiteInfoEvent!: Subscription;
  missionProjectStatusEvent! : Subscription;
  cancelWorkFlowEvent! : Subscription;
  workflowRunStatusEvent! : Subscription;
  hideMissionSection: boolean = false;
  isShowMissionConfirmation: boolean = false;
  selectedProjectId!: string;
  selectedAssetId!: string | null;
  canvasLink: string = "";
  isMissionCreated: boolean = false;
  isValidCanvasUrl: boolean = false;
  projectRequired: boolean = true;
  assetRequired: boolean = true;
  missionObjective!: Array<any>;
  missionObjectiveResponse: any;
  missionObjectives : any;
  missionObjectiveList?: ICreateMissionObjective;
  isDetailUpdated: boolean = false;
  siteInfoList: any = [];
  sitePlaceholderImage!: string;
  getMissionStatusResp: any;
  isWorkflowInProcess: boolean = false;
  isCallWorkflowApi: boolean = false;
  workflowNotStarted: boolean = false;
  progress: number = 0;
  workflowSteps!: string;
  stopWorkflow: boolean = false;
  workflowRunResponse: any;
  isShowCancelWorkflowPopup: boolean = false;
  cancelWorkflow: boolean = false;
  runExistingWorkflow: boolean = false;
  workflowRunning: boolean = false;
  activeMissionId!: string;
  workflowRunningStatusResponse:any;
  missionProjectStatus: ICreateMissionStatusAndProject = {
    records:[]
  };
  completedProjects: MissionProject[] = [];
  activeProjects: MissionProject[] = [];
  draftProjects: MissionProject[] = []
  completeSelected!: MissionProject;
  activeSelected!: MissionProject;
  draftSelected!: MissionProject;
  dropdownTypeFlag: boolean = false;
  toasterClass: string = '';
  toasterMsg: string = '';
  isShowToaster: boolean = false;
  USER_PERMISSIONS = USER_PERMISSIONS;
  missionDataTypes = MISSION_DATA_TYPES;
  selectDisabled: boolean = true;
  showUploadSection: boolean = false;
  MISSION_CONSTANTS = CONSTANTS_MISSION;
  showReplaceWarning: boolean = false;
  latestMiscData!: {preSignedUrl: string, tags: string[]}[];
  latestF01Data!: {preSignedUrl: string, tags: string[]}[];
  miscFilesName: string[] = [];
  f01FileName!: string;
  showReplaceModal: boolean = false;
  continueUploading: boolean = true;
  latestMission!: {
    assetIds: string[]
    id: string
    projectId: string
    recordType: string
    siteId: string
    status: string
    workspaceId: string
  };
  uploadData: {
    files: File[] | FileList,
    fileType: string,
    parentFileType: string,
  } = {
    files: [],
    fileType: '',
    parentFileType: ''
  };
  showUploadBar: boolean = true;
  showDownloadBar: boolean = false;
  selectedMiscFile: string | null = null;
  selectedF01File: string | null = null;
  workflowProgressArray: IWorkflowProgress[] = [];
  activeIndex= 1;
  gridFlow = false;
  gridFlowFlag = false;
  selectedMissionObjective = null
  workFlowStatus = WORKFLOW_STATUS;
  createMissionParams = this.missionSharedService.createMissionParams_signal
  constructor(
    private uploadDatasetFBuilder: FormBuilder,
    private router: Router,
    private missionSharedService: MissionSharedService,
    private uploadDatasetService: UploadDatasetService,
    private createMissionService: CreateMissionService,
    private missionWorkflowService: CreateMissionWorkflowService,
    public workflowSharedService: WorkflowSharedService,
    private sharedFunctionsService : SharedFunctionsService
    ) {}

  ngOnInit() {
    this.workflowSharedService.workflowProgressArraySubject
    .subscribe((updatedWorkflowProgresses) => {
      if(updatedWorkflowProgresses.length) this.workflowProgressArray = [...updatedWorkflowProgresses];
    });
    this.activeIndex = 1;
    if(this.gridFlowFlag) this.activeIndex = 2;
    this.resetUploadingFiles();
    this.missionSharedService.initShowProgressBar();
    this.hideMissionSection = false;
    if ((!this.hideMission && this.loadUploadDataset) || (!this.hideMission && this.isMissionCreated)) {
      this.isShowMissionConfirmation = true;
    } else {
      this.getFormValues();
      this.selectedAssetId = null;
      const { siteId } = this.createMissionParams();
      this.siteId = siteId
      this.projectRequired = true;
      this.assetRequired = true;
      this.missionProjectEvent = this.createMissionService.getMissionProjects().subscribe((response: ICreateMissionProject) => {
        this.missionProjects = response;
        if(this.missionProjects.records.length>0){
          this.missionProjectStatusEvent = this.createMissionService.getMissionStatus(this.siteId).subscribe((response: any) => {
            this.missionProjectStatus = response;
            this.groupMissionsByStatusAndProject();
          });
        }
      });
      this.missionSiteEvent = this.createMissionService.getMissionSiteInfo(this.siteId).subscribe((response: ICreateMissionSiteData[]) => {
        this.missionSiteInfo = response;
        this.missionSiteAsset = this.missionSiteInfo.attachedAsset;
        if (this.missionSiteInfo.siteImageDataResourceId) {
          this.missionSiteInfoEvent = this.uploadDatasetService.getImgFromDataResource(this.missionSiteInfo.siteImageDataResourceId).subscribe((response: ICreateMissionSiteData[]) => {
            this.siteInfoList = response;
            this.sitePlaceholderImage = this.siteInfoList.storage.files[0].preSignedUrl;
          });
        }
      });
      this.missionObjectiveResponse = this.createMissionService.getMissionObjective();
      this.isShowMissionConfirmation = false;
    }
    this.getFormValues();
    this.missionSharedService.showDownload.subscribe((value) => {
      this.showDownloadBar = value;
      if(value) {
        this.showUploadBar = false;
      } else {
        this.showUploadBar = true;
      }
    })
    this.missionObjectives = this.createMissionService.getMissionObjective()
  }


  ngOnDestroy() {
    this.missionProjectEvent?.unsubscribe();
    this.missionSiteEvent?.unsubscribe();
    this.workflowRunStatusEvent?.unsubscribe();
    this.cancelWorkFlowEvent?.unsubscribe();
    this.missionProjectStatusEvent?.unsubscribe();
  }

  groupMissionsByStatusAndProject() {
    const projectMap: Map<string, any> = new Map();
    const completedMissions:  MissionProject[] = [];
    const activeMissions:  MissionProject[] = [];
    const draftMissions: MissionProject[] = [];
    this.dropdownTypeFlag = false;
    this.missionProjectStatus.records.forEach((statusAndProject: any) => {
      const project = this.missionProjects.records.find((p) => p._id === statusAndProject.projectId);
      if (project) {
        let projectId = project._id;
        const projectIdAsString = projectId.toString();
        if (!projectMap.has(projectIdAsString)) {
          projectMap.set(projectIdAsString, true);
          switch (statusAndProject.status) {
            case 'COMPLETED':
              completedMissions.push(project);
              break;
            case 'ACTIVE':
              activeMissions.push(project);
              break;
            default:
              draftMissions.push(project);
              break;
          }
        }
      }
    });
    this.sortProjectsByDate(completedMissions, (item) => this.getUpdatedAt(item._id));
    this.sortProjectsByDate(activeMissions, (item) => this.getUpdatedAt(item._id));
    this.sortProjectsByDate(draftMissions, (item) => this.getUpdatedAt(item._id));
    this.missionProjects.records.forEach((project) => {
      const projectId = project._id;
      if (
        !completedMissions.some((p) => p._id === projectId) &&
        !activeMissions.some((p) => p._id === projectId) &&
        !draftMissions.some((p) => p._id === projectId)
      ) {
        draftMissions.push(project);
      }
    });
    if (completedMissions.length > 0) {
      this.selectedProjectId = completedMissions[0]._id.toString();
      this.completeSelected = completedMissions[0];
    } else if (activeMissions.length > 0) {
      this.selectedProjectId = activeMissions[0]._id.toString();
      this.activeSelected = activeMissions[0];
    } else if (draftMissions.length > 0) {
      this.selectedProjectId = draftMissions[0]._id.toString();
      this.draftSelected = draftMissions[0];
    }
    this.completedProjects = completedMissions;
    this.activeProjects = activeMissions;
    this.draftProjects = draftMissions;
    this.getF01MiscInfo(this.selectedProjectId!);
    if(this.missionProjectStatus.records.length === 0){
      this.dropdownTypeFlag = true;
      if (this.dropdownTypeFlag) {
        this.selectedProjectId = '';
      }
    }
  }

  sortProjectsByDate(projects: any[], dateExtractor: (item: any) => string) {
    projects.sort((first, second) => {
      const firstDate = new Date(dateExtractor(first)).getTime();
      const secondDate = new Date(dateExtractor(second)).getTime();
      return secondDate - firstDate;
    });
  }

  getUpdatedAt(projectId: number): string {
    const record = this.missionProjectStatus.records.find((record) => record.projectId === projectId);
    if (record) {
      return record.updatedAt;
    }
    return '';
  }

  getFormValues() {
    this.UploadDatasetForm = this.uploadDatasetFBuilder.group({
      missionProject: ['', [Validators.required]],
      missionAsset: ['', [Validators.required]],
      missionDataTypes: [{value: '', disabled: true}]
    })
    this.showUploadSection = false;
  }

  async changeMissionProject(event: any) {
    this.projectRequired = true;
    this.selectedProjectId = event.target.value.split(":")[1].trim();
    this.isDetailUpdated = true;
    this.getF01MiscInfo(this.selectedProjectId!);
  }

  changeMissionAsset(event: any) {
    this.assetRequired = true;
    this.selectedAssetId = event.target.value;
  }

  changeMissionDataType(event: any) {
    if(event.target.value === MISSION_DATA_TYPES.AUXILIARY_DATA) {
      this.showUploadSection = true;
    } else {
      this.showUploadSection = false;
    }
  }

  validateCanvasBtn() {
    if (this.selectedProjectId && this.selectedAssetId && this.siteId) {
      this.isValidCanvasUrl = true;
      this.projectRequired = true;
      this.assetRequired = true;
      this.router.navigate(['/canvas'], {
        queryParams: {
          project_id: this.selectedProjectId,
          site_id: this.siteId,
          asset_id: this.selectedAssetId,
        },
      });
      this.closeMission();
    } else {
      this.isValidCanvasUrl = false;
      this.projectRequired = !!this.selectedProjectId;
      this.assetRequired = false;
    }
  }

  initUploadDatasetComponent(value: any) {
    this.loadUploadDataset = value;
    this.backToCreateMission = false;
    if (value === false) {
      this.loadMissionDetail = false;
    }
  }
  backToParentComp(value: any) {
    this.backToCreateMission = value;
    this.loadUploadDataset = false;
    this.isMissionCreated = value;
    this.isDetailUpdated = false;
  }
  initHideMission() {
    if (!this.loadUploadDataset && !this.isMissionCreated) {
      this.closeMission();
    } else {
      this.isShowMissionConfirmation = true;
    }
  }

  closeMission(
    { isProcessCompleted } : { isProcessCompleted: boolean } = {
      isProcessCompleted: false,
    }
  ) {
    this.hideMission = true;
    this.isMissionCreated = false;
    this.isShowMissionConfirmation = false;
    this.loadUploadDataset = false;
    this.loadMissionDetail = false;
    this.isDetailUpdated = false;
    this.backToCreateMission = false;
    this.hideMissionSection = true;
    if(!isProcessCompleted) {
      localStorage.removeItem('camera_param');
      localStorage.removeItem('drone_img');
      localStorage.removeItem('digital_twin');
      localStorage.removeItem('digital_twin_tile');
      this.dispatchCloseMission();
      this.getFormValues();
      this.selectedAssetId= null;
      this.selectedProjectId = '';
    }
    this.onClosePanel.emit(true)
  }

  dispatchCloseMission() {
    const customEvent = new CustomEvent('dronos:create-mission:closeMission')
    window.dispatchEvent(customEvent)
  }

  stayAliveMission() {
    this.hideMission = false;
    this.isShowMissionConfirmation = false;
  }

  inithideMission(value: boolean) {
    this.hideMission = value;
    this.isMissionCreated = false;
  }

  cancelWorkflowProcess(value: boolean) {
    this.cancelWorkflow = value;
    this.isShowCancelWorkflowPopup = false;
    this.workflowSharedService.terminateWorkFlowStatusApi = true;
    this.cancelwWorkflowRunProcess(this.activeMissionId);
  }

  isWorkFlowStarted() {
    return this.workflowSharedService.isWorkFlowStarted
  }

  stayOnMission(value: boolean) {
    this.cancelWorkflow = value;
    this.isShowCancelWorkflowPopup = false;
    if(value) {
      this.cancelwWorkflowRunProcess(this.activeMissionId);
    } else {
      this.hideMission = value;
    }
  }

  onReplaceConsent(value: boolean) {
    if(value) {
      this.showReplaceModal = false;
      this.continueUploading = true;
      this.uploadS3(this.uploadData.files, this.uploadData.fileType, this.uploadData.parentFileType);
    } else {
      this.showReplaceModal = false;
      this.continueUploading = true;
    }
  }

  cancelwWorkflowRunProcess(id: string) {
    this.workflowRunResponse = [];
    let activeWorkflowId: any;
    this.workflowRunStatusEvent = this.missionWorkflowService.getWorkflowRunStatus(id).subscribe((resp: any) => {
    if (resp.records.length < 1) return
    this.workflowRunResponse = resp;
      activeWorkflowId = this.workflowRunResponse.records[0]._id;
      this.cancelWorkFlowEvent = this.missionWorkflowService.updateWorkflowStatus(activeWorkflowId, WORKFLOW_STATUS.ABORTED).subscribe((response:IMissionWorkflowDef[]) => {
        this.workflowRunningStatusResponse = response;
        if(this.workflowRunningStatusResponse.status === WORKFLOW_STATUS.ABORTED){
          const missionStatus = { status: MISSION_STATUS.PENDING };
          this.createMissionService.updateMissionStatus(id, missionStatus).toPromise();
        }
      })
    })
    this.cancelWorkflow = false;
    this.isWorkflowInProcess = false;
    this.workflowSharedService.updateWorkFlowStatusById({workflowId: activeWorkflowId, status: false});
    this.createMissionFormData = this.UploadDatasetForm.value;
    this.getSiteID = this.siteId;
    this.loadMissionDetail = true;
    this.missionObjectiveList = this.missionObjectiveResponse;
    this.workflowRunning = false;
    this.showToasterMsg({
      toasterClass: NOTIFY_MSG.SUCCESS_CLASS,
      toasterMsg: NOTIFY_MSG.WORKFLOW_CANCELLED,
      timer: 2000
    })
  }

  callWorkflowApiMultiple(milliseconds: number) {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds)
    });
  }

  onSumbitDataset() {
    this.isSubmitted = true;
    if (!this.UploadDatasetForm.valid) {
      this.isSubmitted = false;
    } else {
      const projectField = this.UploadDatasetForm.get('missionProject');
      const assetField = this.UploadDatasetForm.get('missionAsset');
      projectField?.valueChanges.subscribe(value => {
        this.isDetailUpdated = true
        if (this.updateMissionFormData.projectId !== 'undefined') {
          this.updateMissionFormData.push({
            projectId: value
          });
        }
      })
      assetField?.valueChanges.subscribe(value => {
        this.isDetailUpdated = true
        if (this.updateMissionFormData.assetId !== 'undefined') {
          this.updateMissionFormData.push({
            assetId: value
          });
        }
      });
      this.createMissionService.getCurrentMissionStatus(this.siteId, MISSION_STATUS.ACTIVE)
        .subscribe(async (response: ICreateMissionGetStatus) => {
          this.getMissionStatusResp = response;
          const [latestMission] = this.getMissionStatusResp.records;
          if (latestMission && latestMission.status === MISSION_STATUS.ACTIVE) {
            this.isShowCancelWorkflowPopup = true;
            this.activeMissionId = latestMission._id;
          } else {
            this.createMissionFormData = this.UploadDatasetForm.value;
            this.getSiteID = this.siteId;
            this.loadMissionDetail = true;
            this.missionObjectiveList = this.missionObjectiveResponse;
            this.workflowRunning = false;
          }
      });
      this.updateActiveIndex(2);
    }
  }

  isInputDisabled(fileType:string, parentFileType:string ) {
    return this.missionSharedService.isAllFilesUploaded(fileType,parentFileType);
  }

  onMissionAssetChange(missionAssetValue: string) {
    if(missionAssetValue && missionAssetValue.length) {
      this.UploadDatasetForm.controls['missionDataTypes'].enable();
    } else {
      this.UploadDatasetForm.controls['missionDataTypes'].disable();
    }
  }

  onMissionDataChange(missionDataValue: string) {
    if(missionDataValue && missionDataValue.length) {
      if(missionDataValue === MISSION_DATA_TYPES.AUXILIARY_DATA) {
        this.showUploadSection = true;
      } else {
        this.showUploadSection = false;
      }
    }
  }

  uploadS3 (files: File[] | FileList, fileType: string, parentFileType: string) {
    this.sharedFunctionsService.uploadS3(files, fileType, parentFileType);
  }
  getF01MiscInfo (projectId: string) {
    this.sharedFunctionsService.getF01MiscInfo(projectId)
  }

  private showToasterMsg ({ toasterClass, toasterMsg, timer = 5000 }: { toasterClass: string, toasterMsg: string, timer?: number }) {
    this.isShowToaster = true;
    this.toasterClass = toasterClass;
    this.toasterMsg = toasterMsg;
    setTimeout(() => {
      this.isShowToaster = false;
    }, timer);
  }

  resetUploadingFiles() {
    this.missionSharedService.resetFiles()
    this.missionSharedService.resetSimulate()
  }

  handleGridFlag(flag: boolean) {
      this.gridFlow = flag;
  }

  updateActiveIndex(id: number){
    this.activeIndex = id;
  }

  onMissionObjectiveChange(value:any) {
    this.selectedMissionObjective = value
  }
}
