import { CONSTANTS_MISSION } from "./create-mission.const";

export const UPLOAD_DATASET = {
  CAMERA_PARAMS: 'CameraParams',
  THREE_D_MODEL: 'ThreeDModel',
  DRONE_IMAGES: 'DroneImages',
  DRONE_IMAGES_TOWER_VIEW: 'TowerView',
  DRONE_IMAGES_LINE_OF_SIGHT: 'LineOfSight',
  DRONE_IMAGES_TOP_TO_DOWN: 'TopToDown',
  DRONE_IMAGES_NADIR: 'Nadir',
  ORTHOMOSAIC: 'Orthomosaic',
  DRONE_IMAGE: 'DroneImage',
  VIDEO: 'Video',
  PANORAMA_360: 'Panorama360',
  ADDITIONAL_IMAGES: 'AdditionalImages',
}

export const UPLOAD_DATASET_TYPE = {
  CAMERA_PARAMS: {
    title: 'Camera Parameters',
    resourceType: 'cameraParams',
    id: UPLOAD_DATASET.CAMERA_PARAMS
  },
  THREE_D_MODEL: {
    title: '3D Model',
    resourceType: 'meshObj',
    id: UPLOAD_DATASET.THREE_D_MODEL
  },
  DRONE_IMAGES: {
    title: 'Drone Images',
    resourceType: 'imageRGB',
    id: UPLOAD_DATASET.DRONE_IMAGES
  },
  DRONE_IMAGES_TOWER_VIEW: {
    title: 'Tower View',
    id: UPLOAD_DATASET.DRONE_IMAGES_TOWER_VIEW
  },
  DRONE_IMAGES_LINE_OF_SIGHT: {
    title: 'Line Of Sight',
    id: UPLOAD_DATASET.DRONE_IMAGES_LINE_OF_SIGHT
  },
  DRONE_IMAGES_TOP_TO_DOWN: {
    title: 'Top To Down',
    id: UPLOAD_DATASET.DRONE_IMAGES_TOP_TO_DOWN
  },
  DRONE_IMAGES_NADIR: {
    title: 'Nadir',
    id: UPLOAD_DATASET.DRONE_IMAGES_NADIR
  },
  ORTHOMOSAIC: {
    title: 'Orthomosaic',
    resourceType: 'orthoRGB',
    id: UPLOAD_DATASET.ORTHOMOSAIC
  },
  DRONE_IMAGE: {
    title: 'Drone Image',
    resourceType: 'image',
    id: UPLOAD_DATASET.DRONE_IMAGE
  },
  VIDEO: {
    title: 'Video',
    resourceType: 'videoRGB',
    id: UPLOAD_DATASET.VIDEO
  },
  PANORAMA_360: {
    title: 'Panorama 360',
    resourceType: 'image',
    id: UPLOAD_DATASET.PANORAMA_360
  },
  ADDITIONAL_IMAGES: {
    title: 'Additional images',
    resourceType: 'image',
    id: UPLOAD_DATASET.ADDITIONAL_IMAGES
  }
}

export const UPLOAD_EXTENSIONS = [
  '.obj',
  '.mtl',
  '.jpg',
  '.xml',
  '.jpeg',
  '.png',
  'heic',
  '.zip',
  '.mp4',
  '.mov',
  '.m4v',
  '.mkv',
  '.tiff',
  '.tif',
  '.html'

];

export const VALID_UPLOAD_EXTENSIONS = {
  ['visual']: {
    exclusiveSet: [],
    inclusiveSet: ['.jpg', 'heic', 'jpeg'],
    mode: 'strict',
  },
  [CONSTANTS_MISSION.datasetCP]: {
    exclusiveSet: ['.xml'],
    inclusiveSet: [],
    mode: 'strict',
  },
  [CONSTANTS_MISSION.datasetDT]: {
    exclusiveSet: [],
    inclusiveSet: ['.jpg', '.obj', '.mtl', '.xml'],
    mode: 'strict',
  },
  [CONSTANTS_MISSION.datasetDI]: {
    exclusiveSet: [],
    inclusiveSet: ['.jpg', '.jpeg', '.png'],
    mode: 'strict',
  },
  [CONSTANTS_MISSION.datasetF01]: {
    exclusiveSet: ['.zip'],
    inclusiveSet: [],
    mode: 'strict',
  },
  [CONSTANTS_MISSION.datasetMisc]: {
    exclusiveSet: [],
    inclusiveSet: ['.jpeg', '.jpg', '.png'],
    mode: 'strict',
  },

  [CONSTANTS_MISSION.ORTHOMOSAIC]: {
    exclusiveSet: [],
    inclusiveSet: ['.tiff', '.tif'],
    mode: 'strict',
  },
  [CONSTANTS_MISSION.ORTHO_CP]: {
    exclusiveSet: ['.xml'],
    inclusiveSet: [],
    mode: 'strict',
  },
  [CONSTANTS_MISSION.DRONE_IMAGE]: {
    exclusiveSet: [],
    inclusiveSet: ['.jpeg', '.jpg', '.png'],
    mode: 'strict',
  },
  [CONSTANTS_MISSION.VIDEO]: {
    exclusiveSet: [],
    inclusiveSet: ['.mp4', '.mkv', '.mov', '.m4v'],
    mode: 'strict',
  },
  [CONSTANTS_MISSION.PANORAMA]: {
    exclusiveSet: [],
    inclusiveSet: ['.jpeg', '.jpg', '.png', '.JPG'],
    mode: 'strict',
  },
  [CONSTANTS_MISSION.VIRTUAL_TOUR]: {
    exclusiveSet: [],
    inclusiveSet: ['.html'],
    mode: 'strict',
  },
  [CONSTANTS_MISSION.ADDITIONAL_IMAGES]: {
    exclusiveSet: [],
    inclusiveSet: ['.jpeg', '.jpg', '.png'],
    mode: 'strict',
  },
};
export const DUPLICATE_TXT = 'duplicate'
