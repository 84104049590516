<div class="progress-modal d-flex flex-column gap-2">
  <div class="d-flex justify-content-between align-items-center download-info">
    <span>{{ generatingZip ? 'Generating zip...' : 'Downloading Support Files' }}</span>
    <span>{{ totalProgress }}%</span>
  </div>
  <div class="progress-bar d-flex align-items-center container-fluid position-relative">
    <div class="progress-completed" [style.width.%]="totalProgress"></div>
    <img
      class="progress-bar-marker"
      [style.left.%] = "totalProgress"
      [src]="'/images/progress-line.svg' | assetUrl"
      alt="progress-bar-marker"
      height="17px"
    />
  </div>
</div>
