import { Injectable } from '@angular/core';
import { BehaviorSubject, from, lastValueFrom, map, mergeMap, Subject, takeUntil, toArray } from 'rxjs';
import { state } from "@app/utility";
import { CONSTANTS_MISSION, FILES_VALIDITY } from '../constants/create-mission.const';
import {  PutObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { MyHttpHandler } from './custom-http-handler';
import { UploadDatasetService } from './upload-dataset.service';
import { ICreateDataResource } from '../models/create-mission-dataset.model';
import { WorkflowSharedService } from './workflow-shared.service';
import { CreateMissionWorkflowService } from './create-mission-workflow.service';
import { IMissionWorkflowDef} from '../interfaces/create-mission-workflow.interface';
import { RESOURCE_TYPE_IMAGE } from  'projects/data-upload/src/app/constants/progress.const';
import { MissionSharedService } from 'projects/data-upload/src/app/services/mission-shared.service'

@Injectable({
  providedIn: 'root'
})

export class GridMissionSharedService {
  public _simulatedGridProgressFilesList = new BehaviorSubject<any>(null);
  requestAbortControllers = new Map();
  public unsubscribeUploadData: Subject<void> = new Subject<void>();
  public _isAllFilesUploaded: Subject<void> = new Subject<void>();
  private selectedWorkflowDef :any;
  workspaceID!: string;
  workflowRunResponse!: { _id: string };
  public isProgressBarVisible = new BehaviorSubject<boolean>(false);
  public uploadFolderVisual: any =
    {
      parentFolderName: '',
      progress: 0,
      resourceType: 'visual',
      subFolder: new Set(),
      id: 'visual',
      files:[],
      filesProgressList:[]
    }
  createMissionParams = this.missionSharedService.createMissionParams_signal
  constructor(
    private uploadDatasetService: UploadDatasetService,
    private missionWorkflowService: CreateMissionWorkflowService,
    private workflowSharedService: WorkflowSharedService,
    public missionSharedService: MissionSharedService,
    ) {
    this._simulatedGridProgressFilesList.next(this.uploadFolderVisual)
  }

  ngOnInit(): void {
    let getLoggedInUserInfo = this.getUserInfo();
    this.workspaceID = getLoggedInUserInfo.activeWorkspaceId;
  }

  getFileName(filePath: string) {
    const path = filePath.split('?')[0].split('/').slice(-1)[0]
    return decodeURIComponent(path)
  }

  updateControllerMap(signalId: string, requestAbortController: AbortController) {
    this.requestAbortControllers.set(signalId, requestAbortController);
  }

  deleteUploadedFile(fileName: string) {}
  cancelFileUpload(fileName: string) {}

  getUserInfo() {
    return state.getUserInfo();
  }

  async uploadGRIDAssetsToS3(
    filesArray: File[],
    missionId: string,
  ) {
    this.isProgressBarVisible.next(true)
    const s3CredentialsInfo = await lastValueFrom(this.uploadDatasetService.getCredentials(missionId));
    const myHttpHandler = new MyHttpHandler();
    const gridPiperesourceType = RESOURCE_TYPE_IMAGE;
    myHttpHandler.onProgress$.subscribe((progress) => {
      const uploadedFilePercentage = (progress.progressEvent.loaded / progress.progressEvent.total) * 100
      this.updateGridFilesProgressList(this.getFileName(progress.path),uploadedFilePercentage)
      if(this.isAllFilesUploaded()) {
        this.runWorkFlow()
      }
    });
    myHttpHandler.onComplete$.subscribe((progress) => {
      const etag =  progress.etag.split(':')[1]?.replaceAll('"', ' ').trim()
      this.trackEtags(progress.path, etag);
    });
    myHttpHandler.onError$.subscribe((progress) => {
    });
    const client = new S3Client({
      region: s3CredentialsInfo.credentials.region,
      credentials: {
        accessKeyId: s3CredentialsInfo.credentials.accessKeyId,
        secretAccessKey: s3CredentialsInfo.credentials.secretAccessKey,
        sessionToken: s3CredentialsInfo.credentials.sessionToken,
      },
      requestHandler: myHttpHandler,
    })
    const url$ = from([
      ...this.getAssetObservableStream(filesArray, s3CredentialsInfo, gridPiperesourceType),
    ]);
    const pipFunc = async (urls: any) => {
      await urls
        .pipe(
          mergeMap(async (param: any) => {
            const requestAbortController = new AbortController();
            this.updateControllerMap(param.signalId, requestAbortController);
            await from(
              client
                .send(new PutObjectCommand(param), { abortSignal: requestAbortController.signal })
                .then((resp: any) => {
                  const input = {
                    Body: param.Body,
                    Bucket: s3CredentialsInfo.credentials.s3Bucket,
                    ETag: this.getFileEtag(param.Body.name),
                    Key: param.Key,
                    tags : param.tags,
                  };
                    return this.createDataResource(
                      s3CredentialsInfo,
                      input,
                      missionId
                    ).then((resp) => {

                    });
                  return resp;
                })
                .catch((error) => {
                }),
            ).toPromise()
          }, CONSTANTS_MISSION.PIPELINE_SIZE),
          takeUntil(this.unsubscribeUploadData),
          map((response) => { }),
          toArray(),
        )
        .toPromise();
    };
    await pipFunc(url$)

  }

  async createDataResource(
    credInfo: any,
    file: any,
    missionId: string,
  ) {
    let respFiles: any = [];
    let counter = 0;

      respFiles.push({
        s3Key: file.Key,
        s3Bucket: file.Bucket,
        sizeBytes: file.Body.size,
        extension: file.Body.name.substr(file.Body.name.indexOf('.') + 1),
        s3Etag: file.ETag,
        origUploadRelativePath: file.Body.webkitRelativePath,
        origFileName: file.Body.name
      });
    const payload: ICreateDataResource = {
      workspaceId: this.getUserInfo().activeWorkspaceId,
      type: 'data_resource',
      missionId: missionId,
      resourceType: RESOURCE_TYPE_IMAGE,
      metadata: {},
      storage: {
        storageType: 's3',
        files: respFiles,
      },
      tags: file.tags
    };
    try {
      let response: any = await lastValueFrom(
        this.uploadDatasetService.createDataResources(payload)
      );
      counter++;
      return response;
    } catch (error) {
      throw new Error('oops an error occurred');
    }
  }

  getAssetObservableStream(allFiles: any, data: any, resourceType: string) {
    const relativepath = data.credentials.s3Prefix;
      return Array.from(allFiles).map((item: any) => {
        let fileRelativePath = item.webkitRelativePath.split('/');
        const s3KeyValue =  fileRelativePath.length > 2 ? relativepath + '/' + resourceType+ '/'  + item.name : relativepath + '/'+ resourceType + '/'  + item.name;
        const tagsList = [];
        if(fileRelativePath.length == 2) {
          tagsList.push(
            'image_group:' + fileRelativePath[0],
            'orig_full_res',
          );
        }
        else if(fileRelativePath.length > 2) {
          tagsList.push(
            'image_group:' + fileRelativePath[0],
            'image_group_hierarchy:' + fileRelativePath.slice(1,fileRelativePath.length-1).join('/'),
            'orig_full_res',
          );
        }
        const uploadFileparams = {
          signalId: item.webkitRelativePath.length ? item.webkitRelativePath : item.name,
          Body: item,
          Bucket: data.credentials.s3Bucket,
          Key: s3KeyValue,
          tags: tagsList
        };
        return uploadFileparams;
      });
  }

  trackEtags(filePath: string, etag: string) {
    const selectedItemIndex = this.uploadFolderVisual.filesProgressList.findIndex((item:any)=>item.name == this.getFileName(filePath))
    if(selectedItemIndex !== -1) {
      this.uploadFolderVisual.filesProgressList[selectedItemIndex] = {
        ...this.uploadFolderVisual.filesProgressList[selectedItemIndex],
        etag
      }
    }
    this._simulatedGridProgressFilesList.next(this.uploadFolderVisual)
  }

  getFileEtag(fileName: string){
    const selectedItemIndex = this.uploadFolderVisual.filesProgressList.findIndex((item:any)=>item.name == fileName)
    if(selectedItemIndex !== -1) {
      return this.uploadFolderVisual.filesProgressList[selectedItemIndex].etag;
    }
  }

  populateGridFilesProgressList(allFiles:File[]) {
    for(let file of Array.from(allFiles)) {
      this.uploadFolderVisual.filesProgressList.push({
        name:file.name,
        progress:3,
        signalId: file.webkitRelativePath.length ? file.webkitRelativePath : file.name,
        file: file
      })
    }
    this._simulatedGridProgressFilesList.next(this.uploadFolderVisual)
  }

  updateGridFilesProgressList(fileName:string,progress:number) {
    const selectedItemIndex = this.uploadFolderVisual.filesProgressList.findIndex((item:any)=>item.name == fileName)
    if(selectedItemIndex !== -1) {
      this.uploadFolderVisual.filesProgressList[selectedItemIndex] = {
        ...this.uploadFolderVisual.filesProgressList[selectedItemIndex],
        progress,
        startTime: Date.now()
      }
    }
    this._simulatedGridProgressFilesList.next(this.uploadFolderVisual)
  }

  isValidFileTypes(fileList: File[] | FileList, categoryName: string): boolean {
    let filesExtensions = new Set<string>([])
    const validExtensions = FILES_VALIDITY.VALID_EXTENSIONS;
    const requiredFileExtensions : any = FILES_VALIDITY.REQUIRED_FILE_EXTENSIONS;
    for (let i = 0; i < fileList.length; i++) {
      const fileExtension = fileList[i].name.substr(fileList[i].name.lastIndexOf('.'));
      filesExtensions.add(fileExtension.toLowerCase())
    }
    const uniqueFileExtensions = Array.from(filesExtensions);
    const exclusiveFileExtensions = requiredFileExtensions[categoryName].exclusiveSet.sort();
    const inclusiveFileExtensions = requiredFileExtensions[categoryName].inclusiveSet.sort();
    const isValidExtensions = Array.from(filesExtensions).some(extension => validExtensions.includes(extension.toLowerCase()));
    if (isValidExtensions) {
      let isRequiredExtensionsAvailable: boolean = true
      if (requiredFileExtensions[categoryName].mode == 'nonstrict') {
        exclusiveFileExtensions.forEach((ext: string) => {
          if (!uniqueFileExtensions.includes(ext)) isRequiredExtensionsAvailable = false;
        })
        if (isRequiredExtensionsAvailable) {
          isRequiredExtensionsAvailable = inclusiveFileExtensions.some((ext: string) => uniqueFileExtensions.includes(ext));
        }
      } else {
        isRequiredExtensionsAvailable = inclusiveFileExtensions.length === 0 ? true : inclusiveFileExtensions.some((ext: string) => uniqueFileExtensions.includes(ext));
        if (isRequiredExtensionsAvailable) {
          const filteredFileExtensions = uniqueFileExtensions.filter((ext: string) => {
            if (inclusiveFileExtensions.includes(ext)) return false;
            return true;
          })
          const sortedUserFileExtensionsSet = filteredFileExtensions.sort();
          isRequiredExtensionsAvailable = JSON.stringify(exclusiveFileExtensions) == JSON.stringify(sortedUserFileExtensionsSet);
        }
      }
      return isRequiredExtensionsAvailable
    }
    return false
  }

  isAllFilesUploaded(){
    let allFilesUploaded = true;
    for (let item of this.uploadFolderVisual.filesProgressList){
      if(item.progress != 100){
        allFilesUploaded = false;
      }
    }
    return allFilesUploaded;
  }

  setWorkFlowData(data: IMissionWorkflowDef) {
    this.selectedWorkflowDef=data;
  }

  async runWorkFlow() {
    const { missionId } = this.createMissionParams()
    let workflowDefList;
        let workflowStepList: IMissionWorkflowDef[] = [];
        let payload!: any;
        this.selectedWorkflowDef.workflowSteps.forEach((step:any) => {
          step.displayName = step.stepName;
          workflowStepList.push(step);
        });
        payload = {
          workspaceId:  this.getUserInfo().activeWorkspaceId,
          name: this.selectedWorkflowDef.name,
          workflowDefId: this.selectedWorkflowDef._id,
          airflowDagId: this.selectedWorkflowDef.airflowDagId,
          missionId: missionId,
          status: this.selectedWorkflowDef.status,
          workflowSteps: workflowStepList,
        };
        this.isProgressBarVisible.next(false);
        let workflowResponse = await this.missionWorkflowService
          .runWorkflowForMission(payload)
          .toPromise();
        let workflowRunResponse: any = await this.missionWorkflowService
          .startWorkflowForMission(workflowResponse)
          .toPromise();
        this.workflowRunResponse = workflowRunResponse;
        this.workflowSharedService.setWorkflowById({
          id: workflowRunResponse?._id
        });
        this.workflowSharedService.getWorkflowProgressById(
          workflowRunResponse?._id
        );
  }

  abortS3Upload(requestIdToAbort: string) {
    if (this.requestAbortControllers.has(requestIdToAbort)) {
      this.requestAbortControllers.get(requestIdToAbort).abort();
      this.requestAbortControllers.delete(requestIdToAbort);
    }
  }
}
