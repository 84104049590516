import { Injectable, inject } from "@angular/core";
import { MissionSharedService } from "./mission-shared.service";
import { FilesProgressList } from "./../interfaces/file-upload-dataset.interfaces";

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  private eventHandlers: Record<string, (event: Event) => void> = {};
  missionSharedService = inject(MissionSharedService)
  closeDropdown(className: string) {
    const dropdowns = document.querySelector(className) as HTMLDivElement;
    const clickHandler = (event: Event) => {
      if (!(event.target as HTMLDivElement).closest(className)) {
        dropdowns && dropdowns.classList.remove('dropdown-show');
      }
    };
    document.addEventListener('click', clickHandler);
    this.eventHandlers[className] = clickHandler;
  }

  removeEventListener(className: string) {
    const clickHandler = this.eventHandlers[className];

    if (clickHandler) {
      document.removeEventListener('click', clickHandler);
      delete this.eventHandlers[className];
    }
  }

  checkUploadFileIsPresent(parentType: string): boolean {
    const fileList = this.missionSharedService.uploadingFiles;
    const parentFileList = fileList?.[parentType]?.filesProgressList as FilesProgressList[];
    return parentFileList.length && parentFileList?.every(file => file.progress === 100) || false;
  }
}
