import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showHideUploadDataset',
})
export class ShowHideUploadDatasetPipe implements PipeTransform {
  transform(workflowResourceType: string[], resourceType: string[]): boolean {
    // return workflowResourceType.some((resource) => resourceType.includes(resource))
    return true
  }
}
