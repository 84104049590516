import { Pipe, PipeTransform } from '@angular/core';
import { AssetHierarchy } from '../../interfaces/asset.interface';

@Pipe({
  name: 'assetHierarchyArray',
})
export class AssetHierarchyArrayPipe implements PipeTransform {

  transform(value: AssetHierarchy): { key: string, value: string }[] {
    return Object.entries(value).map(([key, val]) => ({ key, value: val as string }));
  }
}
