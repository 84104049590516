import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ElementRef,
  effect,
} from '@angular/core';
import { Subscription, lastValueFrom, of } from 'rxjs';
import { UploadDatasetService } from 'projects/data-upload/src/app/services/upload-dataset.service';
import {
  CONSTANTS_MISSION,
  MISSION_STATUS,
  NOTIFY_MSG,
  ASSETS_NAMES,
  DELETE_MODAL,
  DUPLICATE_UPLOAD_MODAL,
} from 'projects/data-upload/src/app/constants/create-mission.const';
import { CreateMissionWorkflowService } from 'projects/data-upload/src/app/services/create-mission-workflow.service';
import {
  IMissionWorkflowDef,
  IMissionWorkflowRun,
} from 'projects/data-upload/src/app/interfaces/create-mission-workflow.interface';
import { Modalpopup } from 'projects/data-upload/src/app/shared/constants';
import { MissionSharedService } from 'projects/data-upload/src/app/services/mission-shared.service';
import { CreateMissionService } from 'projects/data-upload/src/app/services/create-mission.service';
import { WorkflowSharedService } from 'projects/data-upload/src/app/services/workflow-shared.service';
import { USER_PERMISSIONS } from 'projects/data-upload/src/app/user-permissions/user-permission.const';
import { ExternalClientAssetService } from 'projects/data-upload/src/app/services/external-client-asset.service';

import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-upload-new-dataset',
  templateUrl: './upload-new-dataset.component.html',
  styleUrls: ['./upload-new-dataset.component.scss'],
})
export class UploadNewDatasetComponent {
  searchControl: string = 'hello';
  searchResults: any[] = [];
  errorFetchingResults: string | null = null;
  @Input() backToCreateMission?: boolean;
  @Input() isDetailUpdated?: boolean;
  @Input() missionSiteName!: string;
  @Output() initBackToParentComponent: EventEmitter<boolean> =
    new EventEmitter();
  @Output() hideMission: EventEmitter<boolean> = new EventEmitter();
  @Output() resetCreateMission: EventEmitter<boolean> = new EventEmitter();
  @ViewChildren('fileDropRef') fileDropRefs!: QueryList<
    ElementRef<HTMLInputElement>
  >;
  isUploadStart: boolean = false;
  progress: number = 0;
  showDeleteModal: boolean = false;
  deleteFolder: Boolean = false;
  progressPercentage: Number = CONSTANTS_MISSION.PROGRESS_PERCENTAGE;
  createDataResSingle: any;
  localMissionID: any;
  localProjectID: any;
  localSiteID: any;
  localAssetID: any;
  isFolderClicked: boolean = false;
  MISSION_CONSTANTS = CONSTANTS_MISSION;
  isSubmitted: boolean = false;
  workflowData: any;
  toasterClass: string = '';
  toasterMsg: string = '';
  isShowToaster: boolean = false;
  isHideMissionSection: boolean = false;
  timeInterval?: Subscription;
  stopWorkflow: boolean = false;
  workflowSteps!: string;
  isShowProgressModal: boolean = false;
  workspaceID!: string;
  workflowNotStarted: boolean = true;
  USER_PERMISSIONS = USER_PERMISSIONS;
  currentFileType!: string;
  currentParentFileType!: string;
  currentFileRef!: string;
  currentFiles: any;
  Modalpopup = Modalpopup;
  defaultFolder: string = ASSETS_NAMES.DEFAULT_FOLDER;
  downloadIcon: string = ASSETS_NAMES.DOWNLOAD_ICON;
  uploadedFolder: string = ASSETS_NAMES.UPLOADED_FOLDER;
  crossIcon: string = ASSETS_NAMES.CROSS_ICON;
  tickIcon: string = ASSETS_NAMES.TICK_ICON;
  folder: string = ASSETS_NAMES.FOLDER;
  deleteModal = DELETE_MODAL;
  duplicateFileUploadModal = DUPLICATE_UPLOAD_MODAL;

  workflowRunResponse!: { _id: string };
  duplicateFileExist: boolean = false;
  @Output() updateActiveIndex: EventEmitter<number> = new EventEmitter();
  showError = this.missionSharedService.showError
  showErrorEff = effect(() => {
    this.showToast(this.showError().show, NOTIFY_MSG.ERROR_CLASS, this.showError().message)
  })
  createMissionParams = this.missionSharedService.createMissionParams_signal

  constructor(
    private uploadDatasetService: UploadDatasetService,
    private missionWorkflowService: CreateMissionWorkflowService,
    public missionSharedService: MissionSharedService,
    private createMissionService: CreateMissionService,
    private workflowSharedService: WorkflowSharedService,
    private externalClientAssetService: ExternalClientAssetService
  ) {}

  ngOnInit(): void {
    let getLoggedInUserInfo = this.missionSharedService.getUserInfo();
    this.workspaceID = getLoggedInUserInfo.activeWorkspaceId;
    // this.setupSearch();
  }

  search(event: Event) {
    this.searchResults = [];
    const searchValue = (event.target as HTMLInputElement).value;
    searchValue &&
      this.externalClientAssetService
        .searchExternalClientAsset((event.target as HTMLInputElement).value)
        .subscribe({
          next: (data) => {
            this.searchResults = data.records;
          },
        });
  }

  getUploadingFiles() {
    return this.missionSharedService.uploadingFiles;
  }

  ngOnDestroy() {
    this.missionSharedService.unsubscribeUploadData.next();
    this.missionSharedService.unsubscribeUploadData.complete();
  }

  backToCreateMissionComp(backToCreateMission: boolean) {
    if (!this.isUploadStart && !this.workflowSharedService.isWorkFlowStarted) {
      this.initBackToParentComponent.emit(backToCreateMission);
      this.updateActiveIndex.emit(2);
    } else {
      this.isShowProgressModal = true;
    }
  }
  stayOnUploadComponent() {
    this.isShowProgressModal = false;
  }

  initBackTofolderView(value: boolean) {
    this.isFolderClicked = value;
  }
  async DeleteFoldersDataSet(
    fileType: string,
    parentFileType: string,
    fileRef?: any,
    files?: any
  ) {
    if (
      // this.missionSharedService.uploadingFiles[fileType]?.filesProgressList?.length ||
      // this.missionSharedService.uploadingFiles[parentFileType][fileType]?.filesProgressList.length
      this.missionSharedService.uploadingFiles[fileType]?.filesProgressList
        ?.length ||
      this.missionSharedService.uploadingFiles[parentFileType][fileType]
        ?.filesProgressList.length
    ) {
      this.currentFileType = fileType;
      this.currentParentFileType = parentFileType;
      this.currentFileRef = fileRef;
      this.showDeleteModal = true;
      this.currentFiles = files;
    }
  }
  async DeleteDataSetsFolders({
    fileType,
    parentFileType,
    fileRef,
    files,
  }: {
    fileType: string;
    parentFileType: string;
    fileRef: any;
    files: any;
  }) {
    this.resetFileInput(fileRef);
    let dataResourceIdDeleteList: String[] = [];
    const dataResourceIdDeleteProgress: File[] = [];
    if (fileType == CONSTANTS_MISSION.datasetDT) {
      this.digitalTwin(
        fileType,
        parentFileType,
        dataResourceIdDeleteList,
        dataResourceIdDeleteProgress
      );
    } else {
      if (fileType == parentFileType) {
        this.cameraParameter(
          fileType,
          parentFileType,
          dataResourceIdDeleteList,
          dataResourceIdDeleteProgress
        );
      } else {
        this.droneImage(
          fileType,
          parentFileType,
          dataResourceIdDeleteList,
          dataResourceIdDeleteProgress
        );
      }
    }
    if (dataResourceIdDeleteList.length) {
      try {
        {
          await lastValueFrom(
            this.uploadDatasetService.deleteDataResourcesBulk(
              dataResourceIdDeleteList
            )
          );
          this.missionSharedService.resetFilesEmpty(fileType, parentFileType);
        }
      } catch (error) {}
    }
  }
  droneImage(
    fileType: any,
    parentFileType: any,
    dataResourceIdDeleteList: any,
    dataResourceIdDeleteProgress: any
  ) {
    const { missionId, missionProjectId } = this.createMissionParams()
    for (let item of this.missionSharedService.uploadingFiles[parentFileType][
      fileType
    ].filesProgressList) {
      if (item.dataResourceId) {
        dataResourceIdDeleteList.push(item.dataResourceId);
      } else {
        this.missionSharedService.abortS3Upload(item.signalId);
        if (item.progress == 100) {
          dataResourceIdDeleteProgress.push(item.file);
        }
      }
    }
    this.missionSharedService.deleteFilesFromS3(
      dataResourceIdDeleteProgress,
      fileType,
      parentFileType,
      missionId,
      missionProjectId
    );
    if (
      this.missionSharedService.uploadingFiles[parentFileType][fileType]
        .filesProgressList.length
    ) {
      this.missionSharedService.uploadingFiles[parentFileType][
        fileType
      ].filesProgressList = [];
      this.missionSharedService.uploadingFiles[parentFileType][fileType].files =
        [];
    }
  }
  cameraParameter(
    fileType: any,
    parentFileType: any,
    dataResourceIdDeleteList: any,
    dataResourceIdDeleteProgress: any
  ) {
    const { missionId, missionProjectId } = this.createMissionParams()
    if (
      this.missionSharedService.uploadingFiles[fileType].filesProgressList
        .length
    ) {
      for (let item of this.missionSharedService.uploadingFiles[fileType]
        .filesProgressList) {
        if (item.dataResourceId) {
          dataResourceIdDeleteList.push(item.dataResourceId);
        } else {
          this.missionSharedService.abortS3Upload(item.signalId);
          if (item.progress == 100) {
            dataResourceIdDeleteProgress.push(item.file);
          }
          this.missionSharedService.deleteFilesFromS3(
            dataResourceIdDeleteProgress,
            fileType,
            parentFileType,
            missionId,
            missionProjectId
          );
        }
      }
      this.missionSharedService.uploadingFiles[fileType].filesProgressList = [];
      this.missionSharedService.uploadingFiles[fileType].files = [];
    }
  }
  digitalTwin(
    fileType: any,
    parentFileType: any,
    dataResourceIdDeleteList: any,
    dataResourceIdDeleteProgress: any
  ) {
    const { missionId, missionProjectId } = this.createMissionParams()
    if (this.missionSharedService.uploadingFiles[fileType].dataResourceId) {
      dataResourceIdDeleteList.push(
        this.missionSharedService.uploadingFiles[fileType].dataResourceId
      );
    } else {
      for (const files of this.missionSharedService.uploadingFiles[fileType]
        .filesProgressList) {
        this.missionSharedService.abortS3Upload(files.signalId);
        if (files.progress == 100) {
          dataResourceIdDeleteProgress.push(files.file);
        }
      }
      this.missionSharedService.deleteFilesFromS3(
        dataResourceIdDeleteProgress,
        fileType,
        parentFileType,
        missionId,
        missionProjectId
      );
    }

    if (
      this.missionSharedService.uploadingFiles[fileType].filesProgressList
        .length
    ) {
      this.missionSharedService.uploadingFiles[fileType].filesProgressList = [];
      this.missionSharedService.uploadingFiles[fileType].files = [];
    }
  }
  async uploadFiles(files: any, fileType: any, parentFileType: any) {
    let isFileExisted: boolean = this.missionSharedService.isFileExisted(
      files,
      fileType,
      parentFileType
    );

    if (isFileExisted) {
      this.isShowToaster = true;
      this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
      this.toasterMsg = NOTIFY_MSG.UPLOAD_DUPLICATE;
      setTimeout(() => {
        this.isShowToaster = false;
      }, 5000);
    } else {
      let isValidFiles = this.missionSharedService.isValidFileTypes(
        files,
        parentFileType
      );
      if (isValidFiles) {
        const { missionId, missionProjectId, siteId } = this.createMissionParams()
        this.missionSharedService.updateUploadingFilesDataset(
          files,
          fileType,
          parentFileType
        );
        this.missionSharedService.populateFilesProgressList(
          Array.from(files),
          fileType,
          parentFileType
        );
        this.missionSharedService.uploadFilesToS3(
          Array.from(files),
          fileType,
          parentFileType,
          missionId,
          missionProjectId,
          siteId
        );
      } else {
        this.isShowToaster = true;
        this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
        this.toasterMsg = NOTIFY_MSG.INVALID_FILE_FORMAT;
        setTimeout(() => {
          this.isShowToaster = false;
        }, 5000);
      }
    }
  }
  async onUploadDataset(retryWorkflowCounter: number = 0) {
    if (!this.missionSharedService.isAllSiteFilesUploaded()) {
      this.isSubmitted = false;
    } else {
      const missionDataParams = this.createMissionParams()
      if (missionDataParams) {
        this.localMissionID = missionDataParams.missionId;
        this.localProjectID = missionDataParams?.missionProjectId;
        this.localSiteID = missionDataParams?.siteId;
        this.localAssetID = missionDataParams?.missionAssetId;
      }
      this.missionSharedService.hideProgressBar();
      this.isSubmitted = true;
      try {
        const updateSiteInfo = {
          siteImageDataResourceId:
            this.missionSharedService.sitePlaceHolderResTag,
        };
        this.uploadDatasetService
          .updateSitePlaceholder(this.localSiteID, updateSiteInfo)
          .toPromise();
        const missionStatus = {
          status: MISSION_STATUS.ACTIVE,
        };
        this.createMissionService
          .updateMissionStatus(this.localMissionID, missionStatus)
          .toPromise();
        let data = await this.missionWorkflowService
          .getWorkflowDef()
          .toPromise();
        let workflowDefList;
        let workflowStepList: IMissionWorkflowDef[] = [];
        this.workflowData = data;
        let payload!: IMissionWorkflowRun;
        workflowDefList = this.workflowData.records;
        for (let i = 0; i < workflowDefList[0].workflowSteps.length; i++) {
          workflowDefList[0].workflowSteps[i].displayName =
            workflowDefList[0].workflowSteps[i].stepName;
          workflowStepList.push(workflowDefList[0].workflowSteps[i]);
        }
        payload = {
          workspaceId: this.workspaceID,
          name: workflowDefList[0].name,
          workflowDefId: workflowDefList[0]._id,
          airflowDagId: workflowDefList[0].airflowDagId,
          projectId: this.localProjectID,
          missionId: this.localMissionID,
          siteId: this.localSiteID,
          status: workflowDefList[0].status,
          createdBy: workflowDefList[0].createdBy,
          updatedBy: workflowDefList[0].updatedBy,
          workflowSteps: workflowStepList,
          createdAt: workflowDefList[0].createdAt,
          updatedAt: workflowDefList[0].updatedAt,
        };
        let workflowResponse = await this.missionWorkflowService
          .runWorkflowForMission(payload)
          .toPromise();
        let workflowRunResponse: any = await this.missionWorkflowService
          .startWorkflowForMission(workflowResponse)
          .toPromise();
        this.workflowRunResponse = workflowRunResponse;
        this.workflowSharedService.setWorkflowById({
          id: workflowRunResponse?._id,
          siteName: this.missionSiteName,
        });
        this.workflowSharedService.getWorkflowProgressById(
          workflowRunResponse?._id
        );
        this.hideMission.emit(true);
        this.resetCreateMission.emit(true);
        this.clearLocalStorageKeys();
        this.missionSharedService.resetSimulate()
      } catch (error) {
        this.isShowToaster = true;
        this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
        this.toasterMsg = NOTIFY_MSG.WORKFLOW_FAILED;
        setTimeout(() => {
          this.isShowToaster = false;
        }, 5000);
        setTimeout(() => {
          this.workflowSharedService.updateWorkFlowStatusById({
            workflowId: this.workflowRunResponse._id,
            status: false,
          });
          this.isUploadStart = false;
          this.workflowSharedService.terminateWorkFlowById(
            this.workflowRunResponse._id
          );
          this.progress = 0;
        }, 1000);
        this.stopWorkflow = false;
        this.hideMission.emit(true);
        this.clearLocalStorageKeys();
      }
    }
  }

  callApiMultiple(milliseconds: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }

  clearLocalStorageKeys() {
    localStorage.removeItem('camera_param');
    localStorage.removeItem('drone_img');
    localStorage.removeItem('digital_twin_tile');
    localStorage.removeItem('digital_twin');
  }

  isProcessBtnDiabled() {
    return !this.missionSharedService.isAllSiteFilesUploaded();
  }

  isInputDisabled(fileType: string, parentFileType: string) {
    return (
      this.missionSharedService.checkFilesProgressList(
        fileType,
        parentFileType
      ) > 0 &&
      this.missionSharedService.isAllFilesUploaded(fileType, parentFileType)
    );
  }
  resetFileInput(fileDropRef: any) {
    const tempElementRef = new ElementRef(fileDropRef);
    tempElementRef.nativeElement.value = null;
  }
  deleteFolders(deleteData: any) {
    if (deleteData) {
      this.DeleteDataSetsFolders({
        fileRef: this.currentFileRef,
        fileType: this.currentFileType,
        parentFileType: this.currentParentFileType,
        files: this.currentFiles,
      });
    }
    this.showDeleteModal = false;
  }
  showToast(show: boolean, className: string, message: string) {
    this.isShowToaster = show
    this.toasterClass = className
    this.toasterMsg = message
    setTimeout(() => {
      this.isShowToaster = false
    }, 5000)
  }
}
