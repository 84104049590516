import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'app-utm-mission-confirmation-popup',
  templateUrl: './mission-confirmation-popup.component.html',
  styleUrls: ['./mission-confirmation-popup.component.scss']
})
export class MissionConfirmationPopupComponent {
  @Output() userConsent: EventEmitter<boolean> = new EventEmitter();
  @Input() popupHeading: string = "Cancel the Data Processing?";
  @Input() popupDescription: string = "Data Processing for a mission is in progress, would you like to cancel it and create a new mission?";

  onOptionSelect(value: boolean) {
    this.userConsent.emit(value);
  }
}
