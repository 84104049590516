import { Component } from '@angular/core';
import { CONSTANTS_MISSION } from 'projects/data-upload/src/app/constants/create-mission.const';
import { MissionSharedService } from 'projects/data-upload/src/app/services/mission-shared.service';

@Component({
  selector: 'app-files-downloading-progress',
  templateUrl: './files-downloading-progress.component.html',
  styleUrls: ['./files-downloading-progress.component.scss']
})
export class FilesDownloadingProgressComponent {
  totalProgress: number = 0;
  generatingZip: boolean = false;
  private currentProgressArray: number[] = []

  constructor(private missionSharedService: MissionSharedService) {}

  ngOnInit() {
    this.currentProgressArray = this.missionSharedService.downloadList.map(() => 0);
    this.missionSharedService.downloadList.forEach((progressSubject, idx) => {
      progressSubject.subscribe((currentProgress) => {
        this.currentProgressArray[idx] = currentProgress;
        let tempProgress = 0;
        this.currentProgressArray.forEach((val) => {
          tempProgress += val/this.currentProgressArray.length;
        })
        this.totalProgress = Math.floor(tempProgress) - 1 < 0 ? 0 : Math.floor(tempProgress) - 1;
        if(this.totalProgress === CONSTANTS_MISSION.ZIP_GENERERATION_PERCENTAGE) {
          this.generatingZip = true;
        }
      })
    })
  }
}
