import {
  Component,
  EventEmitter,
  Output,
  inject,
} from '@angular/core';
import {
  CONSTANTS_MISSION,
  ORTHOMOSAIC_SUB_HEADER,
  UPLOAD_DATASET_HEADER,
} from '../../../constants/create-mission.const';
import { UploadDatasetService } from '../../../services/upload-dataset.service';
import { FileUploadData } from '../upload-dataset.component';
import { UtilityService } from '../../../services/utility.service';

@Component({
  selector: 'app-orthomosaic-data',
  templateUrl: './orthomosaic-data.component.html',
  styleUrl: './orthomosaic-data.component.scss',
})
export class OrthomosaicDataComponent {
  @Output() getUploadedFile = new EventEmitter<FileUploadData>();

  utilityService = inject(UtilityService);
  uploadDataServices = inject(UploadDatasetService);

  // const
  MISSION_CONSTANTS = CONSTANTS_MISSION;
  ORTHOMOSAIC_SUB_HEADER = ORTHOMOSAIC_SUB_HEADER
  UPLOAD_DATASET_HEADER = UPLOAD_DATASET_HEADER

  
  headExpand = '';
  subHeadExpand = ''
  isValid = true;
  cameraParamUploadedFiles: boolean;
  orthomosaicUploadedFiles: boolean;

  ngOnInit() {
    this.uploadDataServices.datasetHeadExpand.subscribe((data) => {
      this.headExpand = data;
    });

    this.uploadDataServices.checkFileIsAvailable.subscribe(() => {
      this.cameraParamUploadedFiles = this.utilityService.checkUploadFileIsPresent(this.MISSION_CONSTANTS.ORTHO_CP)
      this.orthomosaicUploadedFiles = this.utilityService.checkUploadFileIsPresent(this.MISSION_CONSTANTS.ORTHOMOSAIC)
    })
  }

  onHeadExpand(data: string) {
    this.uploadDataServices.datasetHeadExpand.next(data)
    this.subHeadExpand = ''
  }

  filesDropped($event: FileUploadData) {
    this.getUploadedFile.emit($event);
  }

  isFileUploadedToChild() {
    return (this.cameraParamUploadedFiles || this.orthomosaicUploadedFiles )
      && !this.headExpand
  }

  onSetSubHead(subHead: string) {
    if (this.subHeadExpand === subHead) {
      this.subHeadExpand = '';
      this.onScrollIntoView('.upload-container')

    } else {
      this.subHeadExpand = subHead;
      this.onScrollIntoView('.show-dagAndDrop')
    }
  }

  onScrollIntoView(ClassName: string) {
    requestAnimationFrame(() => {
      const element = document.querySelector(ClassName);
      element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  }
}
