<div class="form-group">
  <div class="upload-form-holder" *ngIf="showUploadSection" >
    <form>
      <div class="upload-accordion accordion" id="uploadDatasetAccordion">
        <div class="upload-accordion-section card">
          <div class="upload-accordion-section-header card-header" id="uploadOne">
            <h2 class="title mb-0">
              <button
                class="btn btn-link btn-block text-left"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#uploadCollapseOne"
                aria-expanded="true"
                aria-controls="uploadCollapseOne">
                F01 FILE
              </button>
            </h2>
          </div>
          <div
            id="uploadCollapseOne"
            class="collapse show"
            aria-labelledby="uploadOne"
            data-bs-parent="#uploadDatasetAccordion">
            <div class="upload-accordion-section-body card-body">
              <h4 class="title">All folders</h4>
              <div class="uploaded-assets-holder">
                <div 
                  *ngIf="latestF01Data && latestF01Data.length" 
                  class="uploaded-folder uploaded-folder-holder"
                  (click)="selectF01Folder(f01FileName)">
                  <div [ngClass]="selectedF01File === f01FileName ? 'uploaded-folder-img' : ''">
                    <img [src]="'/images/uploadedFolder.png' | assetUrl" alt="Uploaded Folder"/>
                    <span class="sub-title">{{ f01FileName.length > 10 ? f01FileName.slice(0, 8) + '...' : f01FileName }}</span>
                  </div>
                </div>
                <button class="btn add-new-btn" type="button">
                  <img [src]="'/images/folders.png' | assetUrl" alt="Add Folder"/>
                  <span class="sub-title">Add New</span>
                  <input
                    class="input-add-new"
                    type="file"
                    #fileDropRefF01A
                    id="fileDropRefF01A"
                    (change)="uploadFiles(fileDropRefF01A.files, MISSION_CONSTANTS.datasetF01, MISSION_CONSTANTS.datasetF01, 'file')"/>
                </button>
              </div>
              <div class="upload-file-holder dropzone" fileDragDrop >
                <input
                  class="input-upload-content"
                  type="file"
                  #fileDropRefF01B
                  id="fileDropRefF01B"
                  (change)="uploadFiles(fileDropRefF01B.files, MISSION_CONSTANTS.datasetF01, MISSION_CONSTANTS.datasetF01, 'file')"/>
                <div class="upload-content">
                  <div class="img-holder">
                    <img [src]="'/images/download.svg' | assetUrl" alt="Download File image"/>
                  </div>
                  <div class="info">
                    <h3 class="heading">
                      Drop files here or
                      <a href="javascript:void(0);">click</a> to upload.
                    </h3>
                    <label class="file-required" for="fileDropRef">Accepted format: .zip only</label>
                  </div>
                </div>
              </div>
              <div class="f01-btn-holder">
                <button type="button" (click)="onDownloadF01()" class="btn btn-download" [disabled]="!selectedF01File">
                  Download
                </button>
                <button type="submit" class="btn btn-upload">
                  <span>Upload</span>
                  <input
                    class="input-btn-upload"
                    type="file"
                    #fileDropRefF01C
                    id="fileDropRefF01C"
                    (change)="uploadFiles(fileDropRefF01C.files, MISSION_CONSTANTS.datasetF01, MISSION_CONSTANTS.datasetF01, 'file')"/>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="upload-accordion-section card">
          <div class="upload-accordion-section-header card-header" id="uploadTwo">
            <h2 class="title mb-0">
              <button
                class="btn btn-link btn-block text-left collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#uploadCollapseTwo"
                aria-expanded="false"
                aria-controls="uploadCollapseTwo">
                MISCELLANEOUS<em class="required">*</em>
              </button>
            </h2>
          </div>
          <div
            id="uploadCollapseTwo"
            class="collapse"
            aria-labelledby="uploadTwo"
            data-bs-parent="#uploadDatasetAccordion">
            <div class="upload-accordion-section-body card-body">
              <h4 class="title">All folders</h4>
              <div class="uploaded-assets-holder">
                <div class="uploaded-folder d-flex justify-content-start align-items-center gap-2 flex-wrap">
                  <div *ngFor="let miscFileName of miscFilesName">
                    <div 
                      *ngIf="latestMiscData && latestMiscData.length"
                      class="uploaded-folder-holder"
                      [ngClass]="this.selectedMiscFile === miscFileName ? 'uploaded-folder-img' : ''"
                      (click)="selectMiscFolder(miscFileName)">
                        <img [src]="'/images/uploadedFolder.png' | assetUrl" alt="Uploaded Folder"/>
                        <span class="sub-title">{{ miscFileName.length > 8 ? miscFileName.slice(0, 8) + '...' : miscFileName }}</span>
                    </div>
                  </div>
                  <button class="btn add-new-btn" type="button">
                    <img [src]="'/images/folders.png' | assetUrl" alt="Add Folder"/>
                    <span class="sub-title">Add New</span>
                    <input 
                      class="input-add-new"
                      type="file" 
                      #fileDropRefMiscA 
                      id="fileDropRefMiscA" 
                      webkitdirectory 
                      mozdirectory 
                      multiple 
                      (change)="uploadFiles(fileDropRefMiscA.files, MISSION_CONSTANTS.datasetMisc, MISSION_CONSTANTS.datasetMisc, 'folder')"/>
                  </button>
                </div>
              </div>
              <div class="upload-file-holder dropzone" fileDragDrop >
                <input
                  class="input-upload-content"
                  type="file"
                  #fileDropRefMiscB
                  id="fileDropRefMiscB"
                  webkitdirectory
                  mozdirectory
                  multiple
                  (change)="uploadFiles(fileDropRefMiscB.files, MISSION_CONSTANTS.datasetMisc, MISSION_CONSTANTS.datasetMisc, 'folder')"/>
                <div class="upload-content">
                  <div class="img-holder">
                    <img [src]="'/images/download.svg' | assetUrl" alt="Download File image"/>
                  </div>
                  <div class="info">
                    <h3 class="heading">
                      Drop files here or
                      <a href="javascript:void(0);">click</a> to upload.
                    </h3>
                    <label class="file-required" for="fileDropRef">Accepted format: .jpg, .jpeg, .png only</label>
                  </div>
                </div>
              </div>
              <div class="f01-btn-holder">
                <button type="button" (click)="onDownloadMisc()" class="btn btn-download" [disabled]="!selectedMiscFile">
                  Download
                </button>
                <button type="submit" class="btn btn-upload">
                  <span>Upload</span>
                  <input 
                    class="input-btn-upload"
                    type="file" 
                    #fileDropRefMiscC 
                    id="fileDropRefMiscC" 
                    webkitdirectory 
                    mozdirectory 
                    multiple 
                    (change)="uploadFiles(fileDropRefMiscC.files, MISSION_CONSTANTS.datasetMisc, MISSION_CONSTANTS.datasetMisc, 'folder')"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>