import { Component } from '@angular/core';
import { ICONS } from 'projects/data-upload/src/app/constants/progress.const';
import { CONNECTION_STATUS } from 'projects/data-upload/src/app/constants/create-mission.const';
const PROGRESS_LINE_ICON_PATH = '/icon/progress-line.svg';
@Component({
  selector: 'app-utm-upload-progressbar-data',
  templateUrl: './upload-progressbar-data.component.html',
  styleUrls: ['./upload-progressbar-data.component.scss']
})
export class UploadProgressbarDataComponent {
  icon = ICONS;
  goodConnection = true;
  connection_status = CONNECTION_STATUS.GOOD_CONN;
  progressPercentage: number = 54;
  progressLineIconPath: string = PROGRESS_LINE_ICON_PATH;
  calculatePointerPosition(): string {
    const containerWidth = 394;
    const pointerWidth = 10;
    const leftPosition = (this.progressPercentage / 100) * (containerWidth - pointerWidth);
    return `${leftPosition}px`;
  }
}
