<div class="upload-container" [ngClass]="headExpand == UPLOAD_DATASET_HEADER.ORTHOMOSAIC_DATA ? 'show-upload-body' : ''">
  <div class="upload-head"
    (click)="onHeadExpand(headExpand == UPLOAD_DATASET_HEADER.ORTHOMOSAIC_DATA?'':UPLOAD_DATASET_HEADER.ORTHOMOSAIC_DATA)">
    <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
    <p>{{UPLOAD_DATASET_HEADER.ORTHOMOSAIC_DATA}}</p>
    @if(isFileUploadedToChild()){
    <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
    }
  </div>
  <div class="upload-body-container">
    <div class="upload-body"
      [ngClass]="subHeadExpand == ORTHOMOSAIC_SUB_HEADER.CAMERA_PARAMS ? 'show-dagAndDrop' : ''">
      <div class="upload-body-head" (click)="onSetSubHead(ORTHOMOSAIC_SUB_HEADER.CAMERA_PARAMS)"
        (keypress)="onSetSubHead(ORTHOMOSAIC_SUB_HEADER.CAMERA_PARAMS)">
        <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
        <p>{{ORTHOMOSAIC_SUB_HEADER.CAMERA_PARAMS}}</p>
        @if(cameraParamUploadedFiles){
        <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
        }
      </div>
      <app-drag-drop (getUploadedFile)="filesDropped($event)" [datasetHeader]="ORTHOMOSAIC_SUB_HEADER.CAMERA_PARAMS"
        [fileType]="MISSION_CONSTANTS.ORTHO_CP" [supportedFile]="'Supported format: .xml only'"
        [showDragDrop]="subHeadExpand == ORTHOMOSAIC_SUB_HEADER.CAMERA_PARAMS"></app-drag-drop>
    </div>
    <div class="upload-body"
      [ngClass]="subHeadExpand == ORTHOMOSAIC_SUB_HEADER.ORTHOMOSAIC ? 'show-dagAndDrop' : ''">
      <div class="upload-body-head" (click)="onSetSubHead(ORTHOMOSAIC_SUB_HEADER.ORTHOMOSAIC)"
        (keypress)="onSetSubHead(ORTHOMOSAIC_SUB_HEADER.ORTHOMOSAIC)">
        <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
        <p>{{ORTHOMOSAIC_SUB_HEADER.ORTHOMOSAIC}}</p>
        @if(orthomosaicUploadedFiles){
        <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
        }
      </div>
      <app-drag-drop (getUploadedFile)="filesDropped($event)" [datasetHeader]="ORTHOMOSAIC_SUB_HEADER.ORTHOMOSAIC"
        [fileType]="MISSION_CONSTANTS.ORTHOMOSAIC" [supportedFile]="'Supported formats: .tif, .tiff'"
        [showDragDrop]="subHeadExpand == ORTHOMOSAIC_SUB_HEADER.ORTHOMOSAIC"></app-drag-drop>
    </div>
  </div>
</div>