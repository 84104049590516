import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ICONS } from '../constants/progress.const';
import { MissionSharedService } from '../services/mission-shared.service';
import { NOTIFY_MSG } from '../shared/constants';
import { ExternalClientAssetService } from '../services/external-client-asset.service';
import { CreateMissionService } from 'projects/data-upload/src/app/services/create-mission.service';
import { GridMissionSharedService } from '../services/grid-mission-shared.service';
import { MISSION_STATUS } from '../constants/create-mission.const';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-utm-upload-dataset-pipline-grid',
  templateUrl: './upload-dataset-pipline-grid.component.html',
  styleUrls: ['./upload-dataset-pipline-grid.component.scss'],
})
export class UploadDatasetPiplineGridComponent implements OnInit {
  @Input() backToCreateMission?: boolean;
  @Input() isDetailUpdated?: boolean;
  @Input() missionSiteName!: string;
  @Output() hideMission: EventEmitter<boolean> = new EventEmitter();
  @Output() closeMission = new EventEmitter();
  icons = ICONS;
  toggleFolder = true;
  isHideMissionSection: boolean = false;
  isFolderUploaded: boolean = true;
  openSubFolders: boolean = false;
  selectedResult: any;
  isShowToaster: boolean = false;
  toasterClass: string = '';
  toasterMsg: string = '';
  missionId!: string;
  folder: any;
  searchControlField: string | undefined;
  attachments:any[]=[];
  workspaceId! : string;
  selectedindex:number= 0;
  searchResults: any[] = [];
  searchBarOpen: boolean = false;
  siteId!: string;
  fullMatchingData: any;
  matchingDataList: string[] = [];
  searchControl: string = '';
  @Output() updateActiveIndex: EventEmitter<number> = new EventEmitter();
  assetIds : Set<string> = new Set([]) ;
  assetIdsMap:any = []
  @Input() selectedMissionObjective = null;
  createMissionParams = this.missionSharedService.createMissionParams_signal

  constructor(
    public gridMissionSharedService: GridMissionSharedService,
    public missionSharedService: MissionSharedService,
    private createMissionService: CreateMissionService,
    private externalClientAssetService: ExternalClientAssetService,
  ) {}
  ngOnInit(): void {
    this.preCheckMatchedAsset();
  }
  folders: Set<any> = new Set();
  toggleButton() {
    this.toggleFolder = !this.toggleFolder;
  }
  uploadDataList: any;
  uploadFileType!: string;
  uploadparentFileType!: string;

  uploadFilesVisual(fileDrop: any, fileType: any) {
    let isValidFiles = this.gridMissionSharedService.isValidFileTypes(
      fileDrop,
      fileType
    );
    if (isValidFiles) {
      for (let i = 0; i < fileDrop.length; i++) {
        const Folder: string[] = fileDrop[i].webkitRelativePath.split('/');
        this.gridMissionSharedService.uploadFolderVisual.subFolder.add(Folder[1]);
      }
      this.gridMissionSharedService.uploadFolderVisual.parentFolderName =
        fileDrop[0].webkitRelativePath.split('/')[0];
    } else {
      this.isShowToaster = true;
      this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
      this.toasterMsg = NOTIFY_MSG.INVALID_FILE_FORMAT;
      setTimeout(() => {
        this.isShowToaster = false;
      }, 5000);
    }
    if (isValidFiles) {
      this.uploadDataList = fileDrop;
      this.uploadFileType = fileType;
      this.uploadparentFileType =
        this.gridMissionSharedService.uploadFolderVisual.parentFolderName;
    }

    this.isFolderUploaded = false;
    this.openSubFolders = true;
  }



  preCheckMatchedAsset() {
    this.externalClientAssetService.searchExternalClientAsset().subscribe({
      next: (data) => {
        this.fullMatchingData = data.records;
        this.matchingDataList = this.fullMatchingData.map(
          (data: any) => data.assetName
        );
      },
    });
  }

  async uploadFile() {
    this.gridMissionSharedService.uploadFolderVisual.files = [
      ...this.uploadDataList,
    ];
    this.gridMissionSharedService.populateGridFilesProgressList(
      this.uploadDataList
    );
    this.onSumbitInfo()
      .then((resp: any) => {
        this.missionId = resp._id;
        this.createMissionParams.update(value => value = { ...value, missionId: this.missionId })
        this.gridMissionSharedService.uploadGRIDAssetsToS3(
          this.uploadDataList,
          this.missionId,
        );
        this.closePipeline();
      })
      .catch((err: any) => {
        this.isShowToaster = true;
        this.toasterClass = NOTIFY_MSG.ERROR_CLASS;
        this.toasterMsg = NOTIFY_MSG.MISSION_FAILED;
        setTimeout(() => {
          this.isShowToaster = false;
        }, 3000);
      });
  }

  closePipeline(){
    setTimeout(() => {
      this.closeMission.emit();
    }, 4000);
  }

  showResult(data: any, index: number) {
    const file = [...this.gridMissionSharedService.uploadFolderVisual.subFolder][
      index
    ];
  }

  back() {
    this.updateActiveIndex.emit(2);
  }

searchDisabled: boolean = false;

searchAsset(event: string,i:number) {
  this.searchResults = [];
  event &&
    this.externalClientAssetService
      .searchExternalClientAsset(event)
      .subscribe({
        next: (data) => {
          this.searchResults = data.records;
          this.selectedindex = i
        },
      });
  this.searchControlField = this.folder.toString().split('.')[0];
}

selectedItem: any;
selectedSearchResult: any = '';

selectResult(result: any,index :number, subFolderName :string) {
  this.selectedItem = result;
  const val = document.getElementById(index.toString())!
  val.innerHTML = this.selectedItem.assetName
  this.selectedSearchResult = this.selectedItem.assetName;
  this.populateAssetMap(this.selectedItem?._id,subFolderName)
  this.searchDisabled = true;
}

populateAssetMap(id:number,key:string) {
  const selectedItemIndex = this.assetIdsMap.findIndex((item:any)=>item.folderName == key)
  if(selectedItemIndex != -1) {
    this.assetIdsMap[selectedItemIndex].assetId = id
  }
  else {
    this.assetIdsMap = [
      ...this.assetIdsMap,
      {
        folderName: key,
        assetId:id
      }
    ]
  }
}

  async onSumbitInfo() {
    const missionDataParams = this.createMissionParams()
    const userInfo = this.gridMissionSharedService.getUserInfo();
    this.assetIdsMap.map((item:any)=>this.assetIds.add(item.assetId))
    let postMissionFormsData = {
      assetIds:[...this.assetIds],
      status: MISSION_STATUS.DRAFT,
      workspaceId: userInfo.activeWorkspaceId,
      objective: this.selectedMissionObjective,
      name: missionDataParams.name
    };
    if (!missionDataParams?.missionId) {
      return await lastValueFrom(
        this.createMissionService.postMissionData(postMissionFormsData)
      );
    } else {
      return await lastValueFrom(
        this.createMissionService.updateMissionData(missionDataParams.missionId, postMissionFormsData)
      );
    }
    return;
  }
}
