import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedFunctionsService } from 'projects/data-upload/src/app/services/shared-functions.service';
import { CONSTANTS_MISSION } from 'projects/data-upload/src/app/constants/create-mission.const';
@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['../create-mission.component.scss']
})
export class UploadFilesComponent {
  showUploadSection : boolean;
  latestMiscData!: {preSignedUrl: string, tags: string[]}[];
  latestF01Data!: {preSignedUrl: string, tags: string[]}[];
  f01FileName!: string;
  miscFilesName: string[] = [];
  selectedMiscFile: string | null = null;
  MISSION_CONSTANTS = CONSTANTS_MISSION;
  selectedF01File: string | null = null;

 constructor(
  private sharedFunctionsService : SharedFunctionsService
 ){
  this.showUploadSection = this.sharedFunctionsService.showUploadSection
  this.latestF01Data = this.sharedFunctionsService.latestF01Data;
  this.f01FileName = this.sharedFunctionsService.f01FileName;
  this.miscFilesName = this.sharedFunctionsService.miscFilesName;
  this.selectedMiscFile = this.sharedFunctionsService.selectedMiscFile;
  this. selectedF01File = this.sharedFunctionsService.selectedF01File
 }

 selectF01Folder(f01FileName: string){
  this.sharedFunctionsService.selectF01Folder(f01FileName);
 }

 uploadFiles(files: File[] | FileList | null, fileType: string, parentFileType: string, type: 'file' | 'folder'){
  this.sharedFunctionsService.uploadFiles(files, fileType, parentFileType, type)
 }

 onDownloadMisc() {
  this.sharedFunctionsService.onDownloadMisc();
 }

 selectMiscFolder(miscFileName: string){
  this.sharedFunctionsService.selectMiscFolder(miscFileName)
 }

 onDownloadF01(){
  this.sharedFunctionsService.onDownloadF01();
 }



}
