import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workflowProgress',
})
export class WorkflowProgressPipe implements PipeTransform {
  transform(percentageCompleted: number): number {
    return Math.floor(percentageCompleted * 100);
  }

}
