import { ChangeDetectorRef, Component, ElementRef, ViewChild, inject } from '@angular/core';
import { IWorkflowProgress, IWorkflowResponse, WorkflowProgress } from '../../interfaces/create-mission-workflow.interface';
import { ICONS, PROGRESS_WAITING_MSG } from '../../constants/progress.const';
import { WorkflowSharedService } from '../../services/workflow-shared.service';
import { Subject } from 'rxjs';
import { MissionSharedService } from '../../services/mission-shared.service';

@Component({
  selector: 'app-workflow-progress-bar',
  templateUrl: './workflow-progress-bar.component.html',
  styleUrl: './workflow-progress-bar.component.scss'
})
export class WorkflowProgressBarComponent {
  @ViewChild('overallProgress') overallProgress: ElementRef<HTMLDivElement>
  @ViewChild('ResourceIngestion') ResourceIngestion: ElementRef<HTMLDivElement>

  // inject
  missionSharedService = inject(MissionSharedService)
  workflowSharedSer = inject(WorkflowSharedService)
  changeDetectorRef = inject(ChangeDetectorRef)

  overallProgressValue = 0
  workflowDetails!: IWorkflowProgress[];
  unSubscribe = new Subject<''>()
  icon = ICONS;
  progress_waiting_msg = PROGRESS_WAITING_MSG;

  createMissionParams = this.missionSharedService.createMissionParams_signal
  workflowProgressData: IWorkflowResponse;
  overallProgressForWorkFlow: number;
  workFlowRunStatusList: WorkflowProgress[] = []

  ngOnInit() {
    this.workflowSharedSer.workflowProgressArraySubject
      .subscribe((updatedWorkflowProgresses) => {
        if (updatedWorkflowProgresses.length) this.workflowDetails = [...updatedWorkflowProgresses];
        this.changeDetectorRef.detectChanges()
      });
    this.getWorkFlowData()
  }

  hideProgressBar(workflowId: string) {
    this.workflowSharedSer.hideWorkflowById(workflowId);
  }

  workflowRestart(workflowId: string) {
    this.workflowSharedSer.restartWorkflowById(workflowId);
  }

  workflowCancel(workflowId: string) {
    this.workflowSharedSer.abortWorkFlowById(workflowId);
  }
  closeWorkflowStatusBar() {
    this.workflowDetails = []
    this.unSubscribe.next('')
  }

  getWorkFlowData() {
    this.workflowSharedSer.workflowProgressData$.subscribe((workflowProgress) => {
      if (workflowProgress) {
        this.workflowProgressData = structuredClone(workflowProgress)
        this.overallProgressForWorkflowRun()
        this.getWorkFlowStatusMessage()
      }
    })
  }

  overallProgressForWorkflowRun() {
    let progress = 0
    this.workflowProgressData?.workflowSteps?.forEach((workFlow) => {
      if (workFlow.progress) {
        progress += workFlow.progress.percentageCompleted * 100
      }
    })
    this.overallProgressForWorkFlow = Math.floor(progress / this.workflowProgressData.workflowSteps.length)
  }

  isWorkflowFailed() {
    return this.workflowProgressData?.workflowSteps?.some((data) => data.status == "failed")
  }

  getWorkFlowStatusMessage() {
    this.workflowProgressData?.workflowSteps?.filter((workflowStep) => workflowStep.progress).map(({ _id, displayName, progress, status }) => {
      if (progress) {
        const workflowStepParent = {
          progressInformation: displayName,
          child: [],
          id: _id,
        }
        const parentIndex = this.workFlowRunStatusList.findIndex(({id}) => id == _id)
        if (parentIndex == -1) {
          this.workFlowRunStatusList.push(workflowStepParent)
        } else {
          const workflowStepChild = {
            status: status !== 'failed',
            progressInformation: progress.detail,
            id: progress._id
          }
          const childIndex = this.workFlowRunStatusList[parentIndex].child.findIndex(({id}) => id == workflowStepChild.id)
          if (childIndex == -1) {
            this.workFlowRunStatusList[parentIndex].child.push(structuredClone(workflowStepChild))
          } else if (status == 'failed' && childIndex != -1) {
            this.workFlowRunStatusList[parentIndex].child[childIndex].status = false
            const value = {
              progressInformation: 'Overall progress failed',
              child: [],
              id: 'Overall progress failed',
            }
            this.workFlowRunStatusList.push(value)
          }
        }
      }
    })
  }
}
