<div class="upload-container" [ngClass]="headExpand == UPLOAD_DATASET_HEADER.DRONE_IMAGE ? 'show-upload-body' : ''">
  <div class="upload-head" (click)="onHeadExpand(headExpand == UPLOAD_DATASET_HEADER.DRONE_IMAGE?'':UPLOAD_DATASET_HEADER.DRONE_IMAGE)">
    <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
    <p>{{UPLOAD_DATASET_HEADER.DRONE_IMAGE}}</p>
    @if(droneImageUploadedFiles){
      <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
    }
  </div>
  <div class="upload-body-container">
    <div class="upload-body-dropdown">
      <div class="upload-body">
        <input #inputDroneImage title=" " (click)="inputDroneImage.value = ''" dropStyle [parentFileType]="MISSION_CONSTANTS.DRONE_IMAGE"
          (isValidFile)="checkFileValid($event)" (change)="
              onSentUploadedFile(
                inputDroneImage.files,
                MISSION_CONSTANTS.DRONE_IMAGE,
                MISSION_CONSTANTS.DRONE_IMAGE
              )" id="inputDroneImage" type="file" multiple webkitdirectory mozdirectory />
        <div class="drag-and-drop-text-container">
          <img [src]="'/images/cloud_upload.svg' | assetUrl" alt="cloud_upload" [ngClass]="!isValid?'not-valid-file':''">
          <p><span class="click-to-upload">Click to upload folder</span><span>&nbsp; or &nbsp;drag and drop</span></p>
          @if(!isValid){
          <p>Wrong file format. Please upload file that is</p>
          }
          <p>Supported formats: .JPG, .jpg, .jpeg</p>
        </div>

      </div>
      @if(droneImageUploadedFiles && isValid){
      <div class="upload-preview-container">
        <app-upload-folder-preview [uploadCategory]="{parentType:MISSION_CONSTANTS.DRONE_IMAGE,childType:MISSION_CONSTANTS.DRONE_IMAGE}"></app-upload-folder-preview>
      </div>
      }
    </div>
  </div>
</div>
