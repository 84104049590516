import {
  Directive,
  HostBinding,
  HostListener,
  Output,
  EventEmitter,
  inject,
  Input,
} from '@angular/core';
import { MissionSharedService } from '../../services/mission-shared.service';

@Directive({
  selector: '[dropStyle]',
})
export class DropStyleDirective {
  @Input() parentFileType: string;
  @Output() isValidFile: EventEmitter<boolean> = new EventEmitter();
  missionSharedService = inject(MissionSharedService);
  isValid: boolean = true;

  @HostBinding('style.background') private background = '';
  @HostBinding('style.border') private border =
    '1px dashed rgba(135, 154, 166,0.4)';
  @HostBinding('style.borderRadius') private borderRadius = '5px ';

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'rgb(90 168 173 / 40%)';
    this.border = '1px dashed rgba(0, 240, 255, 1)';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'rgba(255, 255, 255, 0.1)';
    this.border = '1px dashed rgba(200, 200, 200, 1)';
  }

  @HostListener('change', ['$event']) public onChange(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    const fileList = (evt.target as HTMLInputElement).files;
    if (fileList && this.parentFileType) {
      this.isValid =
        this.missionSharedService.getValidFiles(fileList, this.parentFileType)
          ?.length > 0;
    }

    if (!this.isValid) {
      this.border = '1px solid rgba(255, 70, 70, 1)';
      this.background = 'rgba(211, 47, 47, 0.04)';
      this.isValidFile.emit(this.isValid);
      setTimeout(() => {
        this.border = '1px dashed rgba(135, 154, 166,0.4)';
        this.background = '';
        this.isValidFile.emit(!this.isValid);
      }, 4000);
    }
  }

  @HostListener('click', ['$event']) public aaaa(evt: Event) {
    evt.stopPropagation();
    this.background = '';
    this.border = '1px dashed rgba(135, 154, 166,0.4)';
    this.isValidFile.emit(true);
  }
}
