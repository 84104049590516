<div *ngIf="!hideMissionSection" [ngClass]="hideMissionSection ? 'mission-holder' : ''">
  <div class="mission-wrapper" [ngClass]="loadMissionDetail ? 'upload-dataset-holder' : ''">
    <div class="mission-bg">
      <img class="mission-img"
        src="{{ sitePlaceholderImage ? sitePlaceholderImage : ('/images/default_tower.png' | assetUrl) }}"
        alt="Site Image" />
    </div>
    <button class="btn btn-close" (click)="initHideMission()">
      <img [src]="'/images/close.svg' | assetUrl" alt="close" />
    </button>
    <div class="mission-holder-wrapper">
      <div class="mission-inner" *ngIf="!gridFlowFlag">
        <h3 class="title">
          {{missionSiteInfo.siteName}}
        </h3>
        <h3 class="title">
          {{missionSiteInfo.externalClientSiteId}}
        </h3>
        <p class="measurement">
          <img [src]="'/images/globe.svg' | assetUrl" alt="Globe" class="measurement-icon" />
          {{missionSiteInfo.siteLatitude}}, {{missionSiteInfo.siteLongitude}}
        </p>
        <span class="badge badge-default">{{missionSiteInfo.industryType}}</span>
        <span class="badge badge-default">{{missionSiteAsset.length}}</span>
      </div>
      <div *ngIf="activeIndex === 2">
        <app-mission-detail [missionSiteID]="getSiteID" (updateActiveIndex)="updateActiveIndex($event)"
          [getCreateMissionForm1Data]="createMissionFormData" [updateMissionForm1Data]="updateMissionFormData" [gridFlowFlag]="gridFlowFlag"
          (uploadDatasetComponent)="initUploadDatasetComponent($event)" [isMissionCreatedCheck]="isMissionCreated"  (gridFlow)="handleGridFlag($event)"
          [missionObjectiveList]="missionObjectives" [isDetailUpdated]="isDetailUpdated" (onMissionObjectiveChange)="onMissionObjectiveChange($event)"
          (closeMission)="closeMission()"></app-mission-detail>
      </div>
      <ng-template [ngIf]="!gridFlow && activeIndex === 3">
        <app-upload-new-dataset #uploadNewDatasetView (hideMission)="inithideMission($event)" (updateActiveIndex)="updateActiveIndex($event)"
          (initBackToParentComponent)="backToParentComp($event)" [isDetailUpdated]="isDetailUpdated"
          (resetCreateMission)="closeMission({isProcessCompleted: true})" [missionSiteName]="missionSiteInfo.siteName">
        </app-upload-new-dataset>
      </ng-template>
      <app-utm-upload-dataset-pipline-grid *ngIf="gridFlow && activeIndex === 3" (updateActiveIndex)="updateActiveIndex($event)"  [selectedMissionObjective]="selectedMissionObjective" (closeMission)="closeMission()" />
      <form [formGroup]="UploadDatasetForm" (ngSubmit)="onSumbitDataset()" *ngIf="activeIndex === 1">
          <div class="form-holder-main">
            <div class="form-holder">
              <div class="form-group">
                <label class="label" for="missionProject">Select Project<em class="required">*</em></label>
                <select *ngIf="dropdownTypeFlag" id="missionProject" class="form-control mission-field"
                  formControlName="missionProject" placeholder="Choose Mission Project" required
                  (change)="changeMissionProject($event)" [(ngModel)]="selectedProjectId">
                  <option value="undefined">Choose Project</option>
                  <option *ngFor="let missionProject of missionProjects.records" [ngValue]="missionProject._id">
                    {{missionProject.title}}</option>
                </select>
                <select id="missionProject" class="form-control mission-field" formControlName="missionProject"
                  *ngIf="!dropdownTypeFlag" placeholder="Choose Mission Project" required
                  (change)="changeMissionProject($event)" [(ngModel)]="selectedProjectId">
                  <option *ngIf="completedProjects.length > 0" [ngValue]="completeSelected._id">
                    {{completeSelected.title}}</option>
                  <option *ngIf="activeProjects.length > 0 && completedProjects.length === 0"
                    [ngValue]="activeSelected._id">{{activeSelected.title}}</option>
                  <option
                    *ngIf="draftProjects.length > 0 && completedProjects.length === 0 && activeProjects.length === 0"
                    [ngValue]="draftSelected._id">{{draftSelected.title}}</option>
                  <optgroup label="Processed" *ngIf="completedProjects && completedProjects.length > 0">
                    <option *ngFor="let missionProject of completedProjects" [ngValue]="missionProject._id">
                      {{missionProject.title}}</option>
                  </optgroup>
                  <optgroup label="Processing" *ngIf="activeProjects && activeProjects.length > 0">
                    <option *ngFor="let missionProject of activeProjects" [ngValue]="missionProject._id">
                      {{missionProject.title}}</option>
                  </optgroup>
                  <optgroup label="---------------" *ngIf="draftProjects && draftProjects.length > 0">
                    <option *ngFor="let missionProject of draftProjects" [ngValue]="missionProject._id">
                      {{missionProject.title}}</option>
                  </optgroup>
                </select>
                <div
                  *ngIf="!projectRequired || UploadDatasetForm.controls['missionProject'].invalid && (UploadDatasetForm.controls['missionProject'].dirty || UploadDatasetForm.controls['missionProject'].touched)"
                  class="error-msg">
                  Project Name is required
                </div>
              </div>
              <div class="form-group">
                <label class="label" for="missionAsset">Select Asset<em class="required">*</em></label>
                <select #missionAsset (change)="onMissionAssetChange(missionAsset.value)" id="missionAsset"
                  class="form-control mission-field" formControlName="missionAsset" placeholder="Choose Mission Asset"
                  required (change)="changeMissionAsset($event)">
                  <option selected value="">Choose Asset</option>
                  <option *ngFor="let missionAsset of missionSiteAsset" value="{{missionAsset._id}}">
                    {{missionAsset.assetName}}</option>
                </select>
                <div *ngIf="!assetRequired && UploadDatasetForm.controls['missionAsset'].invalid" class="error-msg">
                  Asset Name is required
                </div>
              </div>
              <div class="form-group">
                <select #missionData (change)="onMissionDataChange(missionData.value)" id="missionDataTypes"
                  class="form-control mission-field" formControlName="missionDataTypes"
                  (change)="changeMissionDataType($event)">
                  <option selected value="">Select Data</option>
                  <option *ngFor="let missionDataType of missionDataTypes | keyvalue"
                    value="{{ missionDataType.value }}">{{ missionDataType.value }}</option>
                </select>
              </div>
            </div>
            <app-upload-files></app-upload-files>
            <div class="btn-holder">
              <button type="submit" class="btn btn-outline" *PermissionGuard="[USER_PERMISSIONS.MISSIONS_CREATE]"
                [disabled]="UploadDatasetForm.invalid">Upload Dataset</button>
              <button type="button" class="btn btn-default" (click)="validateCanvasBtn()">canvas</button>
            </div>
          </div>
      </form>
    </div>
  </div>
</div>
<ng-template
  [ngIf]="(isShowMissionConfirmation && loadUploadDataset && !hideMission) || (isShowMissionConfirmation && !this.hideMission && this.isMissionCreated)">
  <div class="modal fade show mission-confirm-modal" id="closeMissionConfirmation" data-bs-backdrop="static"
    data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="img-holder">
            <img [src]="'/images/warning.png' | assetUrl" alt="warning image" />
          </div>
          <div class="content">
            <h2 class="heading">Cancel the process?</h2>
            <p class="info">All the changes made will be deleted.</p>
          </div>
        </div>
        <div class="btn-holder">
          <button type="button" class="btn btn-default" (click)="closeMission()">Yes</button>
          <button type="button" class="btn btn-danger" (click)="stayAliveMission()">No</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template [ngIf]="workflowProgressArray.length > 0 || showUploadBar || showDownloadBar">
  <div class="popups-container">
    <app-files-uploading-progress *ngIf="showUploadBar" />
    <app-files-downloading-progress *ngIf="showDownloadBar" />
    <app-grid-files-uploading-progress></app-grid-files-uploading-progress>
    <!-- <app-utm-upload-progressbar-data /> -->
  </div>
</ng-template>
<ng-template [ngIf]="showReplaceModal">
  <app-utm-mission-confirmation-popup (userConsent)="onReplaceConsent($event)" [popupHeading]="'Replace the file?'"
    [popupDescription]="'The folder will be deleted.'">
  </app-utm-mission-confirmation-popup>
</ng-template>
<ng-template [ngIf]="isShowCancelWorkflowPopup">
  <app-utm-mission-confirmation-popup (userConsent)="stayOnMission($event)" />
</ng-template>
<ng-template [ngIf]="isShowToaster">
  <app-utm-toaster-custom [toasterClass]="toasterClass" [toasterMsg]="toasterMsg"></app-utm-toaster-custom>
</ng-template>
<ng-template [ngIf]="workflowSharedService.getWorkFlowStatus() == workFlowStatus.COMPLETED">
  <app-utm-toaster-upload-dataset />
</ng-template>

