<div class="asset-selection-container">
  <div class="input-group-container">
    <label class="input-group-label" for="workflow">Asset</label>
    <div #assetSelect class="custom-select-box asset-select">
      <div class="head">
        <form [formGroup]="assetSearchForm">
          <input formControlName="assetSearchInput" (focus)="assetSelect.classList.add('dropdown-show')"
            class="input-tag" type="text" placeholder="Search Asset">
          <img [src]="'/images/chevron.svg' | assetUrl" alt="chevron" />
        </form>
      </div>
      <div class="dropdown dropdown-scroll">
        <div class="dropdown-body-container">
          @for (asset of assetList; track asset._id) {
          <div class="asset-list-checkbox-container">
            <div class="asset-list-checkbox">
              <input [checked]="assetIdList.includes(asset._id)" type="checkbox" name="asset-select" [id]="asset._id" (change)="onSelectedAsset(asset)">
            </div>
            <div class="asset-list-name">
              <label class="asset-list-label" [for]="asset._id">
                <p>{{asset.assetName}}</p>
                @if(asset.assetHierarchy){
                <p>
                  @for (item of asset.assetHierarchy | assetHierarchyArray; track item; let last = $last){
                  @if(item.value){
                  <span>
                    {{ item.value }}
                    @if(!last){
                    <span>&bsol;</span>
                    }
                  </span>
                  }
                  }
                </p>
                }
              </label>
            </div>
          </div>
          }@empty{
          <div class="asset-list-label">
            <p> No data found</p>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
  @if(selectedAssetList.length){
  <div class="asset-preview-container">
    <label>Asset List</label>
    <div class="asset-preview-list-container">
      @for(asset of selectedAssetList; track asset){
      <div class="asset-preview-list">
        <div class="asset-name">
          <p>{{asset.assetName}}</p>
          <img [src]="'/images/close.svg' | assetUrl" alt="close-icon" (click)="onRemoveSelectedAsset(asset._id)">
        </div>
        @if(asset.assetHierarchy){
        <p>
          @for (item of asset.assetHierarchy | assetHierarchyArray; track item; let last = $last) {
          @if(item.value){
          <span>
            {{ item.value }}
            @if(!last){
            <span>&bsol;</span>
            }
          </span>
          }
          }
        </p>
        }
      </div>
      }
    </div>
  </div>
  }
</div>
