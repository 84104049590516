<div [ngClass]="{
  'd-none': isHideMissionSection,
  'upload-dataset-wrapper': !isHideMissionSection
}">
<h3 class="title">Uploading new dataset</h3>
<div class="progress-holder">
  <ul class="progress-list">
    <li class="complete"><span class="bullet-step">Mission Detail</span></li>
    <li class="active"><span class="bullet-step">Upload Dataset</span></li>
  </ul>
</div>
<div class="form-holder" *PermissionGuard="[USER_PERMISSIONS.DATA_RESOURCES_UPLOAD]">
  <form>
    <div class="upload-accordion accordion" id="uploadDatasetAccordion">
      <div class="card">
        <div class="card-header" id="uploadOne">
          <h2 class="title mb-0">
            <button class="btn btn-link btn-block text-left" type="button" data-bs-toggle="collapse"
              data-bs-target="#uploadCollapseOne" aria-expanded="true" aria-controls="uploadCollapseOne">
              CAMERA PARAMETERS<em class="required">*</em>
            </button>
          </h2>
        </div>
        <div id="uploadCollapseOne" class="collapse show" aria-labelledby="uploadOne"
          data-bs-parent="#uploadDatasetAccordion">
          <div class="card-body">
            <h4 class="title">All folders</h4>
            <div class="uploaded-assets-holder" (click)="resetFileInput(fileDropRef12)">
              <button class="btn add-new-btn" type="button">
                <img [src]="folder | assetUrl" alt="Add Folder" />
                <span class="sub-title">Add New</span>
                <input class="uploaded-files input-disabled" [ngClass]="[
                    isInputDisabled(MISSION_CONSTANTS.datasetCP, MISSION_CONSTANTS.datasetCP) ? 'uploaded' : 'in-process'
                  ]" type="file" #fileDropRef12 id="fileDropRef12" (change)="
                    uploadFiles(fileDropRef12.files, MISSION_CONSTANTS.datasetCP, MISSION_CONSTANTS.datasetCP)"
                    (click)="resetFileInput(fileDropRef12)" />
              </button>
              <div class="d-flex flex-wrap">
                <div class="uploaded-files input-disabled"
                  *ngIf="getUploadingFiles()[MISSION_CONSTANTS.datasetCP].files.length"
                  (click)="DeleteFoldersDataSet(MISSION_CONSTANTS.datasetCP,MISSION_CONSTANTS.datasetCP,fileDropRef12)" [ngClass]="[
                    isInputDisabled(MISSION_CONSTANTS.datasetCP, MISSION_CONSTANTS.datasetCP) ? 'uploaded' : 'in-process']">
                                      <img [src]="uploadedFolder | assetUrl" alt="Uploaded Folder" />
                                      <input class="sub-title input-field" type="text"
                    [name]="getUploadingFiles()[MISSION_CONSTANTS.datasetCP].title"
                    [value]="getUploadingFiles()[MISSION_CONSTANTS.datasetCP].title" />
                  <small class="count">{{
                    getUploadingFiles()[MISSION_CONSTANTS.datasetCP].files.length
                    }}
                    file</small>
                </div>
              </div>
            </div>
            <div class="upload-file-holder dropzone"
              fileDragDrop>
              <input type="file" #fileDropRef1 id="fileDropRef1"
                (change)="uploadFiles(fileDropRef1.files, MISSION_CONSTANTS.datasetCP, MISSION_CONSTANTS.datasetCP)"
                (click)="DeleteFoldersDataSet(MISSION_CONSTANTS.datasetCP,MISSION_CONSTANTS.datasetCP,fileDropRef1)" />
              <div class="upload-content">
                <div class="img-holder">
                  <img [src]="downloadIcon | assetUrl" alt="Download File image" />
                </div>
                <div class="info">
                  <h3 class="heading">
                    Drop files here or
                    <a href="javascript:void(0);">click</a> to upload.
                  </h3>
                  <label class="file-required" for="fileDropRef">Accepted format: .xml only</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="uploadTwo">
          <h2 class="title mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#uploadCollapseTwo" aria-expanded="false" aria-controls="uploadCollapseTwo">
              DIGITAL TWIN<em class="required">*</em>
            </button>
          </h2>
        </div>
        <div id="uploadCollapseTwo" class="collapse" aria-labelledby="uploadTwo"
          data-bs-parent="#uploadDatasetAccordion">
          <div class="card-body">
            <h4 class="title">All folders</h4>
            <div class="uploaded-assets-holder" (click)="resetFileInput(fileDropRef2)">
              <button class="btn add-new-btn" type="button">
                <img [src]="folder | assetUrl" alt="Add Folder" />
                <span class="sub-title">Add New</span>
                <input type="file" class="uploaded-files input-disabled" [ngClass]="[
                    isInputDisabled(MISSION_CONSTANTS.datasetDT, MISSION_CONSTANTS.datasetDT) ? 'uploaded' : 'in-process'
                  ]" type="file" #fileDropRef21 id="fileDropRef21" webkitdirectory mozdirectory multiple
                  (click)="resetFileInput(fileDropRef21)" (change)="
                    uploadFiles(fileDropRef21.files, MISSION_CONSTANTS.datasetDT, MISSION_CONSTANTS.datasetDT)" />
              </button>
              <div class="d-flex flex-wrap">
                <div class="uploaded-files input-disabled"
                  (click)="DeleteFoldersDataSet(MISSION_CONSTANTS.datasetDT,MISSION_CONSTANTS.datasetDT,fileDropRef2,fileDropRef21.files)"
                  *ngIf="getUploadingFiles()[MISSION_CONSTANTS.datasetDT].files.length"
                  [ngClass]="[isInputDisabled(MISSION_CONSTANTS.datasetDT, MISSION_CONSTANTS.datasetDT) ? 'uploaded' : 'in-process']">
                  <img [src]="uploadedFolder | assetUrl" alt="Uploaded Folder" />
                  <input class="sub-title input-field" type="text"
                    [name]="getUploadingFiles()[MISSION_CONSTANTS.datasetDT].title"
                    [value]="getUploadingFiles()[MISSION_CONSTANTS.datasetDT].title" />
                  <small class="count">{{getUploadingFiles()[MISSION_CONSTANTS.datasetDT].files.length }}files</small>
                </div>
              </div>
            </div>
            <div class="upload-file-holder dropzone" [ngClass]="[
                isInputDisabled(MISSION_CONSTANTS.datasetDT, MISSION_CONSTANTS.datasetDT) ? 'disabled' : ''
              ]" fileDragDrop (filesChangeEmiter)="uploadFiles($event, '', '')">
              <input type="file" #fileDropRef2 id="fileDropRef2" webkitdirectory mozdirectory multiple (change)="
                  uploadFiles(fileDropRef2.files, MISSION_CONSTANTS.datasetDT, MISSION_CONSTANTS.datasetDT)" />
              <div class="upload-content">
                <div class="img-holder">
                  <img [src]="downloadIcon | assetUrl" alt="Download File image" />
                </div>
                <div class="info">
                  <h3 class="heading">
                    Drop folder here or
                    <a href="javascript:void(0);">click</a> to upload.
                  </h3>
                  <label class="file-required" for="fileDropRef">Accepted format: .xml, .mtl, .jpeg, .obj only</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header" id="uploadThree">
          <h2 class="title mb-0">
            <button class="btn btn-link btn-block text-left collapsed" type="button" data-bs-toggle="collapse"
              data-bs-target="#uploadCollapseThree" aria-expanded="false" aria-controls="uploadCollapseThree">
              DRONE IMAGES<em class="required">*</em>
            </button>
          </h2>
        </div>
        <div id="uploadCollapseThree" class="collapse {{ isFolderClicked }}" aria-labelledby="uploadThree"
          data-bs-parent="#uploadDatasetAccordion">
          <div class="card-body">
            <div class="uploaded-assets-holder">
              <div class="img-default-folder-holder" [ngClass]="[isFolderClicked ? 'd-none' : 'd-flex']">
                <h4 class="title w-100">All folders</h4>
                <div class="img-holder uploaded-files-img input-disabled" [ngClass]="[
                missionSharedService.checkFilesProgressList(MISSION_CONSTANTS.droneImgTv, MISSION_CONSTANTS.datasetDI) > 0 ?
                isInputDisabled(MISSION_CONSTANTS.droneImgTv, MISSION_CONSTANTS.datasetDI)
                  ? 'uploaded'
                  : 'in-process' : '']">
                  <input type="file" #towerViewImg id="towerViewImg"
                    [ngClass]="[isInputDisabled(MISSION_CONSTANTS.droneImgTv, MISSION_CONSTANTS.datasetDI) ? 'd-none' : '']"
                    [disabled]="getUploadingFiles()[MISSION_CONSTANTS.datasetDI]?.[MISSION_CONSTANTS.droneImgTv]?.files.length"
                    webkitdirectory mozdirectory multiple (click)="resetFileInput(towerViewImg)" (change)="
                      uploadFiles(towerViewImg.files, MISSION_CONSTANTS.droneImgTv, MISSION_CONSTANTS.datasetDI)" />
                  <div class="delete-di"
                    *ngIf="getUploadingFiles()[MISSION_CONSTANTS.datasetDI]?.[MISSION_CONSTANTS.droneImgTv]?.files.length" (click)="
                      DeleteFoldersDataSet(MISSION_CONSTANTS.droneImgTv, MISSION_CONSTANTS.datasetDI, $event, towerViewImg)">
                  </div>
                  <div class="folder-holder">
                    <img [src]=" defaultFolder | assetUrl" alt="Tower View Folder" />
                    <input class="sub-title input-field" type="text" name="tower_view" value="Tower View" />
                  </div>
                </div>
                <div class="img-holder uploaded-files-img input-disabled" [ngClass]="[
                    missionSharedService.checkFilesProgressList(MISSION_CONSTANTS.droneImgLos, MISSION_CONSTANTS.datasetDI) > 0 ?
                      isInputDisabled(MISSION_CONSTANTS.droneImgLos, MISSION_CONSTANTS.datasetDI) ? 'uploaded' : 'in-process' : '']">
                  <input type="file" #lineOfSightImg id="lineOfSightImg" (click)="resetFileInput(lineOfSightImg)"
                    [disabled]="getUploadingFiles()[MISSION_CONSTANTS.datasetDI]?.[MISSION_CONSTANTS.droneImgLos]?.files.length"
                    webkitdirectory mozdirectory multiple (change)="
                      uploadFiles(lineOfSightImg.files, MISSION_CONSTANTS.droneImgLos, MISSION_CONSTANTS.datasetDI)" />
                  <div class="delete-di"
                    *ngIf="getUploadingFiles()[MISSION_CONSTANTS.datasetDI]?.[MISSION_CONSTANTS.droneImgLos]?.files.length"
                    (click)="DeleteFoldersDataSet(MISSION_CONSTANTS.droneImgLos,MISSION_CONSTANTS.datasetDI,lineOfSightImg)"></div>
                  <div class="folder-holder">
                    <img [src]="defaultFolder | assetUrl" alt="Line of Sight Folder" />
                    <input class="sub-title input-field" type="text" name="tower_view" value="Line of Sight" />
                  </div>
                </div>
                <div class="img-holder uploaded-files-img input-disabled" [ngClass]="[
                      missionSharedService.checkFilesProgressList(MISSION_CONSTANTS.droneImageSV, MISSION_CONSTANTS.datasetDI) > 0 ?
                      isInputDisabled(MISSION_CONSTANTS.droneImageSV, MISSION_CONSTANTS.datasetDI) ? 'uploaded' : 'in-process' : '']">
                  <input type="file" #TopToDownImg id="TopToDownImg" webkitdirectory mozdirectory multiple
                    (click)="resetFileInput(TopToDownImg)"
                    [disabled]="getUploadingFiles()[MISSION_CONSTANTS.datasetDI]?.[MISSION_CONSTANTS.droneImageSV]?.files.length"
                    (change)="uploadFiles(TopToDownImg.files,MISSION_CONSTANTS.droneImageSV,MISSION_CONSTANTS.datasetDI)" />
                  <div class="delete-di"
                    *ngIf="getUploadingFiles()[MISSION_CONSTANTS.datasetDI]?.[MISSION_CONSTANTS.droneImageSV]?.files.length"
                    (click)="DeleteFoldersDataSet(MISSION_CONSTANTS.droneImageSV,MISSION_CONSTANTS.datasetDI,TopToDownImg)"></div>
                  <div class="folder-holder">
                    <img [src]="defaultFolder | assetUrl" alt="Top to Down Folder" />
                    <input class="sub-title input-field" type="text" name="tower_view" value="Side View" />
                  </div>
                </div>
                <div class="img-holder uploaded-files-img input-disabled" [ngClass]="[
                    missionSharedService.checkFilesProgressList(MISSION_CONSTANTS.droneImgNadir, MISSION_CONSTANTS.datasetDI) > 0 ?
                      isInputDisabled(MISSION_CONSTANTS.droneImgNadir,MISSION_CONSTANTS.datasetDI) ? 'uploaded' : 'in-process' : '']">
                  <input #nadirImg webkitdirectory mozdirectory multiple id="nadirImg" type="file" multiple (click)="resetFileInput(nadirImg)"
                    [disabled]="getUploadingFiles()[MISSION_CONSTANTS.datasetDI]?.[MISSION_CONSTANTS.droneImgNadir]?.files.length"
                    (change)="uploadFiles(nadirImg.files,MISSION_CONSTANTS.droneImgNadir,MISSION_CONSTANTS.datasetDI)" />
                  <div class="delete-di"
                    *ngIf="getUploadingFiles()[MISSION_CONSTANTS.datasetDI]?.[MISSION_CONSTANTS.droneImgNadir]?.files.length"
                    (click)="DeleteFoldersDataSet(MISSION_CONSTANTS.droneImgNadir,MISSION_CONSTANTS.datasetDI,TopToDownImg)"></div>
                  <div class="folder-holder">
                    <img [src]="defaultFolder | assetUrl" alt="NADIR Folder" />
                    <input class="sub-title input-field" type="text" name="tower_view" value="From Above View" />
                  </div>
                </div>
              </div>
            </div>
            <div class="upload-file-holder dropzone" [ngClass]="['disabled']" fileDragDrop
              (filesChangeEmiter)="uploadFiles($event, '', '')" (click)="resetFileInput(nadirImg1)">
              <input #nadirImg1 id="nadirImg1" type="file" #fileDropRef3 id="fileDropRef3" webkitdirectory
                mozdirectory multiple (change)="uploadFiles(fileDropRef3.files, MISSION_CONSTANTS.datasetDI, '')" />
              <div class="upload-content">
                <div class="img-holder">
                  <img [src]="downloadIcon | assetUrl" alt="Download File image" />
                </div>
                <div class="info">
                  <h3 class="heading">
                    Drop files here or
                    <a href="javascript:void(0);">click</a> to upload.
                  </h3>
                  <label class="file-required" for="fileDropRef">Accepted format: .jpeg only</label>
                </div>
              </div>
            </div>
            <div class="btn-holder btn-holder-sec d-none">
              <button type="button" class="btn btn-outline">Back</button>
              <button type="button" class="btn btn-default">Upload</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="btn-holder">
  <button type="button" class="btn btn-outline" (click)="backToCreateMissionComp(true)">Back</button>
  <button type="submit" class="btn btn-default" (click)="onUploadDataset()" [disabled]="isProcessBtnDiabled()">Process</button>
</div>
</div>
<ng-template [ngIf]="isShowToaster">
<app-utm-toaster-custom [toasterClass]="toasterClass" [toasterMsg]="toasterMsg"></app-utm-toaster-custom>
</ng-template>
<ng-template [ngIf]="isShowProgressModal">
<div class="modal fade show mission-confirm-modal" id="closeMissionConfirmation" data-bs-backdrop="static"
  data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <div class="img-holder">
          <img [src]="'/images/warning.png' | assetUrl" alt="warning image" />
        </div>
        <div class="content-body">
          <h2 class="heading">Upload is in progress</h2>
          <p class="info">
            Please wait. You cannot go back until the upload is in progress.
          </p>
        </div>
      </div>
      <div class="btn-holder">
        <button type="button" class="btn btn-default" (click)="stayOnUploadComponent()">Ok</button>
      </div>
    </div>
  </div>
</div>
</ng-template>
<ng-template [ngIf]="showDeleteModal">
<app-utm-mission-confirmation-popup (userConsent)="deleteFolders($event)" [popupHeading]="deleteModal.POP_HEADING"
  [popupDescription]="deleteModal.POP_DESCRIPTION">
</app-utm-mission-confirmation-popup>
</ng-template>
<ng-template [ngIf]="duplicateFileExist">
<app-utm-mission-confirmation-popup [popupHeading]="duplicateFileUploadModal.POP_HEADING"
  [popupDescription]="duplicateFileUploadModal.POP_DESCRIPTION">
</app-utm-mission-confirmation-popup>
</ng-template>
