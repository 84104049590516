<div class="upload-container" [ngClass]="headExpand == UPLOAD_DATASET_HEADER.PANORAMA_360 ? 'show-upload-body' : ''">
  <div class="upload-head"
    (click)="onHeadExpand(headExpand == UPLOAD_DATASET_HEADER.PANORAMA_360?'':UPLOAD_DATASET_HEADER.PANORAMA_360)">
    <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
    <p>{{UPLOAD_DATASET_HEADER.PANORAMA_360}}</p>
    @if((panoramaUploadedFiles || virtualTourUploadedFiles) && !headExpand ){
      <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
    }
  </div>
  <div class="upload-body-container">
    <div class="upload-body sub-head-panorama "
      [ngClass]="subHeadExpand == PANORAMA_360_SUB_HEADER.PANORAMA ? 'show-dagAndDrop' : ''">
      <div class="upload-body-head"
        (click)="onSetSubHead(PANORAMA_360_SUB_HEADER.PANORAMA, 'sub-head-panorama')">
        <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
        <p>{{PANORAMA_360_SUB_HEADER.PANORAMA}}</p>
        @if(panoramaUploadedFiles){
          <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
        }
      </div>
      <div class="upload-dagAndDrop">
        <div class="upload-body-dropdown">
          <div class="upload-dagAndDrop-body">
            <input #inputPanorama title=" " (click)="inputPanorama.value = ''" dropStyle [parentFileType]="MISSION_CONSTANTS.PANORAMA"
              (isValidFile)="checkFileValid($event,PANORAMA_360.INPUT_PANORAMA)" (change)="onSentUploadedFile(
              inputPanorama.files,
              MISSION_CONSTANTS.PANORAMA,
              MISSION_CONSTANTS.PANORAMA
            )" id="inputPanorama" type="file" multiple webkitdirectory mozdirectory />
            <div class="drag-and-drop-text-container">
              <img [src]="'/images/cloud_upload.svg' | assetUrl" alt="cloud_upload"
                [ngClass]="!isValidPanorama?'not-valid-file':''">
              <p><span class="click-to-upload">Click to upload folder</span><span>&nbsp; or &nbsp;drag and drop</span></p>
              @if(!isValidPanorama){
              <p>Wrong file format. Please upload file that is</p>
              }
              <p>Supported format: .jpeg, .jpg, .png</p>
            </div>
          </div>
          @if(panoramaUploadedFiles && isValidPanorama){
          <div class="upload-preview-container">
            <app-upload-folder-preview [uploadCategory]="{parentType:MISSION_CONSTANTS.PANORAMA,childType:MISSION_CONSTANTS.PANORAMA}"></app-upload-folder-preview>
          </div>
          }
        </div>
      </div>
    </div>
    <div class="upload-body sub-head-virtual-tour"
      [ngClass]="subHeadExpand == PANORAMA_360_SUB_HEADER.VIRTUAL_TOUR ? 'show-dagAndDrop' : ''">
      <div class="upload-body-head"
        (click)="onSetSubHead(PANORAMA_360_SUB_HEADER.VIRTUAL_TOUR,'sub-head-virtual-tour')">
        <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
        <p>{{PANORAMA_360_SUB_HEADER.VIRTUAL_TOUR}}</p>
        @if(virtualTourUploadedFiles){
          <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
        }
      </div>
      <div class="upload-dagAndDrop">
        <div class="upload-body-dropdown">
          <div class="upload-dagAndDrop-body">
            <input #inputVirtualTour title=" " (click)="inputVirtualTour.value = ''" dropStyle [parentFileType]="MISSION_CONSTANTS.VIRTUAL_TOUR"
              (isValidFile)="checkFileValid($event, PANORAMA_360.INPUT_VIRTUAL_TOUR)" (change)="onSentUploadedFile(
              inputVirtualTour.files,
              MISSION_CONSTANTS.VIRTUAL_TOUR,
              MISSION_CONSTANTS.VIRTUAL_TOUR
            )" id="inputVirtualTour" type="file" multiple webkitdirectory mozdirectory />
            <div class="drag-and-drop-text-container">
              <img [src]="'/images/cloud_upload.svg' | assetUrl" alt="cloud_upload"
                [ngClass]="!isValidVirtualTour?'not-valid-file':''">
              <p><span class="click-to-upload">Click to upload folder</span><span>&nbsp; or &nbsp;drag and drop</span></p>
              @if(!isValidVirtualTour){
              <p>Wrong file format. Please upload file that is</p>
              }
              <p>Supported formats: Accept all file format</p>
            </div>
          </div>
          @if(virtualTourUploadedFiles && isValidVirtualTour){
          <div class="upload-preview-container">
            <app-upload-folder-preview [disableFileSelect]="true" [uploadCategory]="{parentType:MISSION_CONSTANTS.VIRTUAL_TOUR,childType:MISSION_CONSTANTS.VIRTUAL_TOUR}"></app-upload-folder-preview>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
</div>
