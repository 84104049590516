<div class="delete-modal-container">
  <div class="delete-modal-head">
    <h3>DELETE - {{FileName}} {{isMoreThenOneFile()? FOLDER_FILE.FILES:''}}</h3>
    <img [src]="'/images/close.svg' | assetUrl" alt="close" (click)="closeModal()">
  </div>
  <hr>
  <div class="delete-modal-body">
    <div class="delete-modal-content">
      <img [src]="'/images/warning.png' | assetUrl" alt="warning">
      <p>Are you sure you want to delete {{isFolder ? FOLDER_FILE.FOLDER : isMoreThenOneFile() ? FOLDER_FILE.FILES : FOLDER_FILE.FILE}} ?</p>
      @if(isFolder){
      <p class="child-item-delete">This action will also delete its child items.</p>
      }
    </div>
    <div class="delete-modal-button">
      <button class="btn-cancel" (click)="closeModal()">Cancel</button>
      <button class="btn-delete" (click)="onConformModal()">Delete</button>
    </div>
  </div>
</div>
