import { Pipe, PipeTransform } from '@angular/core';
import { FilesProgressList } from '../../interfaces/file-upload-dataset.interfaces';

@Pipe({
  name: 'uploadProgress',
})
export class UploadProgressPipe implements PipeTransform {

  transform(filesProgressList:FilesProgressList[]): number {
    if (!filesProgressList) {
      return 0;
    }
    const totalItems = filesProgressList.length;
    let totalProgress = 0;
    filesProgressList.forEach((item) => totalProgress += item.progress);
    const overallProgress = totalItems === 0 ? 0 : totalProgress / totalItems;
    return Math.floor(overallProgress);
  }

}
