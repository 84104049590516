<div class="upload-container" [ngClass]="headExpand == UPLOAD_DATASET_HEADER.ADDITIONAL_IMAGES ? 'show-upload-body ' : ''">
  <div class="upload-head" (click)="onHeadExpand(headExpand == UPLOAD_DATASET_HEADER.ADDITIONAL_IMAGES?'':UPLOAD_DATASET_HEADER.ADDITIONAL_IMAGES)">
    <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
    <p>{{UPLOAD_DATASET_HEADER.ADDITIONAL_IMAGES}}</p>
    @if(additionalImageUploadedFiles){
      <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
    }
  </div>
  <div class="upload-body-container">
    <div class="upload-body-dropdown">
      <div class="upload-body">
        <input #inputAdditionalImage title=" " (click)="inputAdditionalImage.value = ''" dropStyle [parentFileType]="MISSION_CONSTANTS.ADDITIONAL_IMAGES"
          (isValidFile)="checkFileValid($event)" (change)="onSentUploadedFile(
                inputAdditionalImage.files,
                MISSION_CONSTANTS.ADDITIONAL_IMAGES,
                MISSION_CONSTANTS.ADDITIONAL_IMAGES
              )" id="inputAdditionalImage" type="file" multiple webkitdirectory mozdirectory />
        <div class="drag-and-drop-text-container">
          <img [src]="'/images/cloud_upload.svg' | assetUrl" alt="upload_icon" [ngClass]="!isValid?'not-valid-file':''">
          <p><span class="click-to-upload">Click to upload folder</span><span>&nbsp; or &nbsp;drag and drop</span></p>
          @if(!isValid){
          <p>Wrong file format. Please upload file that is</p>
          }
          <p>Supported formats: .JPG, .jpg, .jpeg</p>
        </div>
      </div>
      @if(additionalImageUploadedFiles && isValid){
      <div class="upload-preview-container">
        <app-upload-folder-preview [uploadCategory]="{parentType:MISSION_CONSTANTS.ADDITIONAL_IMAGES,childType:MISSION_CONSTANTS.ADDITIONAL_IMAGES}"></app-upload-folder-preview>
      </div>
      }
    </div>
  </div>
</div>
