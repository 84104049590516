import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICreateMissionGetStatus, ICreateMissionObjective, ICreateMissionProject, ICreateMissionSiteData, ICreateMissionStatusAndProject, MissionProject } from '../models/create-mission-dataset.model';
import { Observable, Subscription, interval } from 'rxjs';
import { MissionSharedService } from 'projects/data-upload/src/app/services/mission-shared.service';
import { CreateMissionService } from 'projects/data-upload/src/app/services/create-mission.service';
import {
  CONSTANTS_MISSION,
  MISSION_DATA_TYPES,
  MISSION_STATUS,
  NOTIFY_MSG,
} from 'projects/data-upload/src/app/constants/create-mission.const';
import { IWorkflowProgress } from 'projects/data-upload/src/app/interfaces/create-mission-workflow.interface';

@Injectable({
  providedIn: 'root'
})

export class SharedFunctionsService {
  UploadDatasetForm!: FormGroup;
  latestMiscData!: {preSignedUrl: string, tags: string[]}[];
  latestF01Data!: {preSignedUrl: string, tags: string[]}[];
  showUploadSection: boolean = false;
  f01FileName!: string;
  miscFilesName: string[] = [];
  selectedMiscFile: string | null = null;
  selectedF01File: string | null = null;
  siteId!: string;
  selectedProjectId!: string;
  toasterClass: string = '';
  toasterMsg: string = '';
  isShowToaster: boolean = false;
  MISSION_CONSTANTS = CONSTANTS_MISSION;
  showReplaceModal: boolean = false;
  continueUploading: boolean = true;
  latestMission!: {
    assetIds: string[]
    id: string
    projectId: string
    recordType: string
    siteId: string
    status: string
    workspaceId: string
  };
  uploadData: {
    files: File[] | FileList,
    fileType: string,
    parentFileType: string,
  } = {
    files: [],
    fileType: '',
    parentFileType: ''
  };

  constructor(
    private missionSharedService: MissionSharedService,
    private createMissionService: CreateMissionService,
    ) {}

  async uploadFiles(files: File[] | FileList | null, fileType: string, parentFileType: string, type: 'file' | 'folder') {
    if(!files) return;
    this.createMissionService.getCurrentMissionStatus(this.siteId, MISSION_STATUS.COMPLETED, this.selectedProjectId).
      subscribe(async (response: ICreateMissionGetStatus) => {
        this.uploadData.files = files;
        this.uploadData.fileType = fileType;
        this.uploadData.parentFileType = parentFileType;
        this.latestMission = response.records[0];
        if(this.latestMission && this.latestMission.status === MISSION_STATUS.COMPLETED) {
          let isValidFiles = this.missionSharedService.isValidFileTypes(files, parentFileType);
          if(isValidFiles) {
            if(type === 'file') {
              if(this.latestF01Data && this.latestF01Data.length) {
                this.continueUploading = false;
                this.showReplaceModal = true;
              }
            } else {
              if(this.miscFilesName.some((miscFileName) => {
                return files[0].webkitRelativePath.split('/')[0] === miscFileName;
              })){
                this.continueUploading = false;
                this.showReplaceModal = true;
              }
            }
            if(this.continueUploading) {

              this.uploadS3(this.uploadData.files, this.uploadData.fileType, this.uploadData.parentFileType );
            }
          } else {
            this.showToasterMsg({
              toasterClass: NOTIFY_MSG.ERROR_CLASS,
              toasterMsg: NOTIFY_MSG.INVALID_FILE_FORMAT,
            })
          }
        } else {
          this.showToasterMsg({
            toasterClass: NOTIFY_MSG.ERROR_CLASS,
            toasterMsg: NOTIFY_MSG.NO_MISSION_COMPLETED
          })
        }
      })
  }

  async onDownloadF01() {
    await this.missionSharedService.downloadF01(this.latestF01Data);
  }

  async onDownloadMisc() {
    const filteredMiscData = this.latestMiscData.filter(({tags}: {tags: string[]}) => {
      return tags[tags.length - 3] === this.selectedMiscFile;
    })
    await this.missionSharedService.downloadMiscZip(filteredMiscData);
  }

  async getF01MiscInfo (projectId: string) {
    const projectInfo = await this.missionSharedService.getProjectInfo(projectId);
    const f01Records = await projectInfo?.f01;
    const miscRecords = await projectInfo?.misc;
    const f01FilesArray = f01Records?.records.map(({ storage, tags } :
      { storage: { files: { preSignedUrl: string }[] }, tags: string[] }) => {
        const preSignedUrl = storage.files[0].preSignedUrl;
        return { preSignedUrl, tags };
      }
    );
    const miscFilesArray = miscRecords?.records.map(({ storage, tags } :
      { storage: { files: { preSignedUrl: string }[] }, tags: string[] }) => {
        const preSignedUrl = storage.files[0].preSignedUrl;
        return { preSignedUrl, tags };
      }
    );
    const maxStampsObj: {[key: string]: string} = {}
    this.latestMiscData = miscFilesArray.filter(({ tags }: { tags: string[]}) => {
      if(!maxStampsObj.hasOwnProperty(tags[tags.length - 3])) {
        maxStampsObj[tags[tags.length - 3]] = tags[tags.length - 2];
        return true;
      } else {
        if (Number(maxStampsObj[tags[tags.length - 3]]) > Number(tags[tags.length - 2])) return false;
        maxStampsObj[tags[tags.length - 3]] = tags[tags.length - 2];
        return true;
      }
    })
    const maxTimeStampF01 = Math.max(...f01FilesArray.map(({tags}: {tags: string[]}) => Number(tags[tags.length - 2])));
    this.latestF01Data = f01FilesArray.filter(({ tags }: { tags: string[] }) => Number(tags[tags.length - 2]) === maxTimeStampF01);
    this.f01FileName = this.latestF01Data && this.latestF01Data.length ?
    this.latestF01Data[0].tags[this.latestF01Data[0].tags.length - 3] : '';
    this.miscFilesName = this.latestMiscData && this.latestMiscData.length ?
    this.latestMiscData.map((miscData: any) => miscData.tags[miscData.tags.length - 3]) : [];
    this.miscFilesName = [...new Set(this.miscFilesName)];
  }

  async uploadS3 (files: File[] | FileList, fileType: string, parentFileType: string) {
    this.missionSharedService.updateUploadingFilesDataset(files, fileType, parentFileType);
    this.missionSharedService.populateFilesProgressList(Array.from(files),fileType,parentFileType);
    await this.missionSharedService.uploadFilesToS3(Array.from(files), fileType, parentFileType, this.latestMission.id, this.latestMission.projectId, this.latestMission.siteId);
    const checkFileUploadObservable = this.checkAllFilesUploaded(fileType, parentFileType);
    checkFileUploadObservable.subscribe((allFilesUploadStatus) => {
      if(allFilesUploadStatus){
        this.getF01MiscInfo(this.selectedProjectId!);
      }
    })
  }

  selectMiscFolder(miscFileName: string) {
    this.selectedMiscFile = miscFileName;
  }

  selectF01Folder(f01FileName: string) {
    this.selectedF01File = f01FileName;
  }

  private showToasterMsg ({ toasterClass, toasterMsg, timer = 5000 }: { toasterClass: string, toasterMsg: string, timer?: number }) {
    this.isShowToaster = true;
    this.toasterClass = toasterClass;
    this.toasterMsg = toasterMsg;
    setTimeout(() => {
      this.isShowToaster = false;
    }, timer);
  }

  private checkAllFilesUploaded (fileType: string, parentFileType: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      const intervalSubscription = interval(500).subscribe(() => {
        const isUploaded = this.missionSharedService.isAllFilesUploaded(fileType, parentFileType);
        if(isUploaded) {
          observer.next(true);
          observer.complete();
          intervalSubscription.unsubscribe();
        } else {
          observer.next(false);
        }
      })
    })
  }

  resetUploadingFiles() {
    this.missionSharedService.resetFiles()
  }
 }
