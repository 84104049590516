import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import {
  MISSION_STATUS,
  WORKFLOW_STATUS,
  WORKFLOW_STEPS,
} from '../constants/create-mission.const';
import { CreateMissionWorkflowService } from './create-mission-workflow.service';
import { CreateMissionService } from './create-mission.service';
import { Subject } from 'rxjs';
import { IWorkflowProgress, IWorkflowResponse } from '../interfaces/create-mission-workflow.interface';
import { MissionSharedService } from 'projects/data-upload/src/app/services/mission-shared.service'
@Injectable({
  providedIn: 'root',
})
export class WorkflowSharedService {
  terminateWorkFlowStatusApi: boolean = false;
  isWorkFlowStarted: boolean = false;
  runningWorkFlowId: string = '';
  workflowStepName: Array<string> = [
    'Resource Ingestion',
    'Digital Twin Optimization',
    'AI Telco Equip 3DDetection',
    'Manual Review AI Telco Equip 3DDetection',
    'AI 3D To 2DAnnotation',
    'Manual Review Before AMS Publish',
  ];
  private eventSubject = new Subject<void>();
  workflowProgressArray: IWorkflowProgress[] = [];
  workflowProgressArraySubject = new BehaviorSubject<IWorkflowProgress[]>([]);
  workflowProgressData$ = new BehaviorSubject<IWorkflowResponse | null>(null);
  workFlowStatus: string = '';

  constructor(
    private missionWorkflowService: CreateMissionWorkflowService,
    private createMissionService: CreateMissionService,
    private missionSharedService: MissionSharedService
  ) { }

  createMissionParams = this.missionSharedService.createMissionParams_signal;

  terminateWorkFlowById(workflowId: string) {
    const runningWorkflow = this.workflowProgressArray.find(
      ({ runningWorkFlowId }) => runningWorkFlowId === workflowId
    );
    if (runningWorkflow) runningWorkflow.terminateWorkFlowStatusApi = true;
  }

  updateWorkFlowStatusById({
    status,
    workflowId,
  }: {
    status: boolean;
    workflowId: string;
  }) {
    const runningWorkflow = this.workflowProgressArray.find(
      ({ runningWorkFlowId }) => runningWorkFlowId === workflowId
    );
    if (runningWorkflow) runningWorkflow.isWorkFlowStarted = status;
  }

  setWorkflowById({ id, siteName }: { id: string; siteName?: string | null }) {
    const { missionId } = this.createMissionParams();
    this.workflowProgressArray.push({
      terminateWorkFlowStatusApi: false,
      isWorkFlowStarted: true,
      runningWorkFlowId: id,
      workflowProgress: 0,
      workflowStep: '',
      missionId,
      siteName: siteName ?? null,
    });
    this.updateWorkflow();
  }

  async abortWorkFlowById(workflowId: string) {
    const runningWorkflow = this.workflowProgressArray.find(
      ({ runningWorkFlowId }) => runningWorkFlowId === workflowId
    );
    if (runningWorkflow) {
      runningWorkflow.isWorkFlowStarted = false;
      runningWorkflow.terminateWorkFlowStatusApi = true;
      const missionStatus = {
        status: MISSION_STATUS.PENDING,
      };
      await lastValueFrom(
        this.createMissionService.updateMissionStatus(
          runningWorkflow.missionId,
          missionStatus
        )
      );
      await lastValueFrom(
        this.missionWorkflowService.updateWorkflowStatus(
          runningWorkflow.runningWorkFlowId,
          WORKFLOW_STATUS.ABORTED
        )
      );
      this.workflowProgressArraySubject.next([]);
    }
  }

  async restartWorkflowById(workflowId: string) {
    const runningWorkflow = this.workflowProgressArray.find(
      ({ runningWorkFlowId }) => runningWorkFlowId === workflowId
    );
    if (runningWorkflow) {
      runningWorkflow.isWorkFlowStarted = false;
      await lastValueFrom(
        this.missionWorkflowService.updateWorkflowStatus(
          runningWorkflow.runningWorkFlowId,
          WORKFLOW_STATUS.QUEUED
        )
      );
      this.emitEvent();
      runningWorkflow.isWorkFlowStarted = true;
    }
  }

  emitEvent() {
    this.eventSubject.next();
  }

  getEventObservable() {
    return this.eventSubject.asObservable();
  }

  async getWorkflowProgressById(workflowId: string) {
    let workflowStep: any = undefined;
    try {
      const runningWorkflow = this.workflowProgressArray.find(
        ({ runningWorkFlowId }) => runningWorkFlowId === workflowId
      );
      if (runningWorkflow) {
        runningWorkflow.workflowProgress = 0;
        let workflowStepCounter = 0;
        while (!runningWorkflow.terminateWorkFlowStatusApi) {
          await (async () => {
            this.updateWorkflow();
            const workflowRunResp: any = await lastValueFrom(
              this.missionWorkflowService.getWorkflowRun(workflowId)
            );
            this.workflowProgressData$.next(workflowRunResp)
            if (workflowRunResp.status !== WORKFLOW_STATUS.FAILED) {
              this.updateWorkFlowStatusById({
                status: true,
                workflowId,
              });

              if (!workflowStep)
                // Assign step detail
                workflowStep = workflowRunResp.workflowSteps.find(
                  (step: any) =>
                    step.status === WORKFLOW_STATUS.RUNNING &&
                    step.stepType === 'auto'
                );
              else {
                // Get updated progress for the same step
                workflowStep = workflowRunResp.workflowSteps.find(
                  (step: any) =>
                    step.stepName === workflowStep!.stepName &&
                    step.stepType === 'auto'
                );
              }

              const anyFailed = workflowRunResp.workflowSteps.find(
                (step: any) => step.status === WORKFLOW_STATUS.FAILED
              );

              if (!workflowStep) {
                return;
              } else if (anyFailed) {
                runningWorkflow.isWorkFlowStarted = false;
                runningWorkflow.terminateWorkFlowStatusApi = true;
                this.workflowProgressArraySubject.next(this.workflowProgressArray);
              }

              // const workflowStep = workflowRunResp.workflowSteps[workflowStepCounter];
              const stepName = workflowStep.stepName;
              const parentStepName = workflowStep.parentStepNames[0];
              this.workFlowStatus = workflowStep.status;
              const progress = workflowStep.progress;

              const progressTracking = async () => {
                if (progress) {
                  runningWorkflow.workflowStep = progress.detail;
                  runningWorkflow.workflowProgress =
                    progress.percentageCompleted * 100;
                } else if (stepName === WORKFLOW_STEPS.RESOURCE_INGESTION) {
                  runningWorkflow.workflowStep =
                    stepName + ' is about to start.';
                  runningWorkflow.workflowProgress = 0;
                } else {
                  runningWorkflow.workflowStep =
                    stepName + ' is in ' + this.workFlowStatus + ' state.';
                  runningWorkflow.workflowProgress = 0;
                }

                if (this.workFlowStatus === WORKFLOW_STATUS.COMPLETED) {
                  workflowStepCounter += 1;

                  // Find next child step if current step completed
                  workflowStep = workflowRunResp.workflowSteps.find(
                    (step: any) =>
                      step.parentStepNames[0] === workflowStep!.stepName &&
                      step.stepType === 'auto'
                  );

                  if (!workflowStep) {
                    // Find next step if current step completed
                    workflowStep = workflowRunResp.workflowSteps.find(
                      (step: any) =>
                        step.status === WORKFLOW_STATUS.RUNNING &&
                        step.stepType === 'auto'
                    );

                    if (!workflowStep) {
                      runningWorkflow.isWorkFlowStarted = false;
                      runningWorkflow.terminateWorkFlowStatusApi = true;
                      runningWorkflow.workflowStep =
                        'Completed! All steps are done.';
                    }
                  }
                }

                await this.callApiMultiple(5000)
              };

              if (
                stepName === WORKFLOW_STEPS.DIGITAL_TWIN_OPT &&
                parentStepName === WORKFLOW_STEPS.RESOURCE_INGESTION
              ) {
                if (
                  this.workFlowStatus === WORKFLOW_STATUS.COMPLETED ||
                  this.workFlowStatus !== WORKFLOW_STATUS.FAILED
                ) {
                  await progressTracking();
                }
                return;
              }
              if (
                parentStepName === WORKFLOW_STEPS.RESOURCE_INGESTION &&
                this.workFlowStatus !== WORKFLOW_STATUS.FAILED
              ) {
                runningWorkflow.isWorkFlowStarted = true;
                await progressTracking();
                return;
              }
              if (
                stepName === WORKFLOW_STEPS.RESOURCE_INGESTION &&
                !parentStepName
              ) {
                await progressTracking();
              }

            } else {
              setTimeout(() => {
                this.updateWorkFlowStatusById({
                  status: false,
                  workflowId,
                });
                this.terminateWorkFlowById(workflowId);
                runningWorkflow.workflowProgress = 0;
              }, 1000);
            }
          })();
        }
      }
    } catch (error) { }
  }

  hideWorkflowById(workflowId: string) {
    this.workflowProgressArray = this.workflowProgressArray.filter(
      ({ runningWorkFlowId }) => runningWorkFlowId !== workflowId
    );
    this.updateWorkflow();
  }

  updateWorkflow() {
    this.workflowProgressArraySubject.next(this.workflowProgressArray);
  }

  callApiMultiple(milliseconds: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }

  getWorkFlowStatus() {
    return this.workFlowStatus;
  }
}
