import { Component, OnInit, inject } from '@angular/core';
import { MissionSharedService } from './services/mission-shared.service';
import { WorkflowSharedService } from './services/workflow-shared.service';
import {
  EventTypes,
  NotificationService,
} from './services/notification.service';
import { NOTIFY_MSG } from './constants/create-mission.const';
import { IMissionDetails, IWorkflowProgress } from './interfaces/create-mission-workflow.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-data-upload',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  workflowSharedService = inject(WorkflowSharedService);
  missionSharedService = inject(MissionSharedService);
  notificationService = inject(NotificationService);
  router = inject(Router);

  title = 'fe-create-mission';
  showMissionCreation: boolean = false;
  showSiteDetails: boolean = false;
  isWorkflowStart: boolean = false;
  createMissionParams = this.missionSharedService.createMissionParams_signal;
  WorkflowProgresses: IWorkflowProgress[] = [];
  missionDetails: IMissionDetails | null;
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (this.showMissionCreation) {
        this.showMissionCreation = false;
      }
    });
    this.workflowSharedService.workflowProgressArraySubject.subscribe(
      (updatedWorkflowProgresses) => {
        this.WorkflowProgresses = structuredClone(updatedWorkflowProgresses);

        if (this.WorkflowProgresses.length) {
          const idx = this.WorkflowProgresses.length - 1;
          if (
            this.WorkflowProgresses[idx].isWorkFlowStarted &&
            !this.isWorkflowStart
          ) {
            this.isWorkflowStart =
              this.WorkflowProgresses[idx].isWorkFlowStarted;
            this.notificationService.showToast({
              type: EventTypes.success,
              message: NOTIFY_MSG.WORKFLOW_START,
            });
          }

          if (this.WorkflowProgresses[idx].terminateWorkFlowStatusApi) {
            this.notificationService.showToast({
              type: EventTypes.error,
              message: NOTIFY_MSG.WORKFLOW_FAILED,
            });
            this.isWorkflowStart =
              this.WorkflowProgresses[idx].isWorkFlowStarted;
          }
        }
      }
    );
    this.onClickCreateMissionButton();
  }

  closeMissionCreation(value: boolean) {
    this.showMissionCreation = value;
  }

  onClickCreateMissionButton() {
    window.addEventListener('dronos:utm:missionFloating', (event: any) => {
      if (event.detail.hasOwnProperty('gridFlowFlag')) {
        if (event.detail.missionDetails) {
          const { _id, siteId, projectId, assetIds, trackingId, name, objective } = event.detail.missionDetails
          this.missionDetails = {
            _id,
            siteId,
            projectId,
            assetIds,
            trackingId,
            name,
            objective,
          }
        } else {
          this.missionDetails = null
        }
        if (!this.showMissionCreation) {
          this.showMissionCreation = true;
        }
        this.showSiteDetails = false;
      } else if (event.detail.hasOwnProperty('site_id')) {
        this.showSiteDetails = true;
        this.showMissionCreation = false;
        this.createMissionParams.update(
          (value) => (value = { ...value, siteId: event.detail.site_id })
        );
      }
    });
  }
}
