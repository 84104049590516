<div class="upload-progress-container" *ngIf="isProgressBarVisible">
  <div class="upload-progress-wrapper">
    <!-- <div class="progress-header d-flex align-items-center justify-content-between">
      <span class="extended-text"></span>
      <span class="percent">{{progressPercentage}}%</span>
    </div> -->
    <div class="progress-content">
      <div class="cust-progress-bar" [style]="'--progressbar-width : ' + progressPercentage + '%'"></div>
    </div>
    <div class="upload-header" *ngIf="showProgressBar()">
      <h3 class="title">{{ totalFiles - numberOfFilesUploading }} / {{ totalFiles }} files uploading</h3>
      <button class="btn-hide" (click)="toggleUploadView()">
        {{ showFullUploadDetails ? 'Hide' : 'Show' }}
        <img [src]="icon.EXPAND_ICON | assetUrl" alt="close" class="expand_icon"  [style.transform]="showFullUploadDetails ? '' : 'rotate(90deg)'"/>
      </button>
    </div>
    <div class="progress-container">
      <div class="progress-holder">
        <div class="progress-bar show-progress" role="progressbar" [style.width.%]="progressPercentage"
          aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
          <img [src]="icon.PROGRESS_BG | assetUrl" alt="progress_bg" class="progress-bar-image" />
        </div>
      </div>
      <div class="progress-bar-pointer-wrapper">
        <img [src]="icon.PROGRESS_POINTER | assetUrl" alt="progress_pointer" class="progress-bar-pointer"
          [style.left]="calculatePointerPosition()" />
      </div>
    </div>
    <div class="progress-percentage">
      <div class="content">
        <p>
          <span [ngClass]="goodConnection ? 'connection' : 'bad-connection'">{{ connection_status }}</span>
          <span>{{ calculateTimeLeft() }}</span>
        </p>
      </div>
      <p>{{ progressPercentage }}%</p>
    </div>
    <div class="upload-details-full-view">
      <div class="upload-progress-wrapper" *ngIf="showFullUploadDetails" isProgressBarVisible>
        <div class="d-flex flex-column align-items-start progress-wrap">
          <ng-container>
          <ng-container *ngFor="let item of uploadingFiles.filesProgressList; let idx = index">
            <div class="upload-heading" key="fIdx">
              <h3 class="count">{{ parentFolderName }}/{{ item.name }}</h3>
              <div class="d-flex align-items-center retry-section">
                <img *ngIf="showTimeWarning(item?.startTime, item.progress)" [src]="icon.RESTART_ICON | assetUrl"
                     class="retry-icon" alt="retry" (click)="onFileRetry(item)" />
                <div *ngIf="item.progress < 100 && item.progress != -1" class="progress-bar"
                     [style.background]="getBackgroundStyle(item)"></div>
                <img *ngIf="item.progress == -1" [src]="'/images/error_sign.png' | assetUrl" class="retry-icon"
                     alt="error" />
                <div class="cover">
                  <div class="file-add">
                    <span *ngIf="item.progress == -5">Retry <img [src]="'/images/error_sign.png' | assetUrl" class="retry-icon" alt="error" /></span>
                    <img *ngIf="item.progress == 100" [src]="'/images/tick.svg' | assetUrl" class="retry-icon" alt="successs" />
                    <span *ngIf="(item.progress < 100 || item.progress === -1) && !item.showCancelIcon"
                          (mouseover)="item.showCancelIcon = true"
                          (mouseout)="item.showCancelIcon = false"
                    >
                      <img *ngIf="item.showCancelIcon"
                           [src]="'/images/cancel.svg' | assetUrl"
                           class="retry-icon"
                           alt="cancel"
                           (click)="deleteFileInProgress(item)"
                      />
                    </span>
                  </div>
                  
                  <div class="file-remove" *ngIf="item.progress == 100">
                    <img [src]="'/images/cross.svg' | assetUrl" class="retry-icon"
                         alt="remove" (click)="deleteUploadedFile(item)" />
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>