<div [ngClass]="{
  'd-none': isHideMissionSection,
  'upload-dataset-wrapper': !isHideMissionSection
}">
  <h3 class="title">Uploading new dataset</h3>
  <div class="progress-holder">
    <ul class="progress-list">
      <li class="complete"><span class="bullet-step">Mission Detail</span></li>
      <li class="active"><span class="bullet-step">Upload Dataset</span></li>
    </ul>
  </div>
  <div class="form-holder">
    <div class="drag-drop" *ngIf="isFolderUploaded">
      <input type="file" #fileDrop id="fileDrop" (change)="uploadFilesVisual(fileDrop.files,'visual')" webkitdirectory
        mozdirectory multiple />
      <span>
        Drop files here or
        <a href="javascript:void(0);" class="choose-file-text"> Choose file</a>
      </span>
      <span>Supported format: .jpg, .jpeg, .png</span>
    </div>
    <div *ngIf="openSubFolders" class="uploading-folders">
      <div class="drag-drop" *ngIf="isFolderUploaded">
        <input type="file" #fileDrop id="fileDrop" (change)="uploadFilesVisual(fileDrop.files,'visual')" webkitdirectory
          mozdirectory multiple />
        <span>
          Drop files here or
          <a href="javascript:void(0);" class="choose-file-text"> Choose file</a>
        </span>
        <span>Supported format: .jpg, .jpeg, .png</span>
      </div>
      <span class="uploading-folder-heading">Preview</span>
      <div class="uploading-folder-text">
        <span>Upload Queue</span>
        <span>Existing asset</span>
      </div>
      <button class="btn-dropdown" (click)="toggleButton()">
        <img width="18" [src]="
          toggleFolder
            ? (icons.ARROW_DOWN | assetUrl)
            : (icons.ARROW_UP | assetUrl)
        " alt="arrow down" />
        <img [src]="icons.folder | assetUrl" alt="folder image" />
        <span class="folder-text">{{ gridMissionSharedService.uploadFolderVisual.parentFolderName }}</span>
      </button>
      <div *ngIf="toggleFolder">
        <div *ngFor="let folder of gridMissionSharedService.uploadFolderVisual.subFolder ;let i = index" class="sub-folder sub_folder_new">
          <img [src]="icons.folder | assetUrl" alt="folder image" />
          <p class="folder_name">{{ folder }}</p>
          <p class="dotted-line"></p>
          <div class="search_and_match">
            <!-- <div *ngIf="this.matchingDataList.includes(folder.toString().split('.')[0])"
              style="display: flex; gap: 10px;justify-content: space-between;">
              <p class="folder_name">{{ folder.toString().split('.')[0] }}</p>
              <p style="color:#20c997">matched</p>
            *ngIf="!matchingDataList.includes(folder.toString().split('.')[0])" Picked From the Below div
            </div> -->
            <div class="search_container">
              <span [id]="i" style="color: #fff">
                {{ i == selectedindex ? selectedSearchResult : '' }}
              </span> 
              <div>
                <input #search type="text" placeholder="Search assets" (keyup)="searchAsset(search.value, i)" >
                <i class="bi bi-x-circle"></i>
                <ng-container *ngIf="search.value || i == selectedindex">
                  <ul>
                    <li *ngFor="let result of searchResults" (click)="selectResult(result,i, folder)">
                      {{ result?.externalClientAssetId }}
                    </li>
                  </ul>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-holder">
    <button type="button" class="btn btn-outline" (click)="back()">Back</button>
    <button type="submit" class="btn btn-default"  [disabled]="!uploadDataList || uploadDataList.length === 0" (click)="uploadFile()">Upload</button>
  </div>
  <ng-template [ngIf]="isShowToaster">
    <app-utm-toaster-custom [toasterClass]="toasterClass" [toasterMsg]="toasterMsg"></app-utm-toaster-custom>
  </ng-template>
</div>