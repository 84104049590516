import { Component, OnInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { CONNECTION_STATUS } from 'projects/data-upload/src/app/constants/create-mission.const';
import {ICONS} from  'projects/data-upload/src/app/constants/progress.const';
import { GridMissionSharedService } from 'projects/data-upload/src/app/services/grid-mission-shared.service';
import { NOTIFY_MSG } from 'projects/data-upload/src/app/constants/create-mission.const';
import { NgZone } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { MissionSharedService } from 'projects/data-upload/src/app/services/mission-shared.service'
interface FileProgressItem {
  name: string;
  signalId: string;
  progress: number;
}
@Component({
  selector: 'app-grid-files-uploading-progress',
  templateUrl: './grid-files-uploading-progress.component.html',
  styleUrls: ['./grid-files-uploading-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridFilesUploadingProgressComponent {
  icon = ICONS;
  goodConnection = true;
  connection_status = CONNECTION_STATUS.GOOD_CONN;
  progressPercentage = 0;
  isShowToaster: boolean = true;
  toasterClass: string = '';
  toasterMsg: string = '';
  Object = Object
  uploadingFiles!: any
  parentFolderName = ''
  // icon = ICONS;
  numberOfFilesUploading: number = 0;
  totalFiles: number = 0;
  startTime: number = 0;
  showFullUploadDetails:  boolean = false;
  fileCount: number = 0;
  createMissionParams = this.missionSharedService.createMissionParams_signal
  isProgressBarVisible = false;

  constructor(public gridMissionSharedService: GridMissionSharedService, public missionSharedService: MissionSharedService, private cdr: ChangeDetectorRef, private zone: NgZone) {
    this.totalFiles = 0;
    this.fileCount = 0;
    this.gridMissionSharedService._simulatedGridProgressFilesList.subscribe((value) => {
      this.uploadingFiles = value;
      this.parentFolderName = value.parentFolderName;
      this.totalFiles = this.getTotalFiles(value); // Set the initial totalFiles based on the received files
      this.calculateOverallProgress();
    });

    this.gridMissionSharedService.isProgressBarVisible.subscribe((value) => {
      this.isProgressBarVisible = value;
      this.cdr.detectChanges();
    });
  }
  ngOnInit() {}
  showToaster(message: string, toasterClass: string) {
    this.isShowToaster = true;
    this.toasterClass = toasterClass;
    this.toasterMsg = message;
    this.cdr.detectChanges();
  }

  deleteUploadedFile(file: FileProgressItem) {
    const confirmDelete = window.confirm(`Are you sure you want to cancel ${file.name}?`);
    if (confirmDelete) {

      if (file.progress === 100) {
        this.gridMissionSharedService.deleteUploadedFile(file.name);
      } else {

        this.gridMissionSharedService.abortS3Upload(file.signalId);

        this.uploadingFiles.filesProgressList = this.uploadingFiles.filesProgressList.map(
          (item: FileProgressItem) => {
            if (item.name === file.name) {
              return { ...item, progress: -2 };
            }
            return item;
          }
        );
      }

      this.uploadingFiles.filesProgressList = this.uploadingFiles.filesProgressList.filter(
        (item: FileProgressItem) => item.name !== file.name
      );

      this.calculateOverallProgress();
    }
  }

  deleteFileInProgress(file: FileProgressItem) {
    const confirmDelete = window.confirm(`Are you sure you want to delete ${file.name}?`);

    if (confirmDelete) {
      this.gridMissionSharedService.abortS3Upload(file.signalId);

      this.uploadingFiles.filesProgressList = this.uploadingFiles.filesProgressList.map(
        (item: FileProgressItem) => {
          if (item.name === file.name) {
            return { ...item, progress: -2 };
          }
          return item;
        }
      );

      this.uploadingFiles.filesProgressList = this.uploadingFiles.filesProgressList.filter(
        (item: FileProgressItem) => item.name !== file.name
      );

      this.calculateOverallProgress();
    }
  }

  checkUploadCompletion() {

    if (this.progressPercentage === 100 && !this.showFullUploadDetails) {
      this.zone.run(() => {
        this.showToaster(NOTIFY_MSG.DATA_READY, NOTIFY_MSG.SUCCESS_CLASS);
        this.showFullUploadDetails = false;
      });
    }
  }
  calculateOverallProgress() {
    this.totalFiles = this.getTotalFiles(this.uploadingFiles);
    let filesInProgressCount = 0;
        let totalProgress = 0;

    for (const file of this.uploadingFiles.filesProgressList) {
      if (file && 'progress' in file && typeof file.progress === 'number') {
        totalProgress += file.progress;

        if (file.progress < 100) {
          filesInProgressCount++;
        }
      }
    }

    this.numberOfFilesUploading = filesInProgressCount;
    this.progressPercentage = this.totalFiles > 0 ? Math.round(totalProgress / this.totalFiles) : 0;

    if (this.startTime === 0 && filesInProgressCount > 0) {
      this.startTime = Date.now();
    }

    this.cdr.detectChanges();
    this.checkUploadCompletion();
  }


  getTotalFiles(data: any): number {
    if (data && data.filesProgressList) {
      return data.filesProgressList.length;
    }
    return 0;
  }

  getCompletedFiles(data: any): number {
    const completedFiles = Object.values(data).filter((item: any) => {
      const isCompleted = item && item.progress !== undefined && typeof item.progress === 'number' && item.progress >= 100;
      return data.filesProgressList ? data.filesProgressList.length : 0;

    });

    return data.filesProgressList ? data.filesProgressList.length : 0;
  }
  calculateTimeLeft(): string {
    if (this.progressPercentage === 0 || this.startTime === 0) {
      return '0 Min left';
    }

    const elapsedTimeSeconds = (Date.now() - this.startTime) / 1000;
    const estimatedTotalSeconds = (100 / this.progressPercentage) * elapsedTimeSeconds;

    const remainingSeconds = Math.max(0, estimatedTotalSeconds - elapsedTimeSeconds);
    const minutesLeft = Math.ceil(remainingSeconds / 60);

    return `${minutesLeft} min left`;
  }


  calculatePointerPosition(): string {
    const containerWidth = 394;
    const pointerWidth = 10;
    const leftPosition = (this.progressPercentage / 100) * (containerWidth - pointerWidth);
    return `${leftPosition}px`;
  }
  getBackgroundStyle(item:any) {
    return `radial-gradient(closest-side, rgba(0, 0, 0, 0.4) ${item.progress}%, transparent 80% 100%), conic-gradient(#00f0ff ${item.progress}%, rgba(92, 217, 239, 0.1) 0)`;
  }

  showTimeWarning(startDate:any, progress:number) {
    if(progress == -1) {
      return true
    }
    const currentDate = Date.now()
    const timeDifferenceMilliseconds = Math.abs(currentDate - startDate);
    const timeDifferenceMinutes = Math.floor(timeDifferenceMilliseconds / (1000 * 60));
    return progress > 0 && progress < 100 && timeDifferenceMinutes > 1
  }

  onFileRetry(file: any) {
    const fileName = this.gridMissionSharedService.getFileName(file?.file?.webkitRelativePath);
    const { missionId } = this.createMissionParams()
    this.gridMissionSharedService.updateGridFilesProgressList(fileName, 3);
    this.gridMissionSharedService.uploadGRIDAssetsToS3([file], missionId);
  }

  abortUploading(signalId: string){
    this.gridMissionSharedService.abortS3Upload(signalId);
  }

  getChildKeys(data:any) {
    const childKeys = []
    for(let i=0; i<Object.keys(data).length; i++){
      if(data[Object.keys(data)[i]].filesProgressList) {
        childKeys.push(Object.keys(data)[i])
      }
    }
    return childKeys
  }

  showProgressBar() {
    if(this.uploadingFiles.filesProgressList.length) return true
    return false
  }
  toggleUploadView() {
    this.showFullUploadDetails = !this.showFullUploadDetails;
  }
}
