import { Component, ElementRef, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { CONSTANTS_MISSION, UPLOAD_DATASET_HEADER } from '../../../constants/create-mission.const';
import { UploadDatasetService } from '../../../services/upload-dataset.service';
import { FileUploadData } from '../upload-dataset.component';
import { UtilityService } from '../../../services/utility.service';
@Component({
  selector: 'app-drone-image-data',
  templateUrl: './drone-image-data.component.html',
  styleUrl: './drone-image-data.component.scss'
})
export class DroneImageDataComponent {
  @Output() getUploadedFile = new EventEmitter<FileUploadData>()
  @ViewChild('inputDroneImage') inputElement: ElementRef<HTMLInputElement>

  utilityService = inject(UtilityService)
  uploadDataServices = inject(UploadDatasetService)

  //  const
  MISSION_CONSTANTS = CONSTANTS_MISSION;
  UPLOAD_DATASET_HEADER = UPLOAD_DATASET_HEADER
  headExpand = ''
  isValid = true;
  droneImageUploadedFiles: boolean;


  ngOnInit() {
    this.uploadDataServices.datasetHeadExpand.subscribe((data) => {
      this.headExpand = data
    })
    this.uploadDataServices.checkFileIsAvailable.subscribe(() => {
      this.droneImageUploadedFiles = this.utilityService.checkUploadFileIsPresent(this.MISSION_CONSTANTS.DRONE_IMAGE)
    })
  }

  onSentUploadedFile(file: FileList | null, fileType: string, parentFileType: string) {
    const FileData = { file, fileType, parentFileType }
    this.getUploadedFile.emit(FileData)
  }

  onHeadExpand(data: string) {
    this.uploadDataServices.datasetHeadExpand.next(data)
  }

  checkFileValid(value:boolean) {
    this.isValid = value
    if (!this.isValid) {
      this.inputElement.nativeElement.value = ''
    }
  }

}
