import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { ICONS } from 'projects/data-upload/src/app/constants/progress.const';
import { MissionSharedService } from '../../services/mission-shared.service';
import { FilesProgressList } from '../../interfaces/file-upload-dataset.interfaces';
@Component({
  selector: 'app-uploading-progress-bar',
  templateUrl: './uploading-progress-bar.component.html',
  styleUrl: './uploading-progress-bar.component.scss'
})
export class UploadingProgressBarComponent {
  @ViewChild('uploadingProgress') uploadingProgress: ElementRef<HTMLDivElement>
  Object = Object
  uploadingFiles!: any
  icon = ICONS;
  createMissionParams = this.missionSharedService.createMissionParams_signal

  constructor(public missionSharedService: MissionSharedService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.missionSharedService._simulatedProgressFilesList.subscribe((value) => {
      this.uploadingFiles = value
      this.cdr.detectChanges();
    })
  }

  getBackgroundStyle(item: FilesProgressList) {
    return `radial-gradient(closest-side, rgba(0, 0, 0, 0) ${item.progress}%, transparent 100% 100%), conic-gradient(#00f0ff ${item.progress}%, rgba(92, 217, 239, 0.1) 0)`;
  }

  onFileRetry(file: File, fileType: string, parentFileType: string, signalId: string) {
    const { missionId, missionProjectId, siteId } = this.createMissionParams()
    this.missionSharedService.updateFilesProgressList(file.webkitRelativePath, fileType, parentFileType, 3)
    this.missionSharedService.uploadFilesToS3([file], fileType, parentFileType, missionId, missionProjectId, siteId)
    this.missionSharedService.abortS3Upload(signalId)
  }

  getChildKeys(data: any) {
    const childKeys = []
    for (let i = 0; i < Object.keys(data).length; i++) {
      if (data[Object.keys(data)[i]].filesProgressList) {
        childKeys.push(Object.keys(data)[i])
      }
    }
    return childKeys
  }

  showProgressBar() {
    let showProgressBar = false
    let isChildInProgress = false
    const payloadKeys = Object.keys(this.uploadingFiles ?? '')
    for (let i = 0; i < payloadKeys.length; i++) {
      if (this.uploadingFiles[payloadKeys[i]]?.filesProgressList) {
        if (this.uploadingFiles[payloadKeys[i]]?.filesProgressList.length) showProgressBar = true
      }
      else {
        const childKeys = Object.keys(this.uploadingFiles[payloadKeys[i]])
        for (let j = 0; j < childKeys.length; j++) {
          if (this.uploadingFiles[payloadKeys[i]][childKeys[j]]?.filesProgressList?.length) {
            showProgressBar = true
            isChildInProgress = true
          }
        }
      }
    }
    return {
      showProgressBar,
      isChildInProgress
    }
  }

  isProgressBarVisible() {
    return this.missionSharedService.showProgressBar
  }

  onMouseEnter(element: HTMLElement): void {
    if (element.scrollWidth > element.clientWidth) {
      element.style.marginLeft = '7px';
      element.style.paddingLeft = '0px';
      element.scrollLeft = element.scrollWidth - element.clientWidth;
    }
  }

  onMouseLeave(element: HTMLElement): void {
    element.style.marginLeft = '0px';
    element.style.paddingLeft = '6px';
    element.scrollLeft = 0;
  }

  overallUploadProgress(type: string, childKeys?: string) {
    const filesProgressList:FilesProgressList[] = childKeys ? this.uploadingFiles[type][childKeys]?.filesProgressList : this.uploadingFiles[type]?.filesProgressList;
    if (!filesProgressList) {
      return 0;
    }
    const totalItems = filesProgressList.length;
    let totalProgress = 0;
    filesProgressList.forEach((item) => {
      totalProgress += item.progress;
    });
    const overallProgress = totalItems === 0 ? 0 : totalProgress / totalItems;
    return Math.floor(overallProgress);
  }

  onHideProgressBar(hideProgress: HTMLDivElement) {
    hideProgress.style.display = 'none'
  }

}
