import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
import { ExternalClientAssetService } from '../../services/external-client-asset.service';
import { AssetList } from '../../interfaces/asset.interface'
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';
@Component({
  selector: 'app-asset-selection',
  templateUrl: './asset-selection.component.html',
  styleUrl: './asset-selection.component.scss',
})
export class AssetSelectionComponent implements OnInit {
  @Output() selectedAssetId = new EventEmitter<string[]>()
  // inject service
  utilityService = inject(UtilityService);
  externalClientAssetService = inject(ExternalClientAssetService)

  assetList: AssetList[] = [];
  selectedAssetList: AssetList[] = [];
  assetNextCursor: string;
  scrollNextCall = true;
  assetSearchForm: FormGroup;
  searchData = '';
  assetIdList: string [] = []

  ngOnInit(): void {
    this.customSelectDropdownClose();
    this.onGetAssetListOnScroll()
    this.onAssetSearchForm()
    this.onApiCallOnAssetListScroll()
  }

  ngOnDestroy(): void {
    this.removeEventListenerFromElement();
  }

  onAssetSearchForm() {
    this.assetSearchForm = new FormGroup({
      assetSearchInput: new FormControl('')
    })

    this.assetSearchForm.valueChanges.pipe(debounceTime(500)).subscribe(searchData => {
      this.searchData = searchData.assetSearchInput
      this.onGetAssetListOnSearch()
    });
  }

  onApiCallOnAssetListScroll() {
    const assetScrollContainer = document.querySelector('.dropdown-scroll') as HTMLDivElement
    if (assetScrollContainer) {
      assetScrollContainer.addEventListener('scroll', () => {
        const { scrollHeight, scrollTop, clientHeight } = assetScrollContainer;
        if (scrollHeight - scrollTop <= clientHeight * 5 && this.assetNextCursor && this.scrollNextCall) {
          this.scrollNextCall = false
          this.onGetAssetListOnScroll();
        }
      })
    }
  }

  customSelectDropdownClose(): void {
    this.utilityService.closeDropdown('.asset-select');
  }

  removeEventListenerFromElement(): void {
    this.utilityService.removeEventListener('.asset-select');
  }

  onGetAssetListOnScroll() {
    this.externalClientAssetService.getAssetList(this.searchData, this.assetNextCursor).subscribe((data) => {
      this.assetNextCursor = data.meta.nextCursor
      this.assetList.push(...data.records)
      this.scrollNextCall = true
    })
  }

  onGetAssetListOnSearch() {
    this.assetNextCursor = ''
    this.externalClientAssetService.getAssetList(this.searchData, this.assetNextCursor).subscribe((data) => {
      this.assetNextCursor = data.meta.nextCursor
      this.assetList = data.records
      this.scrollNextCall = true
    })
  }

  onSelectedAsset(selectedAsset: AssetList) {
    const index = this.selectedAssetList.findIndex((asset) => asset._id == selectedAsset._id)
    if (index == -1) {
      this.selectedAssetList.push(selectedAsset)
    } else {
      this.selectedAssetList.splice(index, 1)
    }
    this.onGetAllSelectedAssetId()
  }

  onRemoveSelectedAsset(assetId: string) {
    const index = this.selectedAssetList.findIndex((asset) => asset._id == assetId)
    if (index != -1) {
      const assetCheckBox = document.getElementById(assetId) as HTMLInputElement
      assetCheckBox && (assetCheckBox.checked = false)
      this.selectedAssetList.splice(index, 1)
      this.onGetAllSelectedAssetId()
    }
  }

  onGetAllSelectedAssetId() {
     this.assetIdList = this.selectedAssetList.map((asset) => asset._id)
    this.selectedAssetId.emit(this.assetIdList)
  }

}
