import { ChangeDetectorRef, Component } from '@angular/core';
import { IWorkflowProgress } from 'projects/data-upload/src/app/interfaces/create-mission-workflow.interface';
import { WorkflowSharedService } from 'projects/data-upload/src/app/services/workflow-shared.service';
import { ICONS, PROGRESS_WAITING_MSG } from  'projects/data-upload/src/app/constants/progress.const';
@Component({
  selector: 'app-utm-upload-progressbar',
  templateUrl: './upload-progressbar.component.html',
  styleUrls: ['./upload-progressbar.component.scss']
})
export class UploadProgressbarComponent {
  workflowDetails!: IWorkflowProgress[];
   icon=ICONS;
   progress_waiting_msg = PROGRESS_WAITING_MSG;
  constructor( private workflowSharedService: WorkflowSharedService, private cdr: ChangeDetectorRef ) {}

  ngOnInit() {
    this.workflowSharedService.workflowProgressArraySubject
      .subscribe((updatedWorkflowProgresses) => {
        if(updatedWorkflowProgresses.length) this.workflowDetails = [...updatedWorkflowProgresses];
        this.cdr.detectChanges()
      });
  }

  onWorkflowCancel(workflowId: string) {
    this.workflowSharedService.abortWorkFlowById(workflowId);
  }

  onWorkflowRestart(workflowId: string) {
    this.workflowSharedService.restartWorkflowById(workflowId);
  }

  onHideProgressBar(workflowId: string) {
    this.workflowSharedService.hideWorkflowById(workflowId);
  }
}
