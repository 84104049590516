<div 
  class="modal fade show mission-confirm-modal" 
  id="closeMissionConfirmation" 
  data-bs-backdrop="static" 
  data-bs-keyboard="false" 
  tabindex="-1" 
  aria-labelledby="staticBackdropLabel" 
  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body text-center">
          <div class="img-holder">
            <img [src]="'/images/warning.png' | assetUrl" alt="warning image" />
          </div>
          <div class="content">
            <h2 class="heading">{{ popupHeading }}</h2>
            <p class="info">{{ popupDescription }}</p>
          </div>
        </div>
        <div class="btn-holder">
          <button type="button" class="btn btn-default" (click)="onOptionSelect(true)">Yes</button>
          <button type="button" class="btn btn-danger" (click)="onOptionSelect(false)">No</button>
        </div>
      </div>
    </div>
</div>