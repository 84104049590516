@if(!isWorkflowStart){
<div class="create-mission-container">
  <div class="create-mission-body">
    <img [src]="'/images/close.svg' | assetUrl" alt="close" (click)="onCloseMissionCreation(TIME_DELAY.ZERO)">
    <div class="create-mission-tap-container">
      <h3>{{activeTab}}</h3>
      <div class="mission-tap-head">
        <div class="mission-detail-tap">
          <p class="tap-bar" [ngClass]="tabIndex ==1 ? 'active' : 'complete'">
          </p>
          <div class="tap-name">
            <img [src]="(tabIndex ==1 ?'/images/vector_cyan.svg':'/images/tick.svg') | assetUrl" alt="vector_circle">
            <p>Mission Detail</p>
          </div>
        </div>
        <div class="upload-dataset-tap">
          <p class="tap-bar" [ngClass]="tabIndex ==2 ? 'active' : tabIndex ==1 ? 'initial' : 'complete'">
          </p>
          <div class="tap-name">
            <img
              [src]="(tabIndex ==2 ?'/images/vector_cyan.svg': tabIndex ==1?'/images/vector_circle.svg':'/images/tick.svg') | assetUrl"
              alt="vector_circle">
            <p>Upload Dataset</p>
          </div>
        </div>
        <div class="asset-selection-tap">
          <p class="tap-bar" [ngClass]="tabIndex ==3 ? 'active' : 'initial'">
          </p>
          <div class="tap-name">
            <img [src]="(tabIndex ==3 ?'/images/vector_cyan.svg ':'/images/vector_circle.svg') | assetUrl"
              alt="vector_circle">
            <p>Asset Selection</p>
          </div>
        </div>
      </div>
    </div>
    <div class="mission-tap-body">
      @if(tabIndex == 1){
      <app-new-mission-detail [missionDetails]="missionDetails" (createMissionFormData)="getCreateMissionFormData($event)"
        [missionFormData]="missionFormData"></app-new-mission-detail>
      }
      @if(tabIndex == 2){
      <app-upload-dataset [resourceTypeList]="resourceTypeList"
        [selectedWorkflowDef]="selectedWorkflowDef"></app-upload-dataset>
      }
      @if(tabIndex == 3){
      <app-asset-selection (selectedAssetId)="onGetSelectedAssetId($event)"></app-asset-selection>
      }
    </div>
  </div>
  <div class="create-mission-footer">
    <button type="button" (click)="onPreviousTab()">{{tabIndex == 1? BUTTON_LABEL.CANCEL:BUTTON_LABEL.BACK}}</button>
    <button type="button" [disabled]="!isMissionFormValid || (tabIndex == 3 && !selectedAssetIdList.length)"
      (click)="onNextTab()">{{tabIndex == 3 ?
      BUTTON_LABEL.UPLOAD:BUTTON_LABEL.NEXT}}</button>
  </div>
</div>
}

@if(!isWorkflowStart){
<app-uploading-progress-bar></app-uploading-progress-bar>
}
