import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { UtilityService } from '../../services/utility.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreateMissionService } from '../../services/create-mission.service';
import { IMissionFormData, MissionObjective, WorkflowDef } from '../../models/mission-detail.model';
import { CreateMissionWorkflowService } from '../../services/create-mission-workflow.service';
import { MISSION_DETAIL_SELECT_LABEL } from '../../constants/create-mission.const';
import { IMissionDetails } from '../../interfaces/create-mission-workflow.interface';

@Component({
  selector: 'app-new-mission-detail',
  templateUrl: './new-mission-detail.component.html',
  styleUrl: './new-mission-detail.component.scss'
})
export class NewMissionDetailComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input('missionDetails') missionDetails: IMissionDetails | null;
  @Input({ required: true }) missionFormData: Omit<IMissionFormData, 'valid'>
  @Output() createMissionFormData = new EventEmitter<IMissionFormData>()
  // const
  MISSION_DETAIL_SELECT_LABEL = MISSION_DETAIL_SELECT_LABEL

  // inject service
  changeDetectorRef = inject(ChangeDetectorRef)
  formBuilder = inject(FormBuilder)
  utilityService = inject(UtilityService)
  createMissionService = inject(CreateMissionService)
  missionWorkflowService = inject(CreateMissionWorkflowService)

  missionObjectiveData: MissionObjective[] = []
  selectedMissionObjective: Partial<MissionObjective> = { name: 'Select mission objective' }
  WorkflowDefData: WorkflowDef[] = []
  selectedWorkflow: Partial<WorkflowDef> = { name: 'Select Workflow' }
  createMissionForm: FormGroup;

  ngOnInit(): void {
    this.customSelectDropdownClose()
    this.getMissionObjective()
    this.getWorkflowDefinitions()
    this.onCreateMissionForm()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['missionDetails']) {
      this.onUpdateMissionDetails()
    }
  }

  ngAfterViewInit(): void {
    this.missionFormData && this.onUpdateMissionFormData()
    this.changeDetectorRef.detectChanges()
    this.onUpdateMissionDetails()
  }

  ngOnDestroy(): void {
    this.removeEventListenerFromElement();
  }

  customSelectDropdownClose(): void {
    this.utilityService.closeDropdown('.workflow-select');
    this.utilityService.closeDropdown('.mission-objective-select');
  }

  removeEventListenerFromElement(): void {
    this.utilityService.removeEventListener('.workflow-select');
    this.utilityService.removeEventListener('.mission-objective-select');
  }

  getMissionObjective(): void {
    this.createMissionService.getMissionObjective().subscribe((missionObjective) => {
      this.missionObjectiveData = missionObjective.records
    })
  }

  getWorkflowDefinitions(): void {
    this.missionWorkflowService.getWorkflowDef().subscribe((workflow) => {
      this.WorkflowDefData = workflow.records;
    });
  }

  onUpdateMissionFormData(): void {
    this.createMissionForm.patchValue(this.missionFormData)
    this.selectedMissionObjective = this.missionFormData.missionObjective
    this.selectedWorkflow = this.missionFormData.workflowDef
  }

  getSelectedValue(selectedValue: MissionObjective | WorkflowDef, name: string): void {
    if (name === MISSION_DETAIL_SELECT_LABEL.MISSION_OBJECTIVE) {
      this.selectedMissionObjective = selectedValue;
    } else if (name === MISSION_DETAIL_SELECT_LABEL.WORKFLOW_DEF) {
      this.selectedWorkflow = selectedValue;
    }
    this.createMissionForm.get(name)?.setValue(selectedValue);
  }

  onCreateMissionForm(): void {
    this.createMissionForm = this.formBuilder.group({
      missionName: ['', [Validators.required]],
      missionObjective: ['', [Validators.required]],
      workflowDef: ['', [Validators.required]]
    });

    this.createMissionForm.valueChanges.subscribe(() => {
      const formData: IMissionFormData = {
        valid: this.createMissionForm.valid,
        ...this.createMissionForm.value
      };

      this.createMissionFormData.emit(formData);
    })
  }

  onUpdateMissionDetails() {
    if (this.missionDetails) {
      const missionName = this.missionDetails?.name
      const missionObjective = this.missionDetails?.objective
      this.createMissionForm?.patchValue({
        missionName, missionObjective
      })
      this.selectedMissionObjective = missionObjective
      this.missionUpdateDisable(true)
    } else {
      this.createMissionForm?.patchValue({
        missionName: '', missionObjective: '', workflowDef: ''
      })
      this.selectedMissionObjective = { name: 'Select mission objective' }
      this.selectedWorkflow = { name: 'Select Workflow' }
      this.missionUpdateDisable(false)
    }
  }

  missionUpdateDisable(disable: boolean) {
    const inputMissionName = document.getElementById('missionName') as HTMLInputElement;
    inputMissionName.disabled = disable
  }
}
