@if(showProgressBar().showProgressBar && isProgressBarVisible()){
<div class="upload-progress-outer-container">
  @for(item of Object.keys(uploadingFiles);track item; let idx = $index){
  @if(uploadingFiles[item].filesProgressList?.length){
  <div #uploadProgressContainer class="upload-progress-container" [ngClass]="{'hide-progress-bar':(uploadingFiles[item].filesProgressList | isUploadCompleted) ==
    uploadingFiles[item].filesProgressList?.length}">
    <div class="upload-progress-head">
      <div class="upload-progress-count">
        @if((uploadingFiles[item].filesProgressList | isUploadCompleted :uploadProgressContainer) ==
        uploadingFiles[item].filesProgressList?.length){
        <p class="upload-completed">Upload completed <img [src]="'/images/tick.svg' | assetUrl" alt="tick"></p>
        }@else {
        <p>Uploading {{uploadingFiles[item].filesProgressList | isUploadCompleted}} of
          {{uploadingFiles[item].filesProgressList?.length}} files...</p>
        }
        <div class="upload-progress-hide-show">
          <a (click)="uploadProgressContainer.classList.toggle('show-upload-file')">
            <img [src]="'/images/expand.svg' | assetUrl" alt="expand">
          </a>
        </div>
      </div>
      <div class="upload-progress-name">
        <p>PRASARANA II</p>
        <span>&#8226;</span>
        <p>{{createMissionParams().trackingId}}</p>
      </div>
      <hr>
    </div>
    <div class="upload-progress-bar-container">
      <div class="upload-progress-bar">
        <div>
          <div class="upload-progress-bar-outer">
            <div class="upload-progress-bar-inner"></div>
            <div #uploadingProgress class="upload-progress-pointer"
              [style.width]="(100 - overallUploadProgress(item)) +'%'">
              <img [src]="'/images/progress_pointer.svg' | assetUrl" alt="progress_pointer">
              <p></p>
            </div>
          </div>
        </div>
        <div class="upload-progress-percentage">
          @if((uploadingFiles[item].filesProgressList | isUploadCompleted) ==
          uploadingFiles[item].filesProgressList?.length){
          <div class="upload-progress-time-left">
            <p class="internet-connection">Complete</p>
          </div>
          }@else {
          <div class="upload-progress-time-left">
            <p class="internet-connection">Good connection</p>

          </div>
          }
          <p>{{uploadingFiles[item].filesProgressList | uploadProgress }}%</p>
        </div>
      </div>
    </div>
    <div class="upload-progress-dropdown">
      <div class="upload-progress-file-container">
        <p>Details:</p>
        <div class="upload-progress-file-list">
          @for(fileListItem of uploadingFiles[item].filesProgressList;track fileListItem ;let fIdx = $index){
          <div class="upload-progress-file">
            <p #paragraphElement class="upload-file-name" (mouseenter)="onMouseEnter(paragraphElement)"
              (mouseleave)="onMouseLeave(paragraphElement)">{{ fileListItem.name }}</p>
            @if(fileListItem.progress < 100 && fileListItem.progress !=-1){
              <div class="progress-bar" [style.background]="getBackgroundStyle(fileListItem)">
                <p class="progress-bar-inner"></p>
              </div>
            }
            @if(fileListItem.progress == 100){
              <img [src]="'/images/tick.svg' | assetUrl" class="retry-icon" alt="success">
            }
            @if(fileListItem.progress == -1){
              <div class="upload-error-container">
                <a (click)="onFileRetry(fileListItem.file,item,item,fileListItem.signalId)" class="upload-error-retry">Retry</a>
                <img [src]="'/images/alert_circle.svg' | assetUrl" class="retry-icon" alt="alert_circle">
              </div>
            }
        </div>
        }
      </div>
    </div>
  </div>
</div>
}

@if(!uploadingFiles[item].filesProgressList && showProgressBar().isChildInProgress){
@for(childKeys of getChildKeys(uploadingFiles[item]); track childKeys; let fIdx = $index){
@if(uploadingFiles[item][childKeys]?.filesProgressList?.length){
<div #uploadProgressChildContainer class="upload-progress-container" [ngClass]="{'hide-progress-bar':(uploadingFiles[item][childKeys].filesProgressList | isUploadCompleted) ==
uploadingFiles[item][childKeys].filesProgressList?.length}">
  <div class="upload-progress-head">
    <div class="upload-progress-count">
      @if((uploadingFiles[item][childKeys].filesProgressList | isUploadCompleted : uploadProgressChildContainer) ==
      uploadingFiles[item][childKeys].filesProgressList?.length){
      <p class="upload-completed">Uploaded completed <img [src]="'/images/tick.svg' | assetUrl" alt="tick"></p>
      }@else {
      <p>Uploading {{(uploadingFiles[item][childKeys].filesProgressList | isUploadCompleted)}} of
        {{uploadingFiles[item][childKeys].filesProgressList?.length}} files...</p>
      }
      <div class="upload-progress-hide-show">
        <a (click)="onHideProgressBar(uploadProgressChildContainer)">Hide</a>
        <a (click)="uploadProgressChildContainer.classList.toggle('show-upload-file')">
          <img [src]="'/images/expand.svg' | assetUrl" alt="expand">
        </a>
      </div>
    </div>
    <div class="upload-progress-name">
      <p>PRASARANA III</p>
      <span>&#8226;</span>
      <p>{{createMissionParams().trackingId}}</p>
    </div>
    <hr>
  </div>
  <div class="upload-progress-bar-container">
    <div class="upload-progress-bar">
      <div>
        <div class="upload-progress-bar-outer">
          <div class="upload-progress-bar-inner"></div>
          <div #uploadingProgress class="upload-progress-pointer"
            [style.width]="(100 - overallUploadProgress(item,childKeys)) +'%'">
            <img [src]="'/images/progress_pointer.svg' | assetUrl" alt="progress_pointer">
            <p></p>
          </div>
        </div>
      </div>
      <div class="upload-progress-percentage">
        @if((uploadingFiles[item][childKeys].filesProgressList | isUploadCompleted) ==
        uploadingFiles[item][childKeys].filesProgressList?.length){
        <div class="upload-progress-time-left">
          <p class="internet-connection">Complete</p>
        </div>
        }@else {
        <div class="upload-progress-time-left">
          <p class="internet-connection">Good connection</p>
        </div>
        }
        <p>{{uploadingFiles[item][childKeys].filesProgressList | uploadProgress }}%</p>
      </div>
    </div>
  </div>
  <div class="upload-progress-dropdown">
    <div class="upload-progress-file-container">
      <p>Details:</p>
      <div class="upload-progress-file-list">
        @for(fileListItem of uploadingFiles[item][childKeys].filesProgressList;track fileListItem ;let fIdx = $index){
          <div class="upload-progress-file">
            <p #paragraphElement class="upload-file-name" (mouseenter)="onMouseEnter(paragraphElement)"
              (mouseleave)="onMouseLeave(paragraphElement)">{{ fileListItem.name }}</p>
            @if(fileListItem.progress < 100 && fileListItem.progress !=-1){
              <div class="progress-bar" [style.background]="getBackgroundStyle(fileListItem)">
                <p class="progress-bar-inner"></p>
              </div>
            }
            @if(fileListItem.progress == 100){
              <img [src]="'/images/tick.svg' | assetUrl" class="retry-icon" alt="success">
            }
            @if(fileListItem.progress == -1){
              <div class="upload-error-container">
                <a (click)="onFileRetry(fileListItem.file,childKeys,item,fileListItem.signalId)" class="upload-error-retry">Retry</a>
                <img [src]="'/images/alert_circle.svg' | assetUrl" class="retry-icon" alt="alert_circle">
              </div>
            }
        </div>
      }
    </div>
  </div>
</div>
</div>
}
}
}
}
</div>
}
