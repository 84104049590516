import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError,catchError,Subscription,timer, interval} from 'rxjs';
import { map,mergeMap,tap,concatMap, switchMap, filter, retryWhen, scan, delay } from 'rxjs/operators';
import {IMissionWorkflowDef, IMissionWorkflowResponse, IMissionWorkflowRun} from '../interfaces/create-mission-workflow.interface';
import { Routes } from "./routes";
import { IWorkflow } from '../models/mission-detail.model';

@Injectable({
  providedIn: 'root'
})
export class CreateMissionWorkflowService {
  missionDataResParam:any;
  localMissionID:any;
  localProjectID:any;
  localSiteID:any;
  workflowData:any;
  minutes?: number;
  subscription?: Subscription;

  constructor(private httpClient: HttpClient) { }

  getWorkflowDef(): Observable<IWorkflow> {
    return this.httpClient.get<IWorkflow>(Routes.WORKFLOW_DEF_URL).pipe(map(res => res));
  }

  runWorkflowForMission(data:IMissionWorkflowRun): Observable<IMissionWorkflowRun> {
    return this.httpClient.post<IMissionWorkflowRun>(Routes.WORKFLOW_RUN_URL,data);
  }

  startWorkflowForMission(data:any): Observable<IMissionWorkflowRun> {
   const workFlowStartUrl = Routes.WORKFLOW_RUN_URL+data._id+'/run';
    return this.httpClient.post<IMissionWorkflowRun>(workFlowStartUrl,data);
  }

  getWorkflowRun(data:any): Observable<IMissionWorkflowDef[]> {
    const workFlowUrl = Routes.WORKFLOW_RUN_URL+data;
    return this.httpClient.get<IMissionWorkflowDef[]>(workFlowUrl).pipe(map(res => res));
  }

  updateWorkflowStatus(id:string,status:any): Observable<IMissionWorkflowDef[]> {
    const workFlowUrl = Routes.WORKFLOW_RUN_URL+id+'/status';
    const payload = { status }
    return this.httpClient.put<IMissionWorkflowDef[]>(workFlowUrl,payload).pipe(map(res => res));
  }

  getWorkflowRunStatus(missionId:any): Observable<IMissionWorkflowDef[]> {
    const workFlowUrl = Routes.WORKFLOW_RUN_URL+"?missionId="+missionId;
    return this.httpClient.get<IMissionWorkflowDef[]>(workFlowUrl).pipe(map(res => res));
  }

}
