import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

export enum EventTypes {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
  primary = 'primary',
  sticky = 'sticky',
}
export interface ToastEvent{
  type: EventTypes;
  title?: string;
  message: string;
}
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _toastEvents = new Subject<ToastEvent>();
  toastEvents: Observable<ToastEvent> = this._toastEvents.asObservable();

  constructor() {}

  showToast(events:ToastEvent){
    this._toastEvents.next(events);
  }
}
