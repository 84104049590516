<div class="upload-progress-wrapper" *ngIf="showProgressBar().showProgressBar && isProgressBarVisible()">
  <div class="d-flex flex-column align-items-start progress-wrap">
    <ng-container *ngFor="let item of Object.keys(uploadingFiles); let idx = index">
      <ng-template [ngIf]="uploadingFiles[item].filesProgressList?.length">
        <div class="title-wrapper" (click)="missionSharedService.toogleActivate(uploadingFiles[item].id)" >
          <span class="title">{{ uploadingFiles[item].title }}</span>
          <img [src]="getIconSrc(uploadingFiles[item].id) | assetUrl" alt="arrow-up" />
        </div>
        <ng-container *ngIf="missionSharedService.activeCollapseTitle == uploadingFiles[item].id">
          <ng-container *ngFor="let fileListItem of uploadingFiles[item].filesProgressList; let fIdx = index">
            <div class="upload-heading" key="fIdx">
              <h3 class="count">{{ fileListItem.name }}</h3>
              <div class="d-flex align-items-center retry-section">
                <img *ngIf="showTimeWarning(fileListItem?.startTime,fileListItem.progress)" [src]=" icon.RESTART_ICON| assetUrl" (click)="onFileRetry(fileListItem.file,item,item,fileListItem.signalId)" class="retry-icon" alt="retry" />
                <div *ngIf="fileListItem.progress < 100 && fileListItem.progress != -1" class="progress-bar" [style.background]="getBackgroundStyle(fileListItem)"></div>
                <img *ngIf="fileListItem.progress == -1" [src]="'/images/error_sign.png' | assetUrl" class="retry-icon" alt="error" />
                <img *ngIf="fileListItem.progress == 100" [src]="'/images/tick.svg' | assetUrl" class="retry-icon" alt="successs" />
              </div>
            </div>
          </ng-container>
      </ng-container>  
      </ng-template>
      <ng-template [ngIf]="!uploadingFiles[item].filesProgressList && showProgressBar().isChildInProgress">
        <div class="title-wrapper" (click)="missionSharedService.toogleActivate(uploadingFiles[item].id)" >
          <span class="title" >{{ uploadingFiles[item].title }}</span>
          <img [src]="getIconSrc(uploadingFiles[item].id) | assetUrl" alt="arrow-up" />
        </div>
        <ng-container *ngIf="missionSharedService.activeCollapseTitle == uploadingFiles[item].id">
          <ng-container *ngFor="let childKeys of getChildKeys(uploadingFiles[item]); let fIdx = index">
            <ng-container *ngIf="uploadingFiles[item][childKeys]?.filesProgressList?.length" >
              <span class="sub-heading ">{{ uploadingFiles[item][childKeys].title }}</span>
              <ng-container *ngFor="let childFileListItem of uploadingFiles[item][childKeys].filesProgressList; let fIdx = index">
                <div class="upload-heading" key="fIdx">
                  <h3 class="count">{{ childFileListItem.name }}</h3>
                  <div class="d-flex align-items-center retry-section">
                    <img *ngIf="showTimeWarning(childFileListItem?.startTime,childFileListItem.progress)" [src]="'/images/restrat-icon.svg' | assetUrl" (click)="onFileRetry(childFileListItem.file,childKeys,item,childFileListItem.signalId)" class="retry-icon" alt="retry" />
                    <div *ngIf="childFileListItem.progress < 100 && childFileListItem.progress != -1" class="progress-bar" [style.background]="getBackgroundStyle(childFileListItem)"></div>
                    <img *ngIf="childFileListItem.progress == -1" [src]="icon.ERROR_ICON| assetUrl" class="retry-icon" alt="error" />
                    <img *ngIf="childFileListItem.progress == 100" [src]="icon.SUCCESS_ICON | assetUrl" class="retry-icon" alt="success" />
                  </div>
                </div>
            </ng-container>
          </ng-container>
          </ng-container>
      </ng-container>
      </ng-template>
    </ng-container>
  </div>
</div>