export const Modalpopup: { popupHeading: string, popupDescription: string } = {
    popupHeading: "Delete Folder?",
    popupDescription: "The folder will be Deleted."
  };
  export const NOTIFY_MSG = {
    'ERROR_CLASS': 'toaster-error',
    'SUCCESS_CLASS': 'toaster-success',
    'INVALID_FILE_XML': 'Please drop XML format only',
    'INVALID_FILE_FORMAT': 'Invalid file format!',
    'INVALID_FOLDER_FORMAT': 'Invalid Folder Name!',
    'WORKFLOW_FAILED': 'Workflow run cannot be processed due to failure.',
    'ERROR_UNDEFINED': 'undefined',
    'MISSION_CREATED': 'Mission created successfully.',
    'MISSION_FAILED': 'Mission not created.',
    'UPLOAD_FAILED': 'Credentials access failed. Please try again.',
    'UPLOAD_CAMERA_PARAM': 'Successfully uploaded Camera Parameters.',
    'UPLOAD_DIGITAL_TWIN': 'Successfully uploaded Digital Twin.',
    'UPLOAD_TOWER_VIEW': 'Successfully uploaded Tower View images.',
    'UPLOAD_LINE_SIGHT': 'Successfully uploaded Line of Sight images.',
    'UPLOAD_TOP_DOWN': 'Successfully uploaded Side View images.',
    'UPLOAD_NADIR': 'Successfully uploaded Nadir images.',
    'WORKFLOW_CANCELLED': 'Workflow has been cancelled successfully.',
    'NO_MISSION_COMPLETED': 'Project does not have completed mission',
    'DATA_READY':'Your data is ready'
  }