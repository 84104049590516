import { ChangeDetectorRef, Component, Input, OnInit, inject } from '@angular/core';
import { FilesProgressList } from '../../interfaces/file-upload-dataset.interfaces';
import { MissionSharedService } from '../../services/mission-shared.service';
import { UploadDatasetService } from '../../services/upload-dataset.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteModalComponent } from '../../shared/components/delete-modal/delete-modal.component';
import { EventTypes, NotificationService } from '../../services/notification.service';
import { NOTIFY_MSG } from '../../constants/create-mission.const';
import { debounceTime } from 'rxjs';
interface UploadedFileTree {
  [key: string]: UploadedFileTree | boolean;
}
export interface TreeNode {
  name: string;
  children?: TreeNode[];
  isExpand: boolean;
  isChecked: boolean;
  isSomeChildChecked: boolean;
  isAllChildChecked: boolean;
  isFolder: boolean;
  id: string;
  childTreeGap: number
}
export interface UploadCategory {
  parentType: string,
  childType: string
}

type CheckStatus = {
  allChecked: boolean;
  someChecked: boolean;
};

@Component({
  selector: 'app-upload-folder-preview',
  templateUrl: './upload-folder-preview.component.html',
  styleUrl: './upload-folder-preview.component.scss',
})
export class UploadFolderPreviewComponent implements OnInit {
  @Input() uploadCategory: UploadCategory
  @Input() disableFileSelect: boolean = false;

  uploadDataServices = inject(UploadDatasetService)
  missionSharedService = inject(MissionSharedService)
  ngbModalRef = inject(NgbModal)
  changeDetectorRef = inject(ChangeDetectorRef)
  uploadDatasetService = inject(UploadDatasetService)
  notificationService = inject(NotificationService)

  folderTreeData: TreeNode[] = [];
  selectedAllFileList: string[] = [];
  selectAllStatus: CheckStatus = { allChecked: false, someChecked: false };

  ngOnInit(): void {
    this.uploadDataServices.checkUploadedFileList.pipe(debounceTime(1000)).subscribe(() => {
      this.generateHierarchyFolderStructure()
    })
  }

  generateHierarchyFolderStructure() {
    let fileList: FilesProgressList[] = this.getUploadFileList().filesProgressList;
    if (fileList.some((file) => file.progress !=100)) return;
    const fileTree: UploadedFileTree = {};
    for (const file of fileList) {
      if ( file.progress < 100 ) continue;
      const filePath = file.signalId;
      const pathArray = filePath.split('/');
      let currentLevel: UploadedFileTree = fileTree;
      pathArray.forEach((folder: string, index: number) => {
        if (index === pathArray.length - 1) {
          if (!currentLevel[folder]) {
            currentLevel[filePath] = true;
          }
        } else {
          if (!currentLevel[folder]) {
            currentLevel[folder] = {};
          }
          currentLevel = currentLevel[folder] as UploadedFileTree;
        }
      });
    }
    const convertObjectToArray = (tree: UploadedFileTree, gap: number): TreeNode[] => {
      const result: TreeNode[] = [];
      for (const [key, value] of Object.entries(tree)) {
        const id = key
        const item: Partial<TreeNode> = { name: key.split('/').at(-1), isChecked: false, id, childTreeGap: gap + 12 };
        if (typeof value === 'object') {
          item.children = convertObjectToArray(value, gap + 16);
          item.childTreeGap = gap;
          item.isExpand = false;
          item.isFolder = true;
          item.isSomeChildChecked = false;
          item.isAllChildChecked = false;
        }
        result.push(item as TreeNode);
      }
      return result;
    };

    this.folderTreeData = convertObjectToArray(fileTree, 16);
  }

  getUploadFileList() {
    if (this.uploadCategory.parentType == this.uploadCategory.childType) {
      return this.missionSharedService.uploadingFiles[this.uploadCategory.parentType]
    } else {
      return this.missionSharedService.uploadingFiles[this.uploadCategory.parentType][this.uploadCategory.childType]
    }
  }

  onCheckboxChange(treeNode: TreeNode | TreeNode[], checkBox: HTMLInputElement) {
    const onAddStatus = (node: TreeNode) => {
      if (node.isFolder) {
        if (checkBox.checked) {
          this.onSetCheckedStatus(node, true)
        } else {
          this.onSetCheckedStatus(node, false)
        }
      } else {
        node.isChecked = !node.isChecked
      }
    }

    if (Array.isArray(treeNode)) {
      treeNode.forEach(onAddStatus)
    } else {
      onAddStatus(treeNode)
    }

    this.onAddSelectedFileName()
    this.addCheckStatusOnNodeTree()
    this.selectAllStatus = this.onCheckSelectAllStatus()

  }

  onAddSelectedFileName() {
    const setCheckedStatus = (node: TreeNode) => {
      if (node.isFolder && node.children) {
        node.children.forEach(child => {
          setCheckedStatus(child);
        });
      } else if (node.isChecked && !this.selectedAllFileList.includes(node.id)) {
        this.selectedAllFileList.push(node.id)
      } else if (!node.isChecked && this.selectedAllFileList.includes(node.id)) {
        this.selectedAllFileList = this.selectedAllFileList.filter((value) => value != node.id)
      }
    }
    this.folderTreeData.forEach(setCheckedStatus)
  }

  checkBoxIcon(node: TreeNode) {
    let iconPath: string;
    if (node.isFolder) {
      if (node.isAllChildChecked || node.isChecked) {
        iconPath = '/images/checked.svg';
      } else if (node.isSomeChildChecked) {
        iconPath = '/images/some-check.svg';
      } else {
        iconPath = '/images/un-check.svg';
      }
    } else if (node.isChecked) {
      iconPath = '/images/checked.svg';
    } else {
      iconPath = '/images/un-check.svg';
    }
    return iconPath;
  }

  selectAllCheckBoxIcon() {
    let iconPath: string;
    const { allChecked, someChecked } = this.selectAllStatus
    if (allChecked) {
      iconPath = '/images/checked.svg';
    } else if (someChecked) {
      iconPath = '/images/some-check.svg';
    } else {
      iconPath = '/images/un-check.svg';
    }
    return iconPath;
  }

  onSetCheckedStatus(node: TreeNode, checked: boolean) {
    const setCheckedStatus = (node: TreeNode) => {
      if (node.children) {
        node.isChecked = checked
        node.isAllChildChecked = checked
        node.isSomeChildChecked = checked
        node?.children?.forEach(child => {
          child.isChecked = checked;
          setCheckedStatus(child);
        });
      }
    }
    setCheckedStatus(node)
    checked && this.setAllFolderTreeToExpand(node,checked)
  }

  onDeleteFilesFromS3() {
    const filteredData = this.onFilterSelectedFile()
    let dataResourceId: string[];
    if (this.disableFileSelect) {
      let fileList = this.getUploadFileList()
      const folderNames = [... new Set(filteredData.map((data) => {
        const fullPath = data.file as File
        return fullPath.webkitRelativePath.split('/')[0];
      }))]
      dataResourceId = fileList.dataResources.filter((data: any) => folderNames.includes(data.folderName) ).map((data: any) => data.dataResourceId)
    } else {
      dataResourceId = filteredData.map((data) => data.dataResourceId) as string[]
    }
    this.uploadDatasetService.deleteDataResourcesBulk(dataResourceId).subscribe({
      next: () => {
        this.onDeleteFilesFromUploadDateset(filteredData)
        this.removeFileFromFolderTree(this.folderTreeData, filteredData)
        this.selectAllStatus = { allChecked: false, someChecked: false };
        this.uploadDatasetService.checkFileIsAvailable.next('')
        this.notificationService.showToast({ type: EventTypes.success, message: NOTIFY_MSG.FILE_DELETE_SUCCESS });
        this.selectedAllFileList = []
      },
      error: () => {
        this.notificationService.showToast({ type: EventTypes.error, message: NOTIFY_MSG.FILE_DELETE_FAILED });
      }
    })
  }

  onDeleteFilesFromUploadDateset(filteredData: FilesProgressList[]) {
    let fileList = this.getUploadFileList().filesProgressList as FilesProgressList[]
    filteredData.forEach((files) => {
      const index = fileList.findIndex((fileId) => fileId.signalId == files.signalId)
      if (index !== -1) fileList.splice(index, 1)
    })
  }

  removeFileFromFolderTree(folderTreeData: TreeNode[], selectedFile: FilesProgressList[]) {
    const removeFile = (folderTreeData: TreeNode[], selectedFile: string) => {
      for (let i = 0; i < folderTreeData.length; i++) {
        if (folderTreeData[i].id === selectedFile) {
          folderTreeData.splice(i, 1);
          return true;
        }

        if (folderTreeData[i].children) {
          const childFound = removeFile(folderTreeData[i].children as TreeNode[], selectedFile);
          if (childFound) {
            return true;
          }
        }
      }
      return false;
    }
    selectedFile.forEach((data) => removeFile(folderTreeData, data.signalId))
    this.removeEmptyFolderFromFolderTree(folderTreeData)
  }

  removeEmptyFolderFromFolderTree(folderTreeData: TreeNode[]) {
    if (!folderTreeData) {
      return;
    }

    for (let i = folderTreeData.length - 1; i >= 0; i--) {
      const node = folderTreeData[i];
      if (node.children) {
        this.removeEmptyFolderFromFolderTree(node.children);
        if (node.children.length === 0) {
          folderTreeData.splice(i, 1);
        }
      }
    }
  }

  setAllFolderTreeToExpand(node:TreeNode,expand: boolean) {
    const setExpandToTrue = (folderTree: TreeNode,expand:boolean) => {
      if (folderTree.hasOwnProperty('isExpand')) {
        folderTree.isExpand = expand;
      }
      if (folderTree.hasOwnProperty('children') && Array.isArray(folderTree.children)) {
        folderTree.children.forEach(child => {
          setExpandToTrue(child,expand);
        });
      }
    }
    node.isExpand = expand;
    node.children?.forEach((node)=>setExpandToTrue(node,expand))
  }

  onFilterSelectedFile(): FilesProgressList[] {
    let fileList = this.getUploadFileList().filesProgressList as FilesProgressList[]
    return this.selectedAllFileList.flatMap(selectedFile =>
      fileList.filter(data => data.signalId === selectedFile)
    );
  }

  isFolderChecked() {
    const isAnyChildChecked = (folderTree: TreeNode): boolean => {
      if (folderTree.isFolder && folderTree.isChecked) {
        return true;
      }
      if (folderTree.isFolder) {
        return (folderTree.children || []).some(child => isAnyChildChecked(child));
      }
      return false;
    };

    return this.folderTreeData.some(folder => isAnyChildChecked(folder));
  }

  onDeleteModalOpen() {
    const modalRef = this.ngbModalRef.open(DeleteModalComponent, {
      centered: true,
      windowClass: 'declaration-modal',
      backdropClass: 'declaration-modal-static',
      backdrop: false,
      size: 'md',
    });

    let deleteModelData = {
      isFolder: this.isFolderChecked(),
      selectedFile: this.selectedAllFileList.length == 1 ? this.selectedAllFileList[0].split('/').at(-1) : this.selectedAllFileList.length
    }

    modalRef.componentInstance.fileDetails = deleteModelData;
    modalRef.result.then((isConform) => {
      if (isConform) {
        this.onDeleteFilesFromS3()
        this.folderTreeData.forEach((node) => this.onSetCheckedStatus(node, false))
      }
    }, (reason) => {
    });
    this.changeDetectorRef.detectChanges();
  }

  addCheckStatusOnNodeTree() {
    const updateNodeStatus = (node: TreeNode) => {
      if (node.isFolder) {
        node.children?.forEach(updateNodeStatus);
        const allChecked = node.children?.every(child => child.isChecked || child.isAllChildChecked);
        const someChecked = node.children?.some(child => child.isChecked || child.isSomeChildChecked);
        if (allChecked) {
          node.isAllChildChecked = true;
          node.isChecked = true;
          node.isSomeChildChecked = false;
        } else if (someChecked) {
          node.isSomeChildChecked = true;
          node.isChecked = false;
          node.isAllChildChecked = false;
        } else {
          node.isSomeChildChecked = false;
          node.isChecked = false;
          node.isAllChildChecked = false;
        }
      }
    };
    this.folderTreeData.forEach(updateNodeStatus);

  }

  onCheckSelectAllStatus(): CheckStatus {
    const checkNodes = (nodes: TreeNode[]): CheckStatus => {
      let allChecked = true;
      let someChecked = false;

      const processNode = (node: TreeNode) => {
        if (node.isFolder && node.children) {
          const childStatus = checkNodes(node.children);
          if (!childStatus.allChecked) {
            allChecked = false;
          }
          if (childStatus.someChecked) {
            someChecked = true;
          }
        } else if (!node.isChecked) {
          allChecked = false;
        } else {
          someChecked = true;
        }
      };
      nodes.forEach(processNode);
      return { allChecked, someChecked };
    };
    return checkNodes(this.folderTreeData);
  }

}
