import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, catchError } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Routes } from "./routes";
import { ICreateMissionProject, ICreateMissionSiteData, ICreateMissionObjective, ICreateMissionPostData, ICreateMissionStatus, ICreateMissionGetStatus, ICreateMissionStatusAndProject } from '../models/create-mission-dataset.model';
import { IMissionObjective } from '../models/mission-detail.model';

@Injectable({
  providedIn: 'root'
})
export class CreateMissionService {
  missionSiteUrl?: string;
  missionObjectiveList: any;
  missionStatusUrl?: string;

  constructor(private httpClient: HttpClient) { }

  getMissionObjective() {
    return this.httpClient.get<IMissionObjective>(Routes.MISSION_OBJ_URL).pipe(shareReplay());
  }

  getMissionStatus(siteId: string): Observable<ICreateMissionStatusAndProject[]> {
    this.missionStatusUrl = Routes.CREATE_MISSION_URL + '?siteId=' + siteId;
    return this.httpClient.get<ICreateMissionStatusAndProject[]>(this.missionStatusUrl).pipe(map((res) => res));
  }

  getMissionTrackingID() {
    return this.httpClient.get(Routes.MISSION_TRACKING_URL).pipe(map(res => res))
  }

  getMissionProjects(): Observable<ICreateMissionProject> {
    return this.httpClient.get<ICreateMissionProject>(Routes.MISSION_PROJECT_URL).pipe(map(res => res));
  }

  getMissionSiteInfo(siteId: any): Observable<ICreateMissionSiteData[]> {
    this.missionSiteUrl = Routes.MISSION_SITE_URL + siteId;
    return this.httpClient.get<ICreateMissionSiteData[]>(this.missionSiteUrl).pipe(map(res => res));
  }

  getCurrentMissionStatus(siteId: string, status: string | null = null, projectId: string | null = null): Observable<ICreateMissionGetStatus> {
    let queryParams = `?siteId=${siteId}`;
    if (status) queryParams += `&status=${status}`
    if (projectId) queryParams += `&projectId=${projectId}`
    const missionStatusUrl = Routes.CREATE_MISSION_URL + queryParams;
    return this.httpClient.get<ICreateMissionGetStatus>(missionStatusUrl).pipe(map(res => res));
  }

  postMissionData(data: any): Observable<ICreateMissionPostData[]> {
    return this.httpClient.post<ICreateMissionPostData[]>(Routes.CREATE_MISSION_URL, data).pipe(
      catchError((err) => {
        return throwError(err);
      })
    )
  }

  updateMissionData(missionId: string, missionData: Object): Observable<any> {
    return this.httpClient.patch<any>(Routes.CREATE_MISSION_URL + "/" + missionId, missionData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    )
  }

  updateMissionStatus(missionId: string, missionStatus: any): Observable<ICreateMissionStatus[]> {
    return this.httpClient.patch<ICreateMissionStatus[]>(Routes.CREATE_MISSION_URL + "/" + missionId, missionStatus).pipe(
      catchError((err) => {
        return throwError(err);
      })
    )
  }

  trackingId(): Observable<any> {
    return this.httpClient.get(Routes.MISSION_TRACKING_URL)

  }
}
