<div class="upload-component-container">
  @if((resourceTypeList | showHideUploadDataset: RESOURCE_TYPE.THREE_D_DATA) || selectedWorkflowDef.name == 'TelcoWithAIGeneric'){
  <app-three-d-data (getUploadedFile)="filesDropped($event)"></app-three-d-data>
  }
  @if(resourceTypeList | showHideUploadDataset: RESOURCE_TYPE.ORTHOMOSAIC){
  <app-orthomosaic-data (getUploadedFile)="filesDropped($event)"></app-orthomosaic-data>
  }
  @if(resourceTypeList | showHideUploadDataset: RESOURCE_TYPE.DRONE_IMAGE){
  <app-drone-image-data (getUploadedFile)="filesDropped($event)"></app-drone-image-data>
  }
  @if(resourceTypeList | showHideUploadDataset: RESOURCE_TYPE.VIDEO){
  <app-video-data (getUploadedFile)="filesDropped($event)"></app-video-data>
  }
  @if(resourceTypeList | showHideUploadDataset: RESOURCE_TYPE.PANORAMA_360){
  <app-panorama-360-data (getUploadedFile)="filesDropped($event)"></app-panorama-360-data>
  }
  <app-additional-image-data (getUploadedFile)="filesDropped($event)"></app-additional-image-data>
</div>
