import { ChangeDetectorRef, Component } from '@angular/core';
import { MissionSharedService } from 'projects/data-upload/src/app/services/mission-shared.service';
import {ICONS} from  'projects/data-upload/src/app/constants/progress.const';
@Component({
  selector: 'app-files-uploading-progress',
  templateUrl: './files-uploading-progress.component.html',
  styleUrls: ['./files-uploading-progress.component.scss']
})
export class FilesUploadingProgressComponent {

  constructor(public missionSharedService: MissionSharedService, private cdr: ChangeDetectorRef) {}
  Object = Object
  uploadingFiles!: any
  icon=ICONS;
  createMissionParams = this.missionSharedService.createMissionParams_signal
  ngOnInit() {
    this.missionSharedService._simulatedProgressFilesList.subscribe((value)=>{
      this.uploadingFiles = value
      this.cdr.detectChanges();
    })
  }

  getBackgroundStyle(item:any) {
    return `radial-gradient(closest-side, rgba(0, 0, 0, 0.4) ${item.progress}%, transparent 80% 100%), conic-gradient(#00f0ff ${item.progress}%, rgba(92, 217, 239, 0.1) 0)`;
  }

  showTimeWarning(startDate:any, progress:number) {
    if(progress == -1) {
      return true
    }
    const currentDate = Date.now()
    const timeDifferenceMilliseconds = Math.abs(currentDate - startDate);
    const timeDifferenceMinutes = Math.floor(timeDifferenceMilliseconds / (1000 * 60));
    return progress > 0 && progress < 100 && timeDifferenceMinutes > 1
  }

  onFileRetry(file: any,fileType: string, parentFileType: string, signalId:string) {
    const { missionId , missionProjectId, siteId } = this.createMissionParams()
    this.missionSharedService.updateFilesProgressList(file.webkitRelativePath,fileType,parentFileType,3)
    this.missionSharedService.uploadFilesToS3([file],fileType,parentFileType,missionId,missionProjectId,siteId)
    this.missionSharedService.abortS3Upload(signalId)
  }

  getChildKeys(data:any) {
    const childKeys = []
    for(let i=0;i<Object.keys(data).length;i++){
      if(data[Object.keys(data)[i]].filesProgressList) {
        childKeys.push(Object.keys(data)[i])
      }
    }
    return childKeys
  }

  showProgressBar() {
    let showProgressBar = false
    let isChildInProgress =false
    const payloadKeys:any[] = Object.keys(this.uploadingFiles)
    for(let i=0;i<payloadKeys.length;i++) {
      if(this.uploadingFiles[payloadKeys[i]]?.filesProgressList) {
        if(this.uploadingFiles[payloadKeys[i]]?.filesProgressList.length)  showProgressBar = true
      }
      else {
        const childKeys = Object.keys(this.uploadingFiles[payloadKeys[i]])
        for(let j=0;j<childKeys.length;j++) {
          if(this.uploadingFiles[payloadKeys[i]][childKeys[j]]?.filesProgressList?.length) {
            showProgressBar = true
            isChildInProgress = true
          }
        }
      }
    }
    return {
      showProgressBar,
      isChildInProgress
    }
  }

  getIconSrc(id:string) {
    return this.missionSharedService.activeCollapseTitle == id ? '/images/arrow_down.svg' : '/images/arrow_up.svg'
  }

  isProgressBarVisible() {
    return this.missionSharedService.showProgressBar
  }

  closeProgressBar() {
    if(this.missionSharedService.isAllSiteFilesUploaded()) this.missionSharedService.hideProgressBar()
  }

}
