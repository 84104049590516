<div class="upload-progress-container">
  <div class="upload-progress-wrapper">
    <div class="progress-header d-flex align-items-center justify-content-between">
      <span class="extended-text"></span>
      <span class="percent">{{progressPercentage}}%</span>
    </div>
    <div class="progress-content">
      <div class="cust-progress-bar" [style]="'--progressbar-width: ' + progressPercentage + '%'"></div>
      <img class="line" [src]="progressLineIconPath | assetUrl" [style]="'--line-width: ' + (progressPercentage - 2) + '%'"/>
    </div>
    <div class="upload-header">
      <h3 class="title">Uploading 120 of 254 files...</h3>
      <button class="btn-hide">
        Hide
        <img [src]="icon.EXPAND_ICON | assetUrl" alt="close" class="expand_icon" />
      </button>
    </div>
    <div class="progress-container">
      <div class="progress-holder">
        <div class="progress-bar show-progress" role="progressbar" [style.width.%]="progressPercentage"
          aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
          <img [src]="icon.PROGRESS_BG | assetUrl" alt="progress_bg" class="progress-bar-image" />
        </div>
      </div>
      <div class="progress-bar-pointer-wrapper">
        <img [src]="icon.PROGRESS_POINTER | assetUrl" alt="progress_pointer" class="progress-bar-pointer"
          [style.left]="calculatePointerPosition()" />
      </div>
    </div>
    <div class="progress-percentage">
      <div class="content">
        <p>
          <span [ngClass]="goodConnection ? 'connection' : 'bad-connection'">{{ connection_status }}</span>
          <span>.15 min left</span>
        </p>
      </div>
      <p>{{ progressPercentage }}%</p>
    </div>
  </div>
</div>