import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UploadDatasetService } from '../../../services/upload-dataset.service';
import { UtilityService } from '../../../services/utility.service';
import { FileUploadData } from '../upload-dataset.component';

@Component({
  selector: 'app-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrl: './drag-drop.component.scss',
})
export class DragDropComponent {
  @Input() datasetHeader = '';
  @Input() fileType = '';
  @Input() supportedFile = '';
  @Input() disableFileSelect = false;
  @Input() showDragDrop: boolean = false;
  @Output() getUploadedFile = new EventEmitter<FileUploadData>();
  @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

  public isValid: boolean = true;
  public uploadedFiles: boolean;

  constructor(
    private uploadDataServices: UploadDatasetService,
    private utilityService: UtilityService
  ) {}

  ngOnInit() {
    this.uploadDataServices.checkFileIsAvailable.subscribe(() => {
      this.uploadedFiles =
        this.utilityService.checkUploadFileIsPresent(
          this.fileType
        );
    });
  }

  onSentUploadedFile(
    file: FileList | null,
    fileType: string,
    parentFileType: string
  ) {
    const FileData = { file, fileType, parentFileType };

    this.getUploadedFile.emit(FileData);
  }

  onHeadExpand(data: string) {
    this.uploadDataServices.datasetHeadExpand.next(data);
  }

  checkFileValid(value: boolean) {
    this.isValid = value
      if (!this.isValid) {
        this.inputElement.nativeElement.value = ''
      }
  }
}
