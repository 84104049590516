import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMissionFormData, WorkflowDef } from '../../models/mission-detail.model';
import { MissionSharedService } from '../../services/mission-shared.service';
import { state } from "@app/utility";
import { CreateMissionWorkflowService } from '../../services/create-mission-workflow.service';
import { lastValueFrom } from 'rxjs';
import { BUTTON_LABEL, MISSION_STATUS, MISSION_TAB_LABEL, MISSION_TAP_COLOR, NOTIFY_MSG, TIME_DELAY } from '../../constants/create-mission.const';
import { CreateMissionService } from '../../services/create-mission.service';
import { EventTypes, NotificationService } from '../../services/notification.service';
import { WorkflowSharedService } from '../../services/workflow-shared.service';
import { IMissionDetails } from '../../interfaces/create-mission-workflow.interface';
import { IMissionDataParams } from '../../models/create-mission-dataset.model';
@Component({
  selector: 'app-create-new-mission',
  templateUrl: './create-new-mission.component.html',
  styleUrl: './create-new-mission.component.scss'
})
export class CreateNewMissionComponent implements OnInit {
  @Input('missionDetails') missionDetails: IMissionDetails | null
  @Output() closeMissionCreation = new EventEmitter<false>()
  // const
  BUTTON_LABEL = BUTTON_LABEL
  MISSION_TAP_COLOR = MISSION_TAP_COLOR
  TIME_DELAY = TIME_DELAY
  tabIndex = 1
  activeTab = ''
  missionFormData: IMissionFormData;
  isMissionFormValid = false
  resourceTypeList: string[] = []
  isFileUploaded: boolean;
  selectedWorkflowDef: WorkflowDef;
  workflowRunResponse: { _id: string };
  isWorkFlowStart = false;
  isNewMission = true
  siteId = ''
  selectedAssetIdList: string[] = []
  createMissionParams = this.missionSharedService.createMissionParams_signal
  isWorkflowStart = false
  constructor(
    private missionSharedService: MissionSharedService,
    private missionWorkflowService: CreateMissionWorkflowService,
    private createMissionService: CreateMissionService,
    private notificationService: NotificationService,
    private workflowSharedService: WorkflowSharedService,
  ) { }

  ngOnInit(): void {
    this.onSetTabName()
  }

  getCreateMissionFormData(missionFormData: IMissionFormData) {
    this.isMissionFormValid = missionFormData.valid

    if (missionFormData.valid) {
      this.missionFormData = missionFormData
      this.getResourceTypeList(missionFormData.workflowDef)
    }
  }

  onNextTab() {
    if (this.tabIndex == 1 && this.isMissionFormValid) {
      if (this.missionDetails) {
        this.onUpdateMission()
      } else {
        this.onCreateNewMission()
      }
    }
    if (this.tabIndex == 2) {
      const fileList = this.missionSharedService.uploadingFiles;
      this.isFileUploaded = this.checkFileIsUploaded(fileList)
    }
    this.tabIndex == 3 && this.selectedAssetIdList.length && this.onSetAssetIdToMission()
    this.tabIndex == 3 && !this.isWorkFlowStart && this.onRunWorkFlow()
    this.tabIndex < 3 && (this.tabIndex == 1 || this.isFileUploaded) && this.tabIndex++
    this.onTabIndexChange()
  }

  onPreviousTab() {
    if (this.tabIndex == 1) {
      this.onCloseMissionCreation(TIME_DELAY.ZERO)
    }
    this.tabIndex > 1 && this.tabIndex--
    this.onTabIndexChange()
  }

  onTabIndexChange() {
    this.onSetTabName()
  }

  onSetTabName() {
    if (this.tabIndex == 1) {
      this.activeTab = MISSION_TAB_LABEL.MISSION_DETAILS;
    }
    if (this.tabIndex == 2) {
      this.activeTab = MISSION_TAB_LABEL.UPLOADING_NEW_DATASET;
    }
    if (this.tabIndex == 3) {
      this.activeTab = MISSION_TAB_LABEL.ASSET_SELECTION;
    }
  }

  getResourceTypeList(workflowDef: WorkflowDef) {
    this.selectedWorkflowDef = workflowDef
    const value = workflowDef.workflowSteps
      .filter(workflowStep => !workflowStep.parentStepNames.length)
      .flatMap(data => data.inputDataResourceTypes)
      .map(val => val.resourceType);
    this.resourceTypeList = value
  }

  checkFileIsUploaded(data: any) {
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const value = data[key];
        if (Array.isArray(value.filesProgressList) && value.filesProgressList.length > 0) {
          return true;
        } else if (typeof value === 'object') {
          if (this.checkFileIsUploaded(value)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  getUserInfo() {
    return state.getUserInfo();
  }

  onCloseMissionCreation(time: number = 3000) {
    this.missionSharedService.onUploadingFilesInitialization()
    setTimeout(() => {
      this.closeMissionCreation.emit(false)
    }, time)
  }

  onCreateNewMission() {
    const missionPayload = {
      workspaceId: this.missionSharedService.getUserInfo().activeWorkspaceId,
      _id: "",
      objective: this.missionFormData.missionObjective._id,
      name: this.missionFormData.missionName,
      status: MISSION_STATUS.DRAFT,
    }

    this.createMissionService.postMissionData(missionPayload).subscribe({
      next: (response: any) => {
        this.notificationService.showToast({ type: EventTypes.success, message: NOTIFY_MSG.MISSION_CREATED });
        const params = {
          siteId: this.siteId,
          missionId: response?.id,
          missionProjectId: response?.projectId,
          missionAssetId: response?.assetIds,
          name: this.missionFormData?.missionName,
        } as IMissionDataParams;

        this.missionDetails = {
          _id: response._id,
          siteId: this.siteId,
          projectId: response?.projectId,
          assetIds: response?.assetIds,
          trackingId: response?.trackingId,
          name: this.missionFormData?.missionName,
          objective: this.missionFormData.missionObjective
        }
        
        this.createMissionParams.set(params)
      },
    })
  }


  onUpdateMission() {
    const params = {
      siteId: this.missionDetails?.siteId,
      missionId: this.missionDetails?._id,
      missionProjectId: this.missionDetails?.projectId,
      missionAssetId: this.missionDetails?.assetIds,
      trackingId:this.missionDetails?.trackingId,
      name: this.missionDetails?.name,
    } as IMissionDataParams
    this.createMissionParams.set(params)

    const missionPayload = {
      workspaceId: this.missionSharedService.getUserInfo().activeWorkspaceId,
      _id: this.missionDetails?._id,
      objective: this.missionFormData.missionObjective._id,
      name: this.missionFormData.missionName,
      status: MISSION_STATUS.DRAFT,
    }

    this.createMissionService.updateMissionData(this.createMissionParams().missionId, missionPayload).subscribe({
      next: () => {
        this.notificationService.showToast({ type: EventTypes.success, message: NOTIFY_MSG.MISSION_UPDATED });
      },
    })
  }

  async onRunWorkFlow() {
    this.isWorkFlowStart = true;
    const missionDataParams = this.createMissionParams()
    let workflowStepList: WorkflowDef[] = [];
    let payload!: any;
    this.selectedWorkflowDef.workflowSteps.forEach((step: any) => {
      step.displayName = step.stepName;
      workflowStepList.push(step);
    });

    payload = {
      workspaceId: this.getUserInfo().activeWorkspaceId,
      name: this.selectedWorkflowDef.name,
      workflowDefId: this.selectedWorkflowDef._id,
      airflowDagId: this.selectedWorkflowDef.airflowDagId,
      missionId: missionDataParams.missionId,
      workflowSteps: this.selectedWorkflowDef.workflowSteps,
    };

    let workflowResponse = await lastValueFrom(this.missionWorkflowService
      .runWorkflowForMission(payload))

    let workflowRunResponse = await lastValueFrom(this.missionWorkflowService
      .startWorkflowForMission(workflowResponse))

    if (workflowRunResponse._id) {
      this.workflowSharedService.setWorkflowById({
        id: workflowRunResponse?._id
      });

      this.workflowSharedService.getWorkflowProgressById(
        workflowRunResponse?._id
      );
      this.onCloseMissionCreation()
    }
  }

  onGetSelectedAssetId(assetIdList: string[]) {
    this.selectedAssetIdList = assetIdList
  }

  onSetAssetIdToMission() {
    const assetIds = this.selectedAssetIdList
    this.createMissionService.updateMissionData(this.createMissionParams().missionId, { assetIds }).subscribe({
      next: (data) => {
        this.notificationService.showToast({ type: EventTypes.success, message: NOTIFY_MSG.MISSION_UPDATED });
      },
      error: (error) => {
        this.notificationService.showToast({ type: EventTypes.error, message: NOTIFY_MSG.MISSION_UPDATED_FAILED });
      }
    })
  }
}
