import { Pipe, PipeTransform } from '@angular/core';
import { FilesProgressList } from '../../interfaces/file-upload-dataset.interfaces';

@Pipe({
  name: 'isUploadCompleted',
})
export class IsUploadCompletedPipe implements PipeTransform {

  transform(progressList: FilesProgressList[], element?: HTMLDivElement): number {
    const uploadedFile = progressList.filter((data) => data.progress == 100).length;
    const isCompleted = uploadedFile == progressList.length
    element && this.onCloseProgress(element, isCompleted)
    return uploadedFile;
  }

  onCloseProgress(element: HTMLDivElement, isCompleted: boolean) {
    if (isCompleted) {
      setTimeout(() => {
        element.style.display = 'none'
      }, 5000)
    } else {
      element.style.display = 'flex'
    }
  }
}
