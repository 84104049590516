import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssetUrlPipe } from './pipes/asset-url.pipe';
import { DropStyleDirective } from './directives/dropStyle.directive';
import { ShowHideUploadDatasetPipe } from './pipes/show-hide-upload-dataset.pipe';
import { FileReaderPipe } from './pipes/file-reader.pipe';
import { CustomNotificationComponent } from './components/custom-notification/custom-notification.component';
import { ToasterNotifyComponent } from './components/toaster-notify/toaster-notify.component';
import { AssetHierarchyArrayPipe } from './pipes/asset-hierarchy-array.pipe';
import { UploadProgressPipe } from './pipes/upload-progress.pipe';
import { OverallUploadTimeRemainingPipe } from './pipes/overall-upload-time-remaining.pipe';
import { IsUploadCompletedPipe } from './pipes/is-upload-completed.pipe';
import { WorkflowProgressPipe } from './pipes/workflow-progress.pipe';

const Module = [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule];

const Component = [CustomNotificationComponent, ToasterNotifyComponent];

const Pipes = [
  AssetUrlPipe,
  ShowHideUploadDatasetPipe,
  FileReaderPipe,
  AssetHierarchyArrayPipe,
  UploadProgressPipe,
  OverallUploadTimeRemainingPipe,
  IsUploadCompletedPipe,
  WorkflowProgressPipe,
];

const Directives = [DropStyleDirective];

@NgModule({
  declarations: [...Component, ...Pipes, ...Directives],
  imports: [...Module],
  exports: [...Module, ...Component, ...Pipes, ...Directives],
})
export class SharedModule {}
