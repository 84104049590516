<div class="missionDetail-wrapper">
  <h3 class="title">Uploading new datasets</h3>
  <div class="progress-holder">
    <ul class="progress-list">
      <li class="active"><span class="bullet-step">Mission Detail</span></li>
      <li><span class="bullet-step">Upload Dataset</span></li>
    </ul>
  </div>
  <div class="form-holder">
    <form [formGroup]="createMissionForm" (ngSubmit)="onSumbitInfo()">
      <div class="form-group">
        <label for="missionName" class="label">Mission Name</label>
        <div class="input-group">
          <input formControlName="missionName" class="form-control white-text" autocomplete="off" placeholder="" id="missionName">
          <div class="input-group-append"></div>
        </div>
      </div>
      <div class="form-group">
        <label class="label" for="missionObj">Mission Objective<em class="required">*</em></label>
        <select id="missionObj" class="form-control mission-field" formControlName="missionObj"
          placeholder="Choose Mission Objective" (change)="changeMissionObjective($event)">
          <option selected value="">Choose Mission Objective</option>
          <option *ngFor="let mObj of missionObjectiveRecord" value="{{mObj._id}}">{{mObj.name}}</option>
        </select>
        <div
          *ngIf="createMissionForm.controls['missionObj'].invalid && (createMissionForm.controls['missionObj'].dirty || createMissionForm.controls['missionObj'].touched)"
          class="error-msg">
          Objective Name is required
        </div>
      </div>
        <div class="form-group">
        <label class="label" for="workflowDef">Workflow<em class="required">*</em></label>
        <select id="workflow" class="form-control" formControlName="workflowDef" placeholder="Choose Workflow">
          <option selected value="">Choose Workflow</option>
          <option *ngFor="let workflow of workflows" [ngValue]="workflow">{{ workflow.name }}</option>
        </select>
        <div
          *ngIf="createMissionForm.controls['workflowDef'].invalid && (createMissionForm.controls['workflowDef'].dirty || createMissionForm.controls['workflowDef'].touched)"
          class="error-msg">
          Workflow is required
        </div>
      </div>
      <div class="btn-holder">
        <button type="button" class="btn btn-outline" (click)="initUploadDatasetComponent(false)">
          Cancel
        </button>
        <button type="submit" [disabled]="createMissionForm.invalid || isNextClick" class="btn btn-default">Next</button>
      </div>
    </form>
  </div>
</div>
<ng-template [ngIf]="isShowToaster">
  <app-utm-toaster-custom [toasterClass]="toasterClass" [toasterMsg]="toasterMsg"></app-utm-toaster-custom>
</ng-template>