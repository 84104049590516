import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileReader'
})
export class FileReaderPipe implements PipeTransform {

  transform(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!file) {
        return reject(new Error('No file provided'));
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          if (!ctx) {
            return reject(new Error('Failed to get canvas context'));
          }

          canvas.width = 78;
          canvas.height = 78;
          ctx.drawImage(img, 0, 0, 78, 78);
          const dataURL = canvas.toDataURL('image/jpeg', 1);
          resolve(dataURL);
        };

        img.onerror = () => reject(new Error('Error loading image'));

        img.src = reader.result as string;
      };

      reader.onerror = () => reject(new Error('Error reading file'));
      reader.readAsDataURL(file);
    });
  }


}
