import { Component, Input, OnInit, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectedFile } from '../../../interfaces/file-upload-dataset.interfaces';
import { FOLDER_FILE } from '../../../constants/create-mission.const';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrl: './delete-modal.component.scss'
})
export class DeleteModalComponent implements OnInit {
  @Input({ required: true }) fileDetails: SelectedFile;
  ngbActiveModalRef = inject(NgbActiveModal)

  FOLDER_FILE = FOLDER_FILE
  isFolder = false
  FileName: string | number = '';

  ngOnInit(): void {
      this.isFolder = this.fileDetails.isFolder
      this.FileName = this.fileDetails.selectedFile
  }

  onConformModal() {
    this.ngbActiveModalRef.close(true);
  }

  closeModal() {
    this.ngbActiveModalRef.close();
  }

  isMoreThenOneFile() {
    return typeof this.fileDetails.selectedFile == 'number'
  }
}
