import { Injectable, Input } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, map, throwError } from "rxjs";
import { Routes } from "./routes";
import { Observable, of, Subject } from 'rxjs';
import { ICreateDataResource, ICreateMissionSiteData } from '../models/create-mission-dataset.model';
import { CONSTANTS_MISSION } from 'projects/data-upload/src/app/constants/create-mission.const';
import { WorkflowDef } from '../models/mission-detail.model';
@Injectable({
  providedIn: 'root'
})
export class UploadDatasetService {
  @Input() isHidemission!: boolean;
  downloadList: any[] = [];

  datasetHeadExpand = new BehaviorSubject('');
  getWorkflowData = new BehaviorSubject<WorkflowDef | ''>('')

  checkUploadedFileList = new BehaviorSubject('');
  checkFileIsAvailable = new BehaviorSubject('');
  constructor(private httpClient: HttpClient) { }

  public uploadDataset(src: any, file: any): Observable<any> {
    return of('ok');
  }

  public createDatasetFolder(folderName: any): Observable<any> {
    const rnd = Math.round(Math.random() * CONSTANTS_MISSION.CREATE_FOLDER_COUNT);
    return of(rnd);
  }

  getCredentials = (missionId: string, projectId: string | null = null): Observable<any> => {
    let createDataResUrl = `${Routes.CREATE_DATA_RESOURCE_URL}/credentials`;
    if (missionId && projectId) {
      createDataResUrl = `${createDataResUrl}?project_id=${projectId}&mission_id=${missionId}`;
    }
    else if (missionId) {
      createDataResUrl = `${createDataResUrl}?mission_id=${missionId}`;
    }
    return this.httpClient.get(createDataResUrl).pipe(map(res => res));
  };

  createDataResources(data: ICreateDataResource): Observable<ICreateDataResource[]> {
    return this.httpClient.post<ICreateDataResource[]>(Routes.CREATE_DATA_RESOURCE_URL, data);
  }
  deleteDataResourcesBulk(data: any): Observable<any[]> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { ids: data },
    };
    return this.httpClient.request<any[]>('delete', Routes.BULK_DATA_RESOURCE_URL, options);
  }
  updateDataResources(data: ICreateDataResource): Observable<ICreateDataResource[]> {
    return this.httpClient.put<ICreateDataResource[]>(Routes.CREATE_DATA_RESOURCE_URL, data);
  }

  getImgFromDataResource(resourceId: number): Observable<ICreateMissionSiteData[]> {
    return this.httpClient.get<ICreateMissionSiteData[]>(Routes.CREATE_DATA_RESOURCE_URL + '/' + resourceId + '?pre_signed_url=true').pipe(map(res => res));
  }

  updateSitePlaceholder(siteId: number, siteInfo: any): Observable<any> {
    return this.httpClient.patch<any>(Routes.UPDATE_SITE_INFO + "/" + siteId, siteInfo).pipe(
      catchError((err) => {
        return throwError(err);
      })
    )
  }

  getDataResource({
    projectId,
    preSignedUrl,
    workspaceId,
    type = 'f01',
  }: {
    projectId: string | null;
    resourceType?: string;
    preSignedUrl: boolean;
    workspaceId: string;
    type: 'f01' | 'misc';
  }): Observable<any> {
    let queryParams = `?pre_signed_url=${preSignedUrl}&pageLimit=10000`;
    if (projectId) queryParams += `&projectId=${projectId}`;
    if (workspaceId) queryParams += `&workspaceId=${workspaceId}`;
    if (type === 'f01') {
      queryParams += `&tags=F01`;
    } else {
      queryParams += `&tags=MISC`;
    }
    return this.httpClient.get<any>(Routes.CREATE_DATA_RESOURCE_URL + queryParams);
  }

  async getArrayBufferFromPreSignedUrl(preSignedUrl: string, idx: number, subjectArray: Subject<number>[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', preSignedUrl, true);
      xhr.responseType = 'arraybuffer';
      xhr.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          subjectArray[idx].next(percentComplete);
        }
      }
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject(new Error('Request failed'));
        }
      };
      xhr.onerror = () => {
        reject(new Error('Request failed'));
      };
      xhr.send();
    })
  }
}
