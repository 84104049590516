import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { httpCustomInterceptor } from 'fe-micro-share-components';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { UploadProgressbarComponent } from './components/upload-progressbar/upload-progressbar.component';
import { CreateMissionComponent } from './components/create-mission/create-mission.component';
import { MissionDetailComponent } from './components/mission-detail/mission-detail.component';
import { ToasterCustomComponent } from './components/toaster-custom/toaster-custom.component';
import { UploadFilesViewComponent } from './components/upload-files-view/upload-files-view.component';
import { UploadNewDatasetComponent } from './components/upload-new-dataset/upload-new-dataset.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { MissionConfirmationPopupComponent } from './components/mission-confirmation-popup/mission-confirmation-popup.component';
import { FilesUploadingProgressComponent } from './components/files-uploading-progress/files-uploading-progress.component';
import { IsGrantedDirective } from './user-permissions/user-permission.directive';
import { FilesDownloadingProgressComponent } from './components/files-downloading-progress/files-downloading-progress.component';
import { UploadDatasetPiplineGridComponent } from './upload-dataset-pipline-grid/upload-dataset-pipline-grid.component';
import { UploadFilesComponent } from './components/create-mission/upload-files/upload-files.component';
import { UploadProgressbarDataComponent } from './components/upload-progressbar-data/upload-progressbar-data.component';
import { GridFilesUploadingProgressComponent } from './components/grid-files-uploading-progress/grid-files-uploading-progress.component';
import { ToasterUploadDatasetComponent } from './toaster-upload-dataset/toaster-upload-dataset.component';
import { AssetSelectionComponent } from './mission-creation/asset-selection/asset-selection.component';
import { CreateNewMissionComponent } from './mission-creation/create-new-mission/create-new-mission.component';
import { CustomToasterComponent } from './mission-creation/custom-toaster/custom-toaster.component';
import { NewMissionDetailComponent } from './mission-creation/new-mission-detail/new-mission-detail.component';
import { AdditionalImageDataComponent } from './mission-creation/upload-dataset/additional-image-data/additional-image-data.component';
import { DroneImageDataComponent } from './mission-creation/upload-dataset/drone-image-data/drone-image-data.component';
import { OrthomosaicDataComponent } from './mission-creation/upload-dataset/orthomosaic-data/orthomosaic-data.component';
import { Panorama360DataComponent } from './mission-creation/upload-dataset/panorama-360-data/panorama-360-data.component';
import { ThreeDDataComponent } from './mission-creation/upload-dataset/three-d-data/three-d-data.component';
import { UploadDatasetComponent } from './mission-creation/upload-dataset/upload-dataset.component';
import { VideoDataComponent } from './mission-creation/upload-dataset/video-data/video-data.component';
import { UploadFolderPreviewComponent } from './mission-creation/upload-folder-preview/upload-folder-preview.component';
import { UploadingProgressBarComponent } from './mission-creation/uploading-progress-bar/uploading-progress-bar.component';
import { WorkflowProgressBarComponent } from './mission-creation/workflow-progress-bar/workflow-progress-bar.component';
import { DeleteModalComponent } from './shared/components/delete-modal/delete-modal.component';
import { DragDropComponent } from './mission-creation/upload-dataset/drag-drop/drag-drop.component';

@NgModule({
  declarations: [
    AppComponent,
    UploadProgressbarComponent,
    CreateMissionComponent,
    MissionDetailComponent,
    ToasterCustomComponent,
    UploadFilesViewComponent,
    UploadNewDatasetComponent,
    EmptyRouteComponent,
    MissionConfirmationPopupComponent,
    FilesUploadingProgressComponent,
    IsGrantedDirective,
    FilesDownloadingProgressComponent,
    UploadDatasetPiplineGridComponent,
    UploadFilesComponent,
    UploadProgressbarDataComponent,
    GridFilesUploadingProgressComponent,
    ToasterUploadDatasetComponent,
    UploadDatasetComponent,
    ThreeDDataComponent,
    VideoDataComponent,
    AdditionalImageDataComponent,
    DroneImageDataComponent,
    OrthomosaicDataComponent,
    Panorama360DataComponent,
    UploadFolderPreviewComponent,
    CreateNewMissionComponent,
    NewMissionDetailComponent,
    AssetSelectionComponent,
    CustomToasterComponent,
    UploadingProgressBarComponent,
    WorkflowProgressBarComponent,
    DeleteModalComponent,
    DragDropComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: httpCustomInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
