export const CONSTANTS_MISSION = {
  'droneImgTv': 'TV',
  'droneImgLos': 'LOS',
  'droneImgTD': 'TD',
  'droneImageSV': 'SV',
  'droneImgNadir': 'Nadir',
  'droneImgSubfolder': 'DIF',
  'datasetCP': 'CP',
  'datasetDT': 'DT',
  'datasetDI': 'DI',
  'datasetF01': 'F01',
  'datasetMisc': 'MISC',
  'PROGRESS_PERCENTAGE': 100,
  'MULTIPART_FILE_SIZE': 200 * 1024 * 1024,
  'MULTIPART_CHUNK_SIZE': 50 * 1024 * 1024,
  'FILE_KBS': 1024,
  'CREATE_FOLDER_COUNT': 100000,
  'PIPELINE_SIZE': 2,
  'API_TIMER': 60000,
  'ZIP_GENERERATION_PERCENTAGE': 99,
  'USER_CLIENT': 'Amplitel',
  'ORTHOMOSAIC': 'ORTHOMOSAIC',
  'ORTHO_CP': 'ORTHO_CP',
  'DRONE_IMAGE': 'DRONE_IMAGE',
  'VIDEO': 'VIDEO',
  'PANORAMA': 'PANORAMA',
  'VIRTUAL_TOUR': 'VIRTUAL_TOUR',
  'ADDITIONAL_IMAGES': 'ADDITIONAL_IMAGES',
}

export const MISSION_STATUS = {
  'DRAFT': 'DRAFT',
  'ACTIVE': 'ACTIVE',
  'PENDING': 'PENDING',
  'COMPLETED': 'COMPLETED'
}
export const DELETE_MODAL = {
  'POP_HEADING': 'Delete Folder?',
  'POP_DESCRIPTION': 'The folder will  be replaced.'
}

export const DUPLICATE_UPLOAD_MODAL = {
  'POP_HEADING': 'File already Exist',
  'POP_DESCRIPTION': 'File already Exist.'
}

export const NOTIFY_MSG = {
  'ERROR_CLASS': 'toaster-error',
  'SUCCESS_CLASS': 'toaster-success',
  'INVALID_FILE_XML': 'Please drop XML format only',
  'INVALID_FILE_FORMAT': 'Invalid file format!',
  'INVALID_FOLDER_FORMAT': 'Invalid Folder Name!',
  'SUPPORTED_FILE_NOT_FOUND': 'No supported files found!',
  'WORKFLOW_FAILED': 'Workflow run cannot be processed due to failure.',
  'WORKFLOW_START': 'Workflow run start successfully.',
  'ERROR_UNDEFINED': 'undefined',
  'MISSION_CREATED': 'Mission created successfully.',
  'MISSION_FAILED': 'Mission not created.',
  'MISSION_UPDATED': 'Mission update successfully.',
  'MISSION_UPDATED_FAILED': 'Mission not update.',
  'UPLOAD_FAILED': 'Credentials access failed. Please try again.',
  'UPLOAD_CAMERA_PARAM': 'Successfully uploaded Camera Parameters.',
  'UPLOAD_DIGITAL_TWIN': 'Successfully uploaded Digital Twin.',
  'UPLOAD_TOWER_VIEW': 'Successfully uploaded Tower View images.',
  'UPLOAD_LINE_SIGHT': 'Successfully uploaded Line of Sight images.',
  'UPLOAD_TOP_DOWN': 'Successfully uploaded Side View images.',
  'UPLOAD_NADIR': 'Successfully uploaded Nadir images.',
  'WORKFLOW_CANCELLED': 'Workflow has been cancelled successfully.',
  'NO_MISSION_COMPLETED': 'Project does not have completed mission',
  'UPLOAD_DUPLICATE': 'File is Already Exists,Try the new file to upload',
  'DATA_READY': 'Your data is ready',
  'FILE_DELETE_SUCCESS':'File deleted successfully',
  'FILE_DELETE_FAILED':'File not deleted'
}

export const DRONE_IMG_TYPE = {
  'DI_FTV': 'FTV',
  'DI_L1AD': 'L1AD',
  'DI_L1AV': 'L1AV',
  'DI_CV': 'CV',
  'DI_L1LOS': 'L1LOS',
  'DI_TBA': 'TBA',
  'DI_TBB': 'TBB',
  'DI_TBC': 'TBC',
  'TOWER_VIEW': 'tower_view',
  'LINE_OF_SIGHT': 'line_of_sight',
  'TOP_TO_DOWN': 'top_to_down',
  'SIDE_VIEW': 'side_view',
  'FROM_ABOVE_VIEW': 'from_above_view',
  'DIGITAL_TWIN': 'digital_twin',
  'DIGITAL_TWIN_TILE': 'digital_twin_tile',
  'ORIG_FULL_RES': 'orig_full_res'
}

export const WORKFLOW_STATUS = {
  'NOT_STARTED': 'not_started',
  'QUEUED': 'queued',
  'RUNNING': 'running',
  'COMPLETED': 'completed',
  'FAILED': 'failed',
  'ABORTED': 'aborted'
}

export const WORKFLOW_STEPS = {
  'RESOURCE_INGESTION': 'ResourceIngestion',
  'DIGITAL_TWIN_OPT': 'DigitalTwinOptimization',
}

export const MISSION_DATA_TYPES = {
  'AUXILIARY_DATA': "Auxiliary Data",
}

export const ASSETS_NAMES = {
  'TICK_ICON': '../../../assets/images/tick.svg',
  'CROSS_ICON': '../../../assets/images/cross.svg',
  'UPLOADED_FOLDER': '/images/uploadedFolder.png',
  'DOWNLOAD_ICON': '/images/download.svg',
  'DEFAULT_FOLDER': '/images/default_folder.png',
  'FOLDER': '/images/folders.png'
}

export const MISSION_PGB = {
  "PIPLINE_SURVEILLANCE": "ROW Surveillance - Pipeline",
  "GRID_INSPECTION": "CBM Inspection - GRID"
}

export const CONNECTION_STATUS = {
  'GOOD_CONN': 'Good Connection',
  'BAD_CONN': 'Bad Connection'
}

export const FILES_VALIDITY = {
  'VALID_EXTENSIONS': ['.obj', '.mtl', '.jpg', '.xml', '.jpeg', '.png', 'heic', '.zip'],
  'REQUIRED_FILE_EXTENSIONS': {
    ['visual']:
    {
      exclusiveSet: [],
      inclusiveSet: ['.jpg', 'heic', 'jpeg'],
      mode: 'strict'
    }
  }
}


export const BUTTON_LABEL = {
  UPLOAD: 'Upload',
  CANCEL: 'Cancel',
  NEXT: 'Next',
  BACK: 'Back',
}

export const MISSION_TAB_LABEL = {
  MISSION_DETAILS: 'MISSION DETAILS',
  UPLOADING_NEW_DATASET: 'UPLOADING NEW DATASET',
  ASSET_SELECTION: 'ASSET SELECTION',
}


export const MISSION_DETAIL_SELECT_LABEL = {
  MISSION_OBJECTIVE: 'missionObjective',
  WORKFLOW_DEF: 'workflowDef',
}

export const DRONE_IMAGE_LABEL = {
  TOWER_VIEW_UPLOADED_FILE: 'towerViewUploadedFiles',
  LINE_OF_SIGHT_UPLOADED_FILE: 'lineOfSightUploadedFiles',
  TOP_TO_DOWN_UPLOADED_FILE: 'topToDownUploadedFiles',
  NADIR_UPLOADED_FILE: 'nadirUploadedFiles',
}

export const THREE_D_DATA = {
  INPUT_CAMERA_PARAM: 'inputCameraParam',
  INPUT_3D_MODEL: 'input3DModel',
  INPUT_TOWER_VIEW: 'inputTowerView',
  INPUT_LINE_OG_SIGHT: 'inputLineOfSight',
  INPUT_TOP_TO_DOWN: 'inputTopToDown',
  INPUT_NADIR: 'inputNadir',
}

export const PANORAMA_360 = {
  INPUT_PANORAMA: 'inputPanorama',
  INPUT_VIRTUAL_TOUR: 'inputVirtualTour',
}

export const THREE_D_DATA_SUB_HEADER = {
  CAMERA_PARAMS: 'Camera Param',
  THREE_D_MODEL: '3D Model',
  DRONE_IMAGES: 'Drone Images',
}
export const PANORAMA_360_SUB_HEADER = {
  PANORAMA: 'Panorama',
  VIRTUAL_TOUR: 'Virtual Tour',
}
export const ORTHOMOSAIC_SUB_HEADER = {
  CAMERA_PARAMS: 'Camera Param',
  ORTHOMOSAIC: 'Orthomosaic',
}

export const UPLOAD_DATASET_HEADER = {
  THREE_D_DATA: '3D DATA',
  ORTHOMOSAIC_DATA: 'ORTHOMOSAIC DATA',
  DRONE_IMAGE: 'DRONE IMAGE',
  VIDEO: 'VIDEO',
  PANORAMA_360: '360 TOUR',
  ADDITIONAL_IMAGES: 'ADDITIONAL IMAGES',
}

export const RESOURCE_TYPE = {
  THREE_D_DATA: ['cameraParams', 'meshObj', 'meshObjTile'],
  ORTHOMOSAIC: ['orthoRGB', 'orthoRGBTiles'],
  DRONE_IMAGE: ['image'],
  VIDEO: ['videoRGB'],
  PANORAMA_360: ['panoRGB'],
}

export const MISSION_TAP_COLOR = {
  ACTIVE: 'rgb(0, 240, 255)',
  COMPLETE: 'rgb(6, 249, 164)',
  INITIAL: 'rgb(176, 190, 197)'
}

export const TIME_DELAY = {
 ZERO:0
}
export const FOLDER_FILE = {
  FILE: 'File',
  FILES:'Files',
  FOLDER:'Folder'
}

