import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  getApiUrl() {
    return environment.apiHost
  }
  getMapboxAccessToken() {
    return environment.mapbox.accessToken
  }
  getToken() {
    return environment.token
  }
  getAAToken() {
    return environment.AAToken
  }
  getAAUrl() {
    return environment.AAURL
  }
  getMeteomaticUser() {
    return environment.MeteomaticUser
  }
  getMeteomaticKey() {
    return environment.Meteomatickey
  }
  getAirmap() {
    return environment.Airmap
  }
  getAuthAppSiteKey() {
    return environment.AUTH_APP_SITE_KEY
  }
}
