<div class="custom-toaster toast align-items-center toaster-success show"
  [ngClass]="hideToast === true ? 'dismiss' : ''" role="alert" aria-live="assertive" aria-atomic="true">
  <div class="d-flex">
    <div class="image-align"></div>
    <div class="toast-body">
      <div class="header">
        <h2 class="heading">Your data is ready</h2>
        <!-- <span class="time">50s ago</span> -->
      </div>
      <p class="content">AI Plotting process completed.</p>
      <div class="btn-holder">
        <button class="btn" (click)="dismissToast()">Dismiss</button>
        <button class="btn active" (click)="goToMissionList()">Take me there</button>
      </div>
    </div>
  </div>
</div>