<div class="upload-container" [ngClass]="headExpand == UPLOAD_DATASET_HEADER.THREE_D_DATA ? 'show-upload-body' : ''">
  <div class="upload-head"
    (click)="onHeadExpand(headExpand == UPLOAD_DATASET_HEADER.THREE_D_DATA?'':UPLOAD_DATASET_HEADER.THREE_D_DATA)">
    <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
    <p>{{UPLOAD_DATASET_HEADER.THREE_D_DATA}}</p>
    @if(isFileUploadedToChild()){
      <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
    }
  </div>
  <div class="upload-body-container">
    <div class="upload-body"
      [ngClass]="subHeadExpand == THREE_D_DATA_SUB_HEADER.CAMERA_PARAMS ? 'show-dagAndDrop' : ''">
      <div class="upload-body-head"
        (click)="onSetSubHead(THREE_D_DATA_SUB_HEADER.CAMERA_PARAMS)">
        <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
        <p>{{THREE_D_DATA_SUB_HEADER.CAMERA_PARAMS}}</p>
        @if(cameraParamUploadedFiles){
          <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
        }
      </div>
      <app-drag-drop (getUploadedFile)="filesDropped($event)" [datasetHeader]="THREE_D_DATA.INPUT_CAMERA_PARAM" [fileType]="MISSION_CONSTANTS.datasetCP"
      [supportedFile]="'Supported format: .xml only'" [showDragDrop]="subHeadExpand == THREE_D_DATA_SUB_HEADER.CAMERA_PARAMS"></app-drag-drop>
    </div>
    <div class="upload-body"
      [ngClass]="subHeadExpand == THREE_D_DATA_SUB_HEADER.THREE_D_MODEL ? 'show-dagAndDrop' : ''">
      <div class="upload-body-head"
        (click)="onSetSubHead(THREE_D_DATA_SUB_HEADER.THREE_D_MODEL)">
        <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
        <p>{{THREE_D_DATA_SUB_HEADER.THREE_D_MODEL}}</p>
        @if(threeDModelUploadedFiles){
          <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
        }
      </div>
      <app-drag-drop (getUploadedFile)="filesDropped($event)" [datasetHeader]="THREE_D_DATA.INPUT_3D_MODEL" [fileType]="MISSION_CONSTANTS.datasetDT"
      [supportedFile]="'Supported formats: .obj, .mtl, .jpg'" [disableFileSelect]="true" [showDragDrop]="subHeadExpand == THREE_D_DATA_SUB_HEADER.THREE_D_MODEL"></app-drag-drop>
    </div>
    <div class="upload-body" [ngClass]="subHeadExpand == THREE_D_DATA_SUB_HEADER.DRONE_IMAGES ? 'show-dagAndDrop' : ''">
      <div class="upload-body-head"
        (click)="onSetSubHead(THREE_D_DATA_SUB_HEADER.DRONE_IMAGES)">
        <img [src]="'/images/chevron-Right.png' | assetUrl" alt="chevron" />
        <p>{{THREE_D_DATA_SUB_HEADER.DRONE_IMAGES}}</p>
        @if(towerViewUploadedFiles.length || lineOfSightUploadedFiles.length || topToDownUploadedFiles.length || nadirUploadedFiles.length){
          <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
        }
      </div>
      <div class="upload-dagAndDrop">
        <div class="upload-dagAndDrop-body drone-image">
          <div #droneImageContainer class="drone-image-container">
            <div class="drone-image-allFolder">
              <p class="all-folder-label" (click)="onRemoveAllClass()">All folder</p>
              <div class="tower-view-label">
                <p>&gt; Tower View </p>
                @if(towerViewUploadedFiles.length){
                <div class="drone-image-delete">
                  <div class="checkbox-container">
                    <input #towerViewCheckbox type="checkbox" id="towerViewCheckbox" (change)="onSelectAllDroneImagesFile(towerViewCheckbox,'towerView')">
                    <img [src]="(towerViewCheckbox.checked?'/images/checked.svg':'/images/un-check.svg') | assetUrl" alt="checkBox" />
                  </div>
                  <label class="drone-image-select-all-label" for="towerViewCheckbox">Select All</label>
                  @if(towerViewSelectedFiles.length){
                    <img [src]="'/images/delete.svg' | assetUrl" alt="delete" (click)="onDeleteModalOpen(MISSION_CONSTANTS.droneImgTv,'towerView')"/>
                  }
                </div>
              }
              </div>
              <div class="line-of-sight-label">
                <p>&gt; Line Of Sight </p>
                @if(lineOfSightUploadedFiles.length){
                <div class="drone-image-delete">
                  <div class="checkbox-container">
                    <input #lineOfSightCheckbox type="checkbox" id="lineOfSightCheckbox" (change)="onSelectAllDroneImagesFile(lineOfSightCheckbox,'lineOfSight')">
                    <img [src]="(lineOfSightCheckbox.checked?'/images/checked.svg':'/images/un-check.svg') | assetUrl" alt="checkBox" />
                  </div>
                  <label class="drone-image-select-all-label" for="lineOfSightCheckbox">Select All</label>
                  @if(lineOfSightSelectedFiles.length){
                    <img [src]="'/images/delete.svg' | assetUrl" alt="delete" (click)="onDeleteModalOpen(MISSION_CONSTANTS.droneImgLos,'lineOfSight')"/>
                  }
                </div>
                }
              </div>
              <div class="top-to-down-label">
                <p>&gt; Top To Down </p>
                @if(topToDownUploadedFiles.length){
                <div class="drone-image-delete">
                  <div class="checkbox-container">
                    <input #topToDownCheckbox type="checkbox" id="topToDownCheckbox" (change)="onSelectAllDroneImagesFile(topToDownCheckbox,'topToDown')">
                    <img [src]="(topToDownCheckbox.checked?'/images/checked.svg':'/images/un-check.svg') | assetUrl" alt="checkBox" />
                  </div>
                  <label class="drone-image-select-all-label" for="topToDownCheckbox">Select All</label>
                  @if(topToDownSelectedFiles.length){
                    <img [src]="'/images/delete.svg' | assetUrl" alt="delete" (click)="onDeleteModalOpen(MISSION_CONSTANTS.droneImageSV,'topToDown')"/>
                  }
                </div>
                }
              </div>
              <div class="nadir-label">
                <p>&gt; NADIR </p>
                @if(nadirUploadedFiles.length){
                <div class="drone-image-delete">
                  <div class="checkbox-container">
                    <input #nadirCheckbox type="checkbox" id="nadirCheckbox" (change)="onSelectAllDroneImagesFile(nadirCheckbox,'nadir')">
                    <img [src]="(nadirCheckbox.checked?'/images/checked.svg':'/images/un-check.svg') | assetUrl" alt="checkBox" />
                  </div>
                  <label class="drone-image-select-all-label" for="nadirCheckbox">Select All</label>
                  @if(nadirSelectedFiles.length){
                    <img [src]="'/images/delete.svg' | assetUrl" alt="delete" (click)="onDeleteModalOpen(MISSION_CONSTANTS.droneImgNadir,'nadir')"/>
                  }
                </div>
                }
              </div>
            </div>
            <div class="drone-image-folder-container">
              @for(folder of droneImagesFolder;track folder){
                <ng-container *ngTemplateOutlet="droneImagesFolderTemplate; context: folder"></ng-container>
              }
            </div>
            <div class="drone-image-dragAndDrop tower-view-upload">
              <div class="upload-preview-container">
                @if(towerViewUploadedFiles.length){
                @for(towerViewFile of towerViewUploadedFiles;track towerViewFile){
                <ng-container
                  *ngTemplateOutlet="imagePreviewTemplate;
                  context: { $implicit: towerViewFile, selectedFiles: towerViewSelectedFiles, type: 'towerView' }">
                </ng-container>
                }
                }
              </div>
              <div class="upload-dragAndDrop">
                <input #inputTowerView title=" " (click)="inputTowerView.value = ''" dropStyle [parentFileType]="MISSION_CONSTANTS.datasetDI"
                  (isValidFile)="checkFileValid($event, THREE_D_DATA.INPUT_TOWER_VIEW)" (change)="onSentUploadedFile(
                inputTowerView.files,
                MISSION_CONSTANTS.droneImgTv,
                MISSION_CONSTANTS.datasetDI
              )" id="inputTowerView" type="file" multiple webkitdirectory mozdirectory />
                <div class="drag-and-drop-text-container">
                  <img [src]="'/images/cloud_upload.svg' | assetUrl" alt="cloud_upload"
                    [ngClass]="!isValidTowerView?'not-valid-file':''">
                  <p><span class="click-to-upload">Click to upload folder</span><span>&nbsp; or &nbsp;drag and drop</span></p>
                  @if(!isValidTowerView){
                  <p>Wrong file format. Please upload file that is</p>
                  }
                  <p>Supported formats: .jpeg, .png, .jpg</p>
                </div>
              </div>
            </div>
            <div class="drone-image-dragAndDrop line-of-sight-upload">
              <div class="upload-preview-container">
                @if(lineOfSightUploadedFiles.length){
                  @for(lineOfSightFile of lineOfSightUploadedFiles;track lineOfSightFile){
                    <ng-container
                      *ngTemplateOutlet="imagePreviewTemplate;
                      context: { $implicit: lineOfSightFile, selectedFiles: lineOfSightSelectedFiles, type: 'lineOfSight' }">
                    </ng-container>
                  }
                }
              </div>
              <div class="upload-dragAndDrop">
                <input #inputLineOfSight title=" " (click)="inputLineOfSight.value = ''" dropStyle [parentFileType]="MISSION_CONSTANTS.datasetDI"
                  (isValidFile)="checkFileValid($event, THREE_D_DATA.INPUT_LINE_OG_SIGHT)" (change)="onSentUploadedFile(
                inputLineOfSight.files,
                MISSION_CONSTANTS.droneImgLos,
                MISSION_CONSTANTS.datasetDI
              )" id="inputLineOfSight" type="file" multiple webkitdirectory mozdirectory />
                <div class="drag-and-drop-text-container">
                  <img [src]="'/images/cloud_upload.svg' | assetUrl" alt="cloud_upload"
                    [ngClass]="!isValidLineOfSight?'not-valid-file':''">
                  <p><span class="click-to-upload">Click to upload folder</span><span>&nbsp; or &nbsp;drag and drop</span></p>
                  @if(!isValidLineOfSight){
                  <p>Wrong file format. Please upload file that is</p>
                  }
                  <p>Supported formats: .jpeg, .png, .jpg</p>
                </div>
              </div>
            </div>
            <div class="drone-image-dragAndDrop top-to-down-upload">
              <div class="upload-preview-container">
                @if(topToDownUploadedFiles.length){
                  @for(topToDownFile of topToDownUploadedFiles;track topToDownFile){
                  <ng-container
                    *ngTemplateOutlet="imagePreviewTemplate;
                    context: { $implicit: topToDownFile, selectedFiles: topToDownSelectedFiles, type: 'topToDown' }">
                  </ng-container>
                  }
                }
              </div>
              <div class="upload-dragAndDrop">
                <input #inputTopToDown title=" " (click)="inputTopToDown.value = ''" dropStyle [parentFileType]="MISSION_CONSTANTS.datasetDI"
                  (isValidFile)="checkFileValid($event, THREE_D_DATA.INPUT_TOP_TO_DOWN)" (change)="onSentUploadedFile(
                inputTopToDown.files,
                MISSION_CONSTANTS.droneImageSV,
                MISSION_CONSTANTS.datasetDI
              )" id="inputTopToDown" type="file" multiple webkitdirectory mozdirectory />
                <div class="drag-and-drop-text-container">
                  <img [src]="'/images/cloud_upload.svg' | assetUrl" alt="cloud_upload"
                    [ngClass]="!isValidTopToDown?'not-valid-file':''">
                  <p><span class="click-to-upload">Click to upload folder</span><span>&nbsp; or &nbsp;drag and drop</span></p>
                  @if(!isValidTopToDown){
                  <p>Wrong file format. Please upload file that is</p>
                  }
                  <p>Supported formats: .jpeg, .png, .jpg</p>
                </div>
              </div>
            </div>
            <div class="drone-image-dragAndDrop nadir-upload">
              <div class="upload-preview-container">
                @if(nadirUploadedFiles.length){
                  @for(nadirFile of nadirUploadedFiles;track nadirFile){
                    <ng-container
                      *ngTemplateOutlet="imagePreviewTemplate;
                      context: { $implicit: nadirFile, selectedFiles: nadirSelectedFiles, type: 'nadir' }">
                    </ng-container>
                  }
                }
              </div>
              <div class="upload-dragAndDrop">
                <input #inputNadir title=" " (click)="inputNadir.value = ''" dropStyle [parentFileType]="MISSION_CONSTANTS.datasetDI"
                  (isValidFile)="checkFileValid($event, THREE_D_DATA.INPUT_NADIR)" (change)="onSentUploadedFile(
                inputNadir.files,
                MISSION_CONSTANTS.droneImgNadir,
                MISSION_CONSTANTS.datasetDI
              )" id="inputNadir" type="file" multiple webkitdirectory mozdirectory />
                <div class="drag-and-drop-text-container">
                  <img [src]="'/images/cloud_upload.svg' | assetUrl" alt="cloud_upload"
                    [ngClass]="!isValidNadir?'not-valid-file':''">
                  <p><span class="click-to-upload">Click to upload folder</span><span>&nbsp; or &nbsp;drag and drop</span></p>
                  @if(!isValidNadir){
                  <p>Wrong file format. Please upload file that is</p>
                  }
                  <p>Supported formats: .jpeg, .png, .jpg</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #imagePreviewTemplate let-file let-selectedFiles="selectedFiles" let-type="type">
  @if(file.file | fileReader | async; as fileReaderOutput){
    <a class="image-preview-container" [ngClass]="{'add-border': selectedFiles.includes(file)}" (click)="onSelectDroneImagesFile(file, type)">
      <div class="image-preview">
        <img [src]="fileReaderOutput" alt="uploadedFile">
        <img [src]="'/images/tick.svg' | assetUrl" alt="tick">
      </div>
      <div class="image-name-size">
        <p>{{file.name}}</p>
        <p>{{(file.file.size / (1024 * 1024)) | number: '1.2-2'}} MB</p>
      </div>
    </a>
  }
</ng-template>

<ng-template #droneImagesFolderTemplate let-title="title" let-folderType="folderType" let-uploadedFiles="uploadedFiles">
  <a class="drone-image-folder" (click)="onAddClass(folderType)">
    <img [src]="'/images/folder_gray.png' | assetUrl" alt="folder_gray">
    <div class="drone-image-folder-name">
      <p>{{ title }}</p>
      <p>{{ uploadedFiles?.length ? uploadedFiles.length + ' files' : '' }}</p>
    </div>
  </a>
</ng-template>

