import { Component,Input } from '@angular/core';

@Component({
  selector: 'app-utm-toaster-upload-dataset',
  templateUrl: './toaster-upload-dataset.component.html',
  styleUrls: ['./toaster-upload-dataset.component.scss']
})
export class ToasterUploadDatasetComponent {
  @Input() toasterClass!:string;
  @Input() toasterMsg!:string;
  hideToast = false;

  goToMissionList() {
    const customEvent = new CustomEvent('active-sidebar:mission-list', { detail: { icon:'mission' } })
    window.dispatchEvent(customEvent)
    this.dismissToast();
  }
  
  dismissToast(){
    this.hideToast = true;
  }
}