import { Component, ElementRef, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { CONSTANTS_MISSION, PANORAMA_360, PANORAMA_360_SUB_HEADER, UPLOAD_DATASET_HEADER } from '../../../constants/create-mission.const';
import { FileUploadData } from '../upload-dataset.component';
import { UploadDatasetService } from '../../../services/upload-dataset.service';
import { UtilityService } from '../../../services/utility.service';
@Component({
  selector: 'app-panorama-360-data',
  templateUrl: './panorama-360-data.component.html',
  styleUrl: './panorama-360-data.component.scss'
})
export class Panorama360DataComponent {
  @Output() getUploadedFile = new EventEmitter<FileUploadData>()
  @ViewChild('inputPanorama') inputPanorama: ElementRef<HTMLInputElement>
  @ViewChild('inputVirtualTour') inputVirtualTour: ElementRef<HTMLInputElement>

  utilityService = inject(UtilityService)
  uploadDataServices = inject(UploadDatasetService)

  // const
  UPLOAD_DATASET_HEADER = UPLOAD_DATASET_HEADER
  MISSION_CONSTANTS = CONSTANTS_MISSION;
  PANORAMA_360_SUB_HEADER = PANORAMA_360_SUB_HEADER
  PANORAMA_360 = PANORAMA_360

  headExpand = ''
  subHeadExpand = ''
  isValidPanorama = true;
  isValidVirtualTour = true;
  panoramaUploadedFiles: boolean;
  virtualTourUploadedFiles: boolean;

  ngOnInit() {
    this.uploadDataServices.datasetHeadExpand.subscribe((data) => {
      this.headExpand = data
    })

    this.uploadDataServices.checkFileIsAvailable.subscribe(() => {
      this.panoramaUploadedFiles = this.utilityService.checkUploadFileIsPresent(this.MISSION_CONSTANTS.PANORAMA)
      this.virtualTourUploadedFiles = this.utilityService.checkUploadFileIsPresent(this.MISSION_CONSTANTS.VIRTUAL_TOUR)
    })
  }

  onSentUploadedFile(file: FileList | null, fileType: string, parentFileType: string) {
    const FileData = { file, fileType, parentFileType }
    this.getUploadedFile.emit(FileData)
  }

  onHeadExpand(data: string) {
    this.uploadDataServices.datasetHeadExpand.next(data)
    this.subHeadExpand = ''
  }

  checkFileValid(value: boolean, uploadType: string) {
    if (uploadType == PANORAMA_360.INPUT_PANORAMA) {
      this.isValidPanorama = value
      if (!this.isValidPanorama) {
        this.inputPanorama.nativeElement.value = ''
      }
    }
    if (uploadType == PANORAMA_360.INPUT_VIRTUAL_TOUR) {
      this.isValidVirtualTour = value
      if (!this.isValidVirtualTour) {
        this.inputVirtualTour.nativeElement.value = ''
      }
    }
  }

  onSetSubHead(subHead: string, className: string) {
    if (this.subHeadExpand === subHead) {
      this.subHeadExpand = '';
    } else {
      this.subHeadExpand = subHead;
    }
    this.onScrollIntoView(className)
  }

  onScrollIntoView(className: string) {
    requestAnimationFrame(() => {
      const element = document.querySelector(`.${className}.show-dagAndDrop`);
      element && element.scrollIntoView({ behavior: "smooth", block: "start" });
    });
  }
}
