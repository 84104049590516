import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CONSTANTS_MISSION, DRONE_IMG_TYPE } from 'projects/data-upload/src/app/constants/create-mission.const';

@Component({
  selector: 'app-utm-upload-files-view',
  templateUrl: './upload-files-view.component.html',
  styleUrls: ['./upload-files-view.component.scss']
})
export class UploadFilesViewComponent {
  constructor(public domSanitizer: DomSanitizer) { }
  MISSION_CONSTANTS = CONSTANTS_MISSION;
  CONSTANTS_DI = DRONE_IMG_TYPE;
  @Input() uploadedTvFiles: any;
  @Input() uploadedLosFiles: any;
  @Input() uploadedTbFiles: any;
  @Input() uploadedNadirFiles: any;
  @Input() folderName: any;
  @Input() subFolderTvList: any;
  @Input() subFolderLosList: any;
  @Input() subFolderTbList: any;
  @Output() backToFolderView: EventEmitter<boolean> = new EventEmitter();
  subFolderFiles: any = [];
  ftvFiles: any = [];
  l1adFiles: any = [];
  l1avFiles: any = [];
  cvFiles: any = [];
  l1losFiles: any = [];
  tbaFiles: any = [];
  tbbFiles: any = [];
  tbcFiles: any = [];
  isFolderClicked: Boolean = false;
  getFolderName?: string;

  backToFolder() {
    this.backToFolderView.emit(false);
  }

  backToMainFolder() {
    this.isFolderClicked = false;
    this.getFolderName = "";
  }

  openFolderDblClick(folderName: any) {
    this.ftvFiles = [];
    this.l1adFiles = [];
    this.cvFiles = [];
    this.l1losFiles = [];
    this.tbaFiles = [];
    this.tbbFiles = [];
    this.tbcFiles = [];
    this.isFolderClicked = true;

    this.uploadedTvFiles.filter((el: any) => {
      if (el.folderName === folderName) {
        this.getFolderName = el.folderName;
        this.ftvFiles.push(el);
      }
      if (el.folderName === folderName) {
        this.getFolderName = el.folderName;
        this.l1adFiles.push(el);
      }
      if (el.folderName === folderName) {
        this.getFolderName = el.folderName;
        this.cvFiles.push(el);
      }
      if (el.folderName === folderName) {
        this.getFolderName = el.folderName;
        this.l1avFiles.push(el);
      }
    })

    this.uploadedLosFiles.filter((el: any) => {
      if (el.folderName === folderName) {
        this.getFolderName = el.folderName;
        this.l1losFiles.push(el);
      }
    })

    this.uploadedTbFiles.filter((el: any) => {
      if (el.folderName === folderName) {
        this.getFolderName = el.folderName;
        this.tbaFiles.push(el);
      }
      if (el.folderName === folderName) {
        this.getFolderName = el.folderName;
        this.tbbFiles.push(el);
      }
      if (el.folderName === folderName) {
        this.getFolderName = el.folderName;
        this.tbcFiles.push(el);
      }
    })
  }
}
