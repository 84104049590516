import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MISSION_STATUS, NOTIFY_MSG, MISSION_PGB } from 'projects/data-upload/src/app/constants/create-mission.const';
import { ICreateMissionObjective, ICreateMissionPostData, IMissionDataParams } from '../../models/create-mission-dataset.model';
import { MissionSharedService } from 'projects/data-upload/src/app/services/mission-shared.service';
import { CreateMissionWorkflowService } from 'projects/data-upload/src/app/services/create-mission-workflow.service';
import { CreateMissionService } from 'projects/data-upload/src/app/services/create-mission.service';
import { IMissionWorkflowDef, IMissionWorkflowResponse } from 'projects/data-upload/src/app/interfaces/create-mission-workflow.interface';
import { GridMissionSharedService } from 'projects/data-upload/src/app/services/grid-mission-shared.service';
import { UploadDatasetService } from '../../services/upload-dataset.service';
@Component({
  selector: 'app-mission-detail',
  templateUrl: './mission-detail.component.html',
  styleUrls: ['./mission-detail.component.scss']
})
export class MissionDetailComponent implements OnInit {
  @Input() loadUploadDataset?: boolean;
  @Input() getCreateMissionForm1Data?: any;
  @Input() updateMissionForm1Data?: any;
  @Input() missionSiteID?: any;
  @Input() missionObjectiveList: any;
  @Input() isMissionCreatedCheck: boolean = false;
  @Input() isDetailUpdated: boolean = false;
  workflows: IMissionWorkflowDef[] = [];
  @Output() uploadDatasetComponent: EventEmitter<boolean> = new EventEmitter();
  @Output() onMissionObjectiveChange: EventEmitter<string> = new EventEmitter();

  createMissionForm!: FormGroup;
  isSubmitted = false;
  missionIdControl?: string;
  missionIDErrorMsg?: string;
  missionObjErrorMsg?: string;
  isDisabled: boolean = true;
  toasterClass: string = "";
  toasterMsg: string = "";
  isShowToaster: boolean = false;
  isNextClick: boolean = false;
  missionCreatedTag: boolean = false;
  missionObjectiveRecord!: Array<any>;
  selectedObjectiveId!: string;
  selectedObjective!: string;
  missionId!: string;
  updatedMissionFormsDataArr?: Array<any>;
  missionObjectiveItems?: Array<any>;
  prevSelectedObjective!: string;
  @Output() gridFlow = new EventEmitter<boolean>();
  @Output() updateActiveIndex: EventEmitter<number> = new EventEmitter();
  @Input()  gridFlowFlag! : boolean;
  @Output() closeMission = new EventEmitter();
  @Output() selectedGridObjective = new EventEmitter();
  grid = false;
  createMissionParams = this.missionSharedService.createMissionParams_signal

  constructor(
    private cmFBuilder: FormBuilder,
    private createMissionService: CreateMissionService,
    private missionSharedService: MissionSharedService,
    private missionWorkflowService: CreateMissionWorkflowService,
    private gridMissionSharedService: GridMissionSharedService,
    private uploadDateServices: UploadDatasetService
  ) { }

  ngOnInit(): void {
    this.grid = this.gridFlowFlag;
    this.getFormValues();
    this.missionObjectiveList
    .subscribe((response: ICreateMissionObjective[]) => {
      this.missionObjectiveList = response;
      this.missionObjectiveRecord = this.missionObjectiveList.records;
    });
    this.fetchWorkflowDefinitions();
    this.createMissionForm.patchValue({ missionName: this.createMissionParams().name })
  }

  changeMissionObjective(event: any) {
    this.isDetailUpdated = this.selectedObjectiveId !== event.target.value ? true : false;
    this.selectedObjectiveId = event.target.value;
    const selectedValue = this.missionObjectiveRecord.find(item => item._id === this.selectedObjectiveId)
    if(selectedValue.name === MISSION_PGB.PIPLINE_SURVEILLANCE || selectedValue.name === MISSION_PGB.GRID_INSPECTION)
    {
      this.gridFlow.emit(true)
      this.gridFlowFlag = true;
      this.onMissionObjectiveChange.emit(this.selectedObjectiveId)
    }
    else
    {
      this.gridFlow.emit(false)
    }
    this.missionSharedService._selectedObjective.next(this.selectedObjectiveId);
  }
  getFormValues() {
    this.createMissionForm = this.cmFBuilder.group({
      missionObj: ['', [Validators.required]],
      missionName: ['', [Validators.required]],
      workflowDef:['', [Validators.required]]
    });

    this.createMissionForm.get('missionName')?.valueChanges.subscribe((value) => {
      this.sendMissionNameToAPI(value);
    });
    this.createMissionForm.get('workflowDef')?.valueChanges.subscribe((workflow) => {
      this.uploadDateServices.getWorkflowData.next(workflow)
    });
  }
  sendMissionNameToAPI(missionName: string) {
    localStorage.setItem('uploadMissionName',missionName)
  }
  initUploadDatasetComponent(flag: boolean) {
    if(this.gridFlowFlag){
      this.closeMission.emit();
    }
    else{
      this.updateActiveIndex.emit(1);
      this.uploadDatasetComponent.emit(false);
    }
  }
  showToaster(message: string, toastClass: string) {
    this.toasterMsg = message;
    this.toasterClass = toastClass;
    this.isShowToaster = true;
    setTimeout(() => {
      this.isShowToaster = false;
    }, 3000);
  }
  onSumbitInfo() {
    this.isNextClick = true;
    this.isSubmitted = true;
    this.gridMissionSharedService.setWorkFlowData(this.createMissionForm.get('workflowDef')?.value )
    if (!this.createMissionForm.valid && !this.gridFlowFlag) {
      this.isSubmitted = false;
    } else {
      if (!this.isMissionCreatedCheck) {
        let getLoggedInUserInfo = this.missionSharedService.getUserInfo();
        let workspaceId = getLoggedInUserInfo.activeWorkspaceId;
        let postData = {
          workspaceId: workspaceId,
          _id: '',
          siteId: this?.missionSiteID,
          assetIds: this.getCreateMissionForm1Data?.missionAsset,
          projectId: this.getCreateMissionForm1Data?.missionProject,
          objective: this.createMissionForm.get('missionObj')?.value,
          name: this.createMissionForm.get('missionName')?.value,
          status: MISSION_STATUS.DRAFT,
        };
        this.createMissionService.postMissionData(postData).subscribe({
          next: (response: any) => {
            let responseList: any = response;
            this.missionId = responseList.id;
            const params = {
              siteId: this?.missionSiteID,
              missionId: this.missionId,
              missionProjectId: responseList.projectId,
              missionAssetId: responseList.assetIds,
              name: this.createMissionForm.get('missionName')?.value,
            }
            this.createMissionParams.set(params)
            this.showToaster(NOTIFY_MSG.MISSION_CREATED, NOTIFY_MSG.SUCCESS_CLASS)
            setTimeout(() => {
              this.loadUploadDataset = true;
              this.updateActiveIndex.emit(3);
            }, 1000);
            this.enableNextBtnWithDelay()
          },
          error: (error) => {
            this.showToaster(NOTIFY_MSG.MISSION_FAILED, NOTIFY_MSG.ERROR_CLASS)
            this.enableNextBtnWithDelay()
          }
        })
      } else {
        let assetId, projectID;
        this.updatedMissionFormsDataArr = [];
        let missionDataParams = this.createMissionParams();
        for (let i = 0; i < this.updateMissionForm1Data.length; i++) {
          this.updatedMissionFormsDataArr = [];
          assetId = this.updateMissionForm1Data[i].assetId;
          projectID = this.updateMissionForm1Data[i].projectId;
          let prevAssetID = missionDataParams.missionAssetId.toString().replaceAll('"', '').replace(/[\[\]']+/g, '');

          this.updatedMissionFormsDataArr.push({
            assetIds: typeof assetId !== 'undefined' ? assetId.toString() : prevAssetID,
            projectId: typeof projectID !== 'undefined' ? projectID : missionDataParams.missionProjectId,
          })
        }
        this.updatedMissionFormsDataArr.push({
          objective: this.selectedObjectiveId
        })
        let updatedMissionData = {};
        for (let i = 0; i < this.updatedMissionFormsDataArr.length; i++) {
          Object.assign(updatedMissionData, this.updatedMissionFormsDataArr[i]);
        }
          this.createMissionService.updateMissionData(missionDataParams.missionId, updatedMissionData)
            .subscribe((response: ICreateMissionPostData[]) => {
              let responseList: any = response;
              this.missionId = responseList.id;
              const params = {
                siteId: this.missionSiteID,
                missionId: missionDataParams.missionId,
                missionProjectId: responseList.projectId,
                missionAssetId: JSON.stringify(responseList.assetIds),
                name: this.createMissionForm.get('missionName')?.value,
              }
              this.createMissionParams.set(params)
              this.enableNextBtnWithDelay()
              this.updateActiveIndex.emit(3);
            }, error => {
              this.showToaster(NOTIFY_MSG.MISSION_FAILED, NOTIFY_MSG.ERROR_CLASS)
              this.enableNextBtnWithDelay()
            });
        this.loadUploadDataset = true;
      }
    }
  }
  fetchWorkflowDefinitions() {
    this.missionWorkflowService.getWorkflowDef().subscribe((response:any) => {
        this.workflows = response.records;
      });
    // this.missionWorkflowService.getWorkflowDef().subscribe((response:IMissionWorkflowResponse) => {
    //   this.workflows = response.records;
    // });
  }
  enableNextBtnWithDelay() {
    setTimeout(() => {
      this.isNextClick = false
    }, 1000)
  }
}
