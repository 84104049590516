export const ICONS= {
    'CROSS_ICON':'/images/close.svg',
    'RESTART_ICON':'/images/restrat-icon.svg',
    'FAIL_ICON':'/images/eclipse-dot-red.svg',
    'ERROR_ICON':'/images/error_sign.png',
    'SUCCESS_ICON':'/images/tick.svg',
    "ARROW_UP":'/images/arrow_up.svg',
    "ARROW_DOWN":'/images/arrow_down.svg',
    "folder":'/images/folder.svg',
    "EXPAND_ICON": '/images/expand.svg',
    "PROGRESS_BG" : './images/progress.png',
    "PROGRESS_POINTER" : './images/progress_pointer.svg',
  }
  export const  PROGRESS_WAITING_MSG = 'Waiting For Workflow to Run';
  export const RESOURCE_TYPE_IMAGE  = 'image';