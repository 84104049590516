@if(workflowProgressData){
<div #workflowProgressContainer class="workflow-progress-container">
  <div class="workflow-progress-head">
    <div class="workflow-progress-count">
      <p>Processing in progress.....</p>
      <div class="workflow-progress-hide-show">
        <a (click)="workflowProgressContainer.classList.toggle('show-workflow-file')">
          <img [src]="'/images/expand.svg' | assetUrl" alt="expand">
        </a>
      </div>
    </div>
    <div class="workflow-progress-name">
      <p>PRASARANA II</p>
      <span>&#8226;</span>
      <p>{{createMissionParams().trackingId}}</p>
    </div>
    <hr>
  </div>
  <div class="workflow-progress-bar-container">
    <div class="workflow-progress-bar">
      <p>Overall progress</p>
      @if(!isWorkflowFailed()){
        <div class="workflow-progress-percentage-container">
          <div class="workflow-progress-percentage">
            <p>Running...</p>
            <p>{{overallProgressForWorkFlow}}%</p>
          </div>
          <div>
            <div class="workflow-progress-bar-outer">
              <div class="workflow-progress-bar-inner"></div>
              <div #overallProgress class="workflow-progress-pointer" [style.width]="(100 - overallProgressForWorkFlow) +'%'">
                <img [src]="'/images/progress_pointer.svg' | assetUrl" alt="progress_pointer">
                <p></p>
              </div>
            </div>
          </div>
        </div>
      }@else {
        <div class="workflow-progress-percentage-container-error">
          <div class="workflow-progress-percentage">
            <p>Failed to process.</p>
            <p class="workflow-retry">Retry <img [src]="'/images/warning.png' | assetUrl" alt="warning"></p>
          </div>
          <div>
            <div class="workflow-progress-bar-outer">
              <div class="workflow-progress-bar-inner"></div>
            </div>
          </div>
        </div>
      }
    </div>
    @for(workflowStep of workflowProgressData.workflowSteps || []; track workflowStep._id){
      @if(workflowStep.status == "running" && workflowStep.progress && !isWorkflowFailed()){
        <div class="workflow-progress-bar">
          <p>{{workflowStep.displayName}}</p>
            <div class="workflow-progress-percentage-container">
              <div class="workflow-progress-percentage">
                <p>Running...</p>
                <p>{{workflowStep.progress.percentageCompleted | workflowProgress}}%</p>
              </div>
              <div>
                <div class="workflow-progress-bar-outer">
                  <div class="workflow-progress-bar-inner"></div>
                  <div class="workflow-progress-pointer" [style.width]="(100 - (workflowStep.progress.percentageCompleted | workflowProgress)) +'%'">
                    <img [src]="'/images/progress_pointer.svg' | assetUrl" alt="progress_pointer">
                    <p></p>
                  </div>
                </div>
              </div>
            </div>
        </div>
      }@else if (workflowStep.status == "failed" && workflowStep.progress) {
        <div class="workflow-progress-bar">
          <p>{{workflowStep.displayName}}</p>
            <div class="workflow-progress-percentage-container-error">
              <div class="workflow-progress-percentage">
                <p>Failed to process.</p>
                <p class="workflow-retry">Retry <img [src]="'/images/warning.png' | assetUrl" alt="warning"></p>
              </div>
              <div>
                <div class="workflow-progress-bar-outer">
                  <div class="workflow-progress-bar-inner"></div>
                </div>
              </div>
            </div>
        </div>
      }
    }
  </div>
    @if(workFlowRunStatusList.length){
      <div class="workflow-progress-dropdown">
      <div class="workflow-progress-status-container">
        <p>Details:</p>
        <div class="workflow-progress-status-list">
          @for(workFlowRunStatus of workFlowRunStatusList; track workFlowRunStatus.id){
            <div class="workflow-progress-status-parent">
              <span>&#8226;</span>
              <div class="workflow-progress-status-check">
                @if(workFlowRunStatus.id == 'Overall progress failed'){
                  <img [src]="'/images/danger.svg' | assetUrl" alt="danger">
                }
                <p [ngStyle]="{width:workFlowRunStatus.id == 'Overall progress failed' ? '220px': ''}">{{workFlowRunStatus.progressInformation}}</p>
              </div>
            </div>
            @for(child of workFlowRunStatus?.child || []; track child.id){
              <div class="workflow-progress-status-child">
                @if(!child.status){
                  <img [src]="'/images/danger.svg' | assetUrl" alt="danger">
                }
                <p [ngStyle]="{width:child.status ? '': '210px'}">{{child.progressInformation}}</p>
              </div>
            }
          }
        </div>
      </div>
    </div>
    }
</div>
}
