import { EnvironmentService } from "./environment.service";



const environmentService = new EnvironmentService();
const apiUrl = environmentService.getApiUrl();

export class Routes {
  static baseAddress =
    window.location.origin !== 'http://localhost:4200'
      ? `${window.location.origin}/api`
      : apiUrl;

  // =============================== User Management =============================== //
  static GET_MEMBERS = `${Routes.baseAddress}/data-resource/data-resource/credentials?project_id=5f92cbf10cf217478ba93561&mission_id=5f92cbf10cf217478ba93561&resource_type=image`;
  static CREATE_DATA_RESOURCE = `${Routes.baseAddress}/resources`;
  static GET_DATA_RESOURCE = `${Routes.baseAddress}/resources/?project_id=5f92cbf10cf217478ba93561&mission_id=5f92cbf10cf217478ba93561&site_id=5f92cbf10cf217478ba93561&cursor=5f92cbf10cf217478ba93561&limit=5&resource_type=image`;
  static MISSION_OBJ_URL = `${apiUrl}/mission/mission-objective`;
  static MISSION_PROJECT_URL = `${apiUrl}/project/projects`;
  static CREATE_MISSION_URL = `${apiUrl}/mission/data-proc-only/missions`;
  static MISSION_SITE_URL = `${apiUrl}/inventory/sites/`;
  static WORKFLOW_DEF_URL = `${apiUrl}/workflows/workflow-defs/`;
  static WORKFLOW_RUN_URL = `${apiUrl}/workflows/workflow-runs/`;
  static CREATE_DATA_RESOURCE_URL = `${apiUrl}/data-resource/data-resource`;
  static UPDATE_SITE_INFO = `${apiUrl}/inventory/sites`;
  static BULK_DATA_RESOURCE_URL = `${apiUrl}/data-resource/data-resource/bulk`;
  static MISSION_TRACKING_URL = `${apiUrl}/mission/missions/trackingId`;
  static GET_ALL_ASSETS = `${apiUrl}/inventory/assets`;
}
