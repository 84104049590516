@if(folderTreeData.length){
<div class="upload-folder-preview">
  <div class="upload-folder-preview-head">
    <div class="upload-preview-delete-container">
      <p>preview</p>
      @if(selectedAllFileList.length){
      <a (click)="onDeleteModalOpen()">
        <img [src]="'/images/delete-white.svg' | assetUrl" alt="delete-white" />
        <p>Delete</p>
      </a>
      }
    </div>
    <div class="select-all-container">
      <div class="checkbox-container">
        <input #SelectAllCheckbox type="checkbox" id="selectAllCheckbox"
          (change)="onCheckboxChange(folderTreeData,SelectAllCheckbox)">
        <img [src]="selectAllCheckBoxIcon() | assetUrl"
          alt="checkBox" />
      </div>
      <label class="select-all-label" for="selectAllCheckbox">Select All</label>
    </div>
  </div>
  @for(folderTree of folderTreeData;track folderTree.id;let first = $first){
  <ng-container *ngTemplateOutlet="folderTreeView; context: { $implicit: folderTree, rootFolder:true }"></ng-container>
  }
</div>
}

<ng-template #folderTreeView let-node let-rootFolder="rootFolder">
  <div class="upload-preview-container">
    <div class="upload-preview-folder-head-container" [style.gap]="node.childTreeGap+'px'" [ngClass]="{'disable-file-select': (!rootFolder && disableFileSelect)}">
      <div class="checkbox-container">
        <input #checkBox [checked]="node.isFolder ? node.isChecked : node.isChecked" type="checkbox"
          (change)="onCheckboxChange(node, checkBox)" [disabled]="(!rootFolder && disableFileSelect)">
        <img [src]="checkBoxIcon(node) | assetUrl" alt="checkBox"/>
      </div>
      <a class="upload-preview-folder-head" (click)="node.isFolder && node.isExpand = !node.isExpand">
        @if(node.isFolder){
        <img [src]="'/images/arrow.svg' | assetUrl" alt="arrow" [ngClass]="{'tree-view-expand': node.isExpand}" />
        <img [src]="'/images/folder.svg' | assetUrl" alt="folder" />
        }
        <p [style.cursor]="node.isFolder ? 'pointer' : 'default'">{{ node.name }}</p>
      </a>
    </div>
    @if(node.isExpand){
    <div class="node-child">
      @for(child of node.children;track child){
      <ng-container *ngTemplateOutlet="folderTreeView; context: { $implicit: child }"></ng-container>
      }
    </div>
    }
  </div>
</ng-template>
