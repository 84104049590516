import { Component, ElementRef, EventEmitter, Output, ViewChild, inject } from '@angular/core';
import { CONSTANTS_MISSION, UPLOAD_DATASET_HEADER } from '../../../constants/create-mission.const';
import { UploadDatasetService } from '../../../services/upload-dataset.service';
import { FileUploadData } from '../upload-dataset.component';
import { UtilityService } from '../../../services/utility.service';
@Component({
  selector: 'app-additional-image-data',
  templateUrl: './additional-image-data.component.html',
  styleUrl: './additional-image-data.component.scss'
})
export class AdditionalImageDataComponent {
  @Output() getUploadedFile = new EventEmitter<FileUploadData>()
  @ViewChild('inputAdditionalImage') inputElement: ElementRef<HTMLInputElement>

  utilityService = inject(UtilityService)
  uploadDataServices = inject(UploadDatasetService)

  // const
  UPLOAD_DATASET_HEADER = UPLOAD_DATASET_HEADER
  MISSION_CONSTANTS = CONSTANTS_MISSION;

  headExpand = ''
  isValid = true;
  additionalImageUploadedFiles: boolean;


  ngOnInit() {
    this.uploadDataServices.datasetHeadExpand.subscribe((data) => {
      this.headExpand = data
    })
    this.uploadDataServices.checkFileIsAvailable.subscribe(() => {
      this.additionalImageUploadedFiles = this.utilityService.checkUploadFileIsPresent(this.MISSION_CONSTANTS.ADDITIONAL_IMAGES)
    })
  }

  onSentUploadedFile(file: FileList | null, fileType: string, parentFileType: string) {
    const FileData = { file, fileType, parentFileType }
    this.getUploadedFile.emit(FileData)
  }

  onHeadExpand(data: string) {
    this.uploadDataServices.datasetHeadExpand.next(data)
  }

  checkFileValid(value: boolean) {
    this.isValid = value
    if (!this.isValid) {
      this.inputElement.nativeElement.value = ''
    }
  }
}
